import createCart from "../../model/Cart";
const config = {
	methodName: "ChangeQuantity",
	isTest: false,
	isDummy: false,
	method: "POST",
	dummyData: {},
	prepareRequest: (p) => p,
	prepareResponse: (r) => createCart(r),
	handleError: (e) => e,
};

export default config;
