import React, { Component } from "react";
import { Form, Input, Button } from "../Interface/Interface";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import css from "./UserForm.module.scss";
import UserModel, { validationRules } from "../../model/User";

class RegisterForm extends Component {
  state = {
    lname: "",
    fname: "",
    phone: "",
    email: "",
    password: "",
    password_confirm: "",
    private_policy_agreed: true,
  };

  handleFieldChange = (e) => {
    e.preventDefault();
    const name = e.target.getAttribute("name");
    const value = e.target.value;
    this.setState((state) => {
      const newState = { ...state };
      newState[name] = value;
      return newState;
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.reg.error) {
      const error = this.props.reg.error;
      this.props.reset();
      alert(error);
    }
  }

  handleRegistration = () => {
    const fieldsToValidate = [
      [this.state.fname, validationRules.FIRSTNAME],
      [this.state.lname, validationRules.LASTNAME],
      [this.state.phone, validationRules.PHONE],
      [this.state.email, validationRules.EMAIL],
      [this.state.password, validationRules.PASSWORD],
      [
        this.state.password,
        validationRules.PASSWORD_MATCH,
        this.state.password_confirm,
      ],
    ];
    const validationResult = UserModel.validateFields(fieldsToValidate);
    if (!validationResult.result) {
      return alert(validationResult.errors.join("\n"));
    }
    this.props.register({ ...this.state });
  };

  render = () => {
    return (
      <div className="ut-form__container">
        {this.props.isLogged ? (
          <h3 className={css["title"]}>Вы авторизованы</h3>
        ) : (
          <Wrapper>
            <h3 className={css["title"]}>Регистрация</h3>
            <Form>
              <Input
                onChange={this.handleFieldChange}
                name="fname"
                placeholder="Имя"
                label="Введите Ваше имя"
              />
              <Input
                onChange={this.handleFieldChange}
                name="lname"
                placeholder="Фамилия"
                label="Введите Вашу фамилию"
              />
              <Input
                onChange={this.handleFieldChange}
                name="phone"
                placeholder="Телефон"
                label="Введите Ваш телефон"
              />
              <Input
                onChange={this.handleFieldChange}
                name="email"
                placeholder="Email"
                label="Введите Ваш Email"
              />
              <Input
                name="password"
                onChange={this.handleFieldChange}
                placeholder="Пароль"
                label="Введите Ваш пароль"
                type="password"
              />
              <Input
                name="password_confirm"
                onChange={this.handleFieldChange}
                placeholder="Повторите Пароль"
                label="Введите Ваш пароль"
                type="password"
              />
              <div className={css["cb-group"]}>
                <input
                  id="policy_f"
                  checked={this.state.private_policy_agreed}
                  name="createAccount"
                  value={true}
                  onChange={() =>
                    this.setState((state) => ({
                      ...state,
                      private_policy_agreed: !state.private_policy_agreed,
                    }))
                  }
                  type="checkbox"
                />
                <label htmlFor="policy_f" className={css["text"]}>
                  Даю свое согласие на обработку моих персональных данных в
                  соотвествии с ФЗ от 27.07.2006 г. №152-ФЗ "О персональных
                  данных" на условиях и для целей, определенных в{" "}
                  <a
                    href="https://uniqtrip.ru/mobile/pd_consent.pdf"
                    target="_blanc"
                  >
                    Согласии
                  </a>{" "}
                  на обработку персональных данных и{" "}
                  {/* а также соглашаюсь с
                  правилами и условиями обслуживания */}
                  <a
                    href="https://uniqtrip.ru/mobile/security_policy.pdf"
                    target="_blanc"
                  >
                    Политикой конфиденциальности
                  </a>
                  {""}.
                </label>
              </div>
              <Button
                className={
                  !this.state.private_policy_agreed
                    ? css["formButton_button__disabled"]
                    : ""
                }
                disabled={!this.state.private_policy_agreed}
                onClick={(e) => {
                  e.preventDefault();
                  return this.handleRegistration();
                }}
                loading={this.props.reg.loading}
              >
                {this.props.title}
              </Button>
            </Form>
          </Wrapper>
        )}
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  register: (formData) => dispatch(actions.register(formData)),
  reset: () => dispatch(actions.regReset()),
});
const mapStateToProps = (state) => ({
  isLogged: state.user.isLogged,
  reg: state.user.regState,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
