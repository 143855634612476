import * as actionTypes from "../actions/actionTypes";

const InitialState = {
  categories: [],
  countries: [],
  banners: [],
  geozoneList: [],
  articles: [],
};

const setCategories = (state, action) => {
  const categories = action.data;
  return {
    ...state,
    categories,
  };
};
const setCountries = (state, action) => {
  const countries = [...action.data];
  return {
    ...state,
    countries,
  };
};
const setBanners = (state, action) => {
  const banners = action.data;
  return {
    ...state,
    banners,
  };
};
const getGeozoneList = (state, action) => {
  const geozoneList = action.data;
  return {
    ...state,
    geozoneList,
  };
};
const geArticles = (state, action) => {
  const articles = action.data;
  return {
    ...state,
    articles,
  };
};

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORIES:
      return setCategories(state, action);
    case actionTypes.SET_COUNTRIES:
      return setCountries(state, action);
    case actionTypes.SET_BANNERS:
      return setBanners(state, action);
    case actionTypes.SET_GEOZONES:
      return getGeozoneList(state, action);
    case actionTypes.SET_ARTICLES:
      return geArticles(state, action);
    default:
      return state;
  }
};

export default reducer;
