import React, { useEffect, Fragment } from "react";
import css from "./LoadingMessage.module.scss";

const LoadingMessage = props => {
    return (
        <div className={css["loading"]}>
            <div className={css["logo"]}></div>
            {props.children}
        </div>
    );
};

export default LoadingMessage;
