import React, { Fragment } from "react";
import css from "./styles/RandomReview.module.scss";
import Label from "../Label/Label";
import util from "../util/util";

const RandomReview = (props) => {
  const reviewBlock = props.review ? (
    <div className={css.review}>
      <div>
        <img
          className={css.img}
          src={props.review.userPhoto}
          onError={util.addDummyImage}
          alt=""
        />
      </div>
      <div>
        <h6 className={css.heading}>
          <Label className={css.label} color="blue">
            {props.review.reviewAvgRating}
          </Label>
          {props.review.reviewTitle}
        </h6>
        <p className={css.text}>{util.getText(props.review.reviewText, 240)}</p>
      </div>
    </div>
  ) : (
    <div className={css.empty}>
      <p className={css["empty-text"]}>
        В данный момент отзывов нет. <br></br> Ваш может стать первым!
      </p>
    </div>
  );

  return (
    <Fragment>
      {reviewBlock}
      <div className="ut-theme-color">
        {props.review.itemsLength > 1 ? (
          <button
            onClick={props.scrollBtnClick}
            className={util.join(css["button"], css["button-more"])}
          >
            Еще отзывы
          </button>
        ) : null}
        <button
          disabled={props.postedReview}
          onClick={props.postBtnClick}
          className={util.join(css["button"], css["button-post"])}
        >
          <span>
            {props.postedReview ? "Отзыв отправлен" : "Написать отзыв"}
          </span>
        </button>
      </div>
    </Fragment>
  );
};

export default RandomReview;
