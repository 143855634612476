import GetReviews from "./_GetReviews";
import AddReview from "./_AddReview";
import GetSubCategory from "./_GetSubCategory";
import GetDiscounts from "./_GetDiscounts";
import GetCountriesList from "./_GetCountriesList";
import GetBanners from "./_GetBanners";
import GetFilterDataByPartnerCode from "./_GetFilterDataByPartnerCode";
import GetPartnersRating from "./_GetPartnersRating";
import GetReviewFields from "./_GetReviewFields";
import GetProductsByPartnerID from "./_GetProductsByPartnerID";
export default {
  GetReviews,
  AddReview,
  GetSubCategory,
  GetDiscounts,
  GetCountriesList,
  GetBanners,
  GetFilterDataByPartnerCode,
  GetPartnersRating,
  GetReviewFields,
  GetProductsByPartnerID,
};
