import React from "react";
import { Link } from "react-router-dom";
import css from "./styles/_ArticleItem.module.scss";
import articleArr from "../../assets/image/article-icon.svg";

const _ArticleItem = (props) => {
  return (
    <Link className={css["article-preview"]} to={`news/${props.item.id}`}>
      <div
        className={css["article-container"]}
        style={{
          backgroundImage: `url('${props.item.image}')`,
        }}
      >
        <div className={css["article-title"]}>{props.item.title}</div>
        <div className={css["article-service"]}>
          <button>
            Читать{" "}
            <span>
              <img src={articleArr} alt="" />
            </span>
          </button>
          <div className={css["article-date"]}>
            {props.item.created_at.split(" ")[0]}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default _ArticleItem;
