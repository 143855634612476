import React from "react";
import css from "./OrderTotals.module.scss";
import withOverlay from "../../hoc/Overlay/Overlay";
import { Link } from "react-router-dom";

const FinishPaymentMsg = (props) => {
  return (
    <div className={css["check"]}>
      <div
        className={[
          css["check-container"],
          css["check-container-with-link"],
        ].join(" ")}
      >
        <h3 className={css["check-header"]}>Ваша тестовая покупка успешна!</h3>
        <div className={css["check-caption"]}>
          <p>
            Понравился проект? Вы можете связаться с нами по телефону{" "}
            <a className={css["check-link-phone"]} href="tel:+78622777042">
              +78622777042
            </a>{" "}
            или написать на почту{" "}
            <a
              className={css["check-link-mail"]}
              href="mailto:info@uniqtrip.ru"
            >
              info@uniqtrip.ru
            </a>
          </p>
        </div>
        <div
          className={[
            css["check-buttons-container"],
            css["check-buttons-container-links"],
          ].join(" ")}
          onClick={props.hideHandler}
        >
          <Link className={css["check-link-back"]} to="/">
            Вернуться на главную
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withOverlay(FinishPaymentMsg);

