import * as actionTypes from '../actionTypes';
import api from '../../../components/API/new/API';


export const regReset = () => {
    return dispatch => {
        dispatch({
           type:actionTypes.REG_RESET
        })
    }
}

export const register = (formData) => {
    return dispatch => {
        dispatch({
            type:actionTypes.REG_PENDING,
        })
        api.user.Register({...formData})
            .then(response => {
                if (!response.data.error) {
                    dispatch({
                        type:actionTypes.REG_SUCCESS,
                        user:response.data.user ? response.data.user : response.data
                    })                
                } else {
                    const message = response.data.msg ? response.data.msg : 'Регистрация не пройдена';
                    dispatch({
                        type:actionTypes.REG_FAILED,
                        error:message
                    })
                }
            })
            .catch( e => {
                    const message = 'Произошла ошибка сервера';
                    dispatch({
                        type:actionTypes.REG_FAILED,
                        error:message
                    })
            })
    }
}


