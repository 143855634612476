import React, { Component } from "react";
import SearchResultItem from "./_SearchResultItem";
import css from "./SearchResults.module.scss";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import util from "../util/util";
import { element } from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";

class SearchResultsList extends Component {
  render() {
    // const productsWithPrice = this.props.products.filter(element => element.params.price || util.currencyFormat(element.params.price) === !"0 RUB");
    const filteredItems = this.props.products.filter(
      (item) => item.params.services
    );
    const items = filteredItems.map((item, index) => {
      const itemData = {
        productId: item.params.productid || item.params.productId,
        categoryId: item.category_id,
        dateIn: this.props.filter.dateIn.format("YYYY-MM-DD"),
        dateOut: this.props.filter.dateOut.format("YYYY-MM-DD"),
        adults: this.props.filter.adults,
        children: this.props.filter.children,
        time: this.props.filter.time,
        Fname: "",
        Lname: "",
        email: "",
      };
      return (
        <SearchResultItem
          category={this.props.category}
          data={item}
          key={index}
          addToCart={() => {
            this.props.addToCart(itemData);
          }}
          getHover={this.props.getHover}
          hoverId={this.props.hoverId}
          count={false}
          search={true}
        />
      );
    });

    return (
      <div className={css["data"]}>
        <ul
          className={
            this.props.disableBtn
              ? util.join(css["list"], css["list--height"])
              : css["list"]
          }
          ref={this.props.scrollRef}
        >
          {this.props.loadSize <= 2 ? (
            items ? (
              items
            ) : (
              "На запрошенный период предложения не найдены"
            )
          ) : (
            <Scrollbars>
              {items ? items : "На запрошенный период предложения не найдены"}
            </Scrollbars>
          )}
          <li></li>
        </ul>
        {this.props.disableBtn ? null : (
          <button
            className={`ut-theme-color ${css["btn"]}`}
            onClick={this.props.onMoreBtnClick}
          >
            <span>Еще</span>
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.user.cart,
  filter: state.user.customerData.filter,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(actions.addToCart(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsList);
