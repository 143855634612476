import React, { useRef, useEffect, useState, Fragment } from "react";
import css from "./Cart.module.scss";
import PropTypes from "prop-types";
import util from "../util/util";

const CartItemField = (props) => {
  //   const textInput = useRef(null);
  //   if (props.focus && textInput.current) {
  //     textInput.current.focus();
  //   }

  let value = props.value;
  let name = props.name;
  let paramClassName = css["product-param"];

  if (props.code === "dateIn" || props.code === "dateOut") {
    value = util.moment(value, "YYYY-MM-DD").format("DD.MM.YYYY");
  } else if (props.code === "adults" || props.code === "children") {
    if (!props.value.includes("чел")) {
      value = props.value + " чел";
    }
    if (!props.name.includes(":")) {
      name = props.name + ":";
    }
  } else if (props.code === "comment") {
    paramClassName = util.join(
      css["product-param"],
      css["product-param-comment"]
    );
  }

  const field =
    props.code === "comment" ? (
      <textarea
        onChange={props.changeHandler}
        className={`${
          !props.isChange
            ? css["product-param-input--disabled"]
            : css["product-param-input"]
        } ${css["product-param-input-comment"]}`}
        disabled={!props.isChange}
        rows="3"
        maxLength="400"
        placeholder={name}
        name={props.code}
        value={value}
      ></textarea>
    ) : props.edit ? (
      <Fragment>
        <input
          className={`${
            !props.isChange
              ? css["product-param-input--disabled"]
              : css["product-param-input"]
          }`}
          placeholder={props.name}
          value={value}
          name={props.code}
          onChange={props.changeHandler}
          // disabled={props.isChange == false ? true : false}
          //   ref={textInput}
          // autoFocus={props.focus ? true : false}
        />
        {!props.isChange ? <div className={css["hackNotClick"]}></div> : null}
      </Fragment>
    ) : (
      <span className={css["product-param-value"]}>{value}</span>
    );

  return (
    <li className={paramClassName}>
      <span className={css["product-param-caption"]}>{name}</span>
      {field}
    </li>
  );
};

CartItemField.defaultProps = {
  name: "",
  code: "",
  value: "",
  edit: false,
  changeHandler: (e) => {
    console.log(e.target.value);
  },
};

CartItemField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  code: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
};

export default CartItemField;
