import React, { Component } from "react";
import classes from "./Hero.module.scss";
// import LeftPanel from "./_LeftPanel";
import MainPanel from "./_MainPanel";
// import RightPanel from "./_RightPanel";
import Preloader from "../Preloader/Preloader";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import Video from "./_Video";
import api from "../API/new/API";
// import defaultImage from "../../assets/image/main_1.jpg";

const videoUrl =
  "https://krasnayapolyanaresort.ru/assets/video/winter/winter.mp4";
const splashUrl = "https://shop.uniqtrip.ru/mobile/background_mobile.jpeg";
const defaultImage = document.body.dataset.background;

class Hero extends Component {
  state = {
    // backgroundImage: document.body.dataset.background,
    backgroundImage: defaultImage,
    offers: [],
  };

  componentDidMount() {
    api.catalog.GetDiscounts().then((response) => {
      const offers = Array.isArray(response.data)
        ? response.data.slice(0, 3)
        : [];
      this.setState((state) => ({
        ...state,
        offers,
      }));
    });
    if (this.props.category) {
      const backgroundImage = this.props.category
        ? this.props.category.picture.mid
        : defaultImage;
      this.setState((state) => ({
        ...state,
        backgroundImage,
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const backgroundImage = this.props.category
      ? this.props.category.picture.mid
      : defaultImage;
    if (this.props.category != prevProps.category) {
      this.setState({
        backgroundImage,
      });
    }
  }

  render = () => {
    let imagesToPreload = [];
    this.props.categories.map(
      (category) =>
        (imagesToPreload = imagesToPreload.concat([
          category.picture.mid,
          category.picture.small,
        ]))
    );

    return (
      <section className={classes["container"]}>
        {false && (
          <Video
            category={this.props.category}
            src={videoUrl}
            poster={splashUrl}
          />
        )}
        <MainPanel
          onCategoryPick={this.categoryPickHandler}
          defaultCategory={this.state.selectedCategory}
          category={this.state.selectedCategory}
          backgroundImage={this.state.backgroundImage}
          offers={this.state.offers}
          cartHandler={this.props.addToCart}
        />
        <Preloader images={imagesToPreload} />
      </section>
    );
  };
}

const mapStateToProps = (state) => ({
  categories: state.catalog.categories,
  cart: state.user.cart,
  category: state.user.customerData.category,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(actions.addToCart(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hero);
