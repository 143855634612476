import React, { useState, useEffect } from 'react';
import withLoading from '../../hoc/Loading/Loading';
import css from './Banner.module.scss';
import util from '../util/util';

const Banner = (props) => {
  return Object.keys(props.actual).length > 0 ? (
    <section>
      <div
        className={util.join(
          'ut-section__container',
          'ut-theme-color',
          css['containerBanner']
        )}
      >
        <a
          href={props.actual.url}
          target={props.actual.target}
          className={util.join('ut-banner', css['mobileBunner'])}
          // style={{ backgroundImage: `url(${props.actual.imageUrl})` }}
        >
          <img
            src={props.actual.imageUrl}
            className="ut-banner__image"
            alt=""
            onError={(e) => util.addDummyImage(e, 'banner')}
          />
          <div className={util.join(css['bannerData'])}>
            <div target={props.actual.target} className="ut-banner__title">
              {props.actual.header}
            </div>
            <div
              href={props.actual.url}
              target={props.actual.target}
              className="ut-banner__slogan"
            >
              {props.actual.price}
            </div>
            <div
              href={props.actual.url}
              target={props.actual.target}
              className={css['bannerText']}
            >
              {props.actual.description}
            </div>
            {/* <div className="ut-banner__buttons"> */}
            <div
              href={props.actual.url}
              target={props.actual.target}
              className={util.join('ut-theme-color', css['bannerButton'])}
            >
              <span>{props.actual.button}</span>
            </div>
            {/* </div> */}
          </div>
        </a>
      </div>
    </section>
  ) : null;
};

export default withLoading(Banner);
