/**
 *
 * Refactored version
 *
 * Accommodation product!
 *
 */

import React, { useState } from "react";
import css from "./Cart.module.scss";

import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import ShareWidget from "../Share/ShareWidget";
import ClearIcon from "@material-ui/icons/Clear";
import CartItemProduct from "./_CartItemProduct_Acc";
import UserModel from "../../model/User";

import util from "../util/util";

const CartItem = (props) => {
  const [load, setLoad] = useState(false);
  const itemsSummary = props.items.map((item, index) => {
    let properNumbersWord;

    if (item.quantity === 1) {
      properNumbersWord = "номер";
    } else if (item.quantity > 1 && item.quantity < 5) {
      properNumbersWord = "номера";
    } else if (item.quantity >= 5) {
      properNumbersWord = "номеров";
    }

    return (
      <li key={index}>
        <div className={css["item-summary-info"]}>
          <div className={css["item-summary-info-room"]}>
            {item.name} x {item.quantity} {properNumbersWord} x{" "}
            {util.currencyFormat(item.price)}
          </div>
          <div className={css["item-summary-info-price"]}>
            {util.currencyFormat(item.total)}
          </div>
        </div>
      </li>
    );
  });

  const removeHandler = () => {
    const itemsToRemove = props.items.map((item) => item.id);
    props.removeFromCart(itemsToRemove);
  };

  const [expandedAll, toggleExpandedAll] = useState(false);
  const [expandedComment, toggleExpandedComment] = useState(false);
  const itemsPrepared = props.items.reduce((prev, current) => {
    const result = [...prev];

    // Duplicate room handler
    const duplicateHandler = () => {
      const fields = util.arrayToAssoc(current.params, "code");

      const itemData = {
        productId: current.productId,
        categoryId: props.categoryId,
        dateIn: fields.dateIn.value,
        dateOut: fields.dateOut.value,
        adults: fields.adults.value,
        children: fields.children.value,
      };

      return props.addToCart(itemData);
    };

    // Update room guest data
    const updateHandler = (fields) => {
      const fieldsToValidate = Object.values(fields)
        .filter((field) => field.edit && field.value.length > 0)
        .map((field) => [field.value, field.code]);

      const validationResult = UserModel.validateFields(fieldsToValidate);
      if (!validationResult.result) {
        return alert(validationResult.errors.join("\n"));
      }

      // TODO:Refactor !!!
      const updatedItem = {
        comment: fields.comment ? fields.comment.value : null,
        email: fields.email ? fields.email.value : null,
        phone: fields.phone ? fields.phone.value : null,
        Fname: fields.Fname ? fields.Fname.value : null,
        Lname: fields.Lname ? fields.Lname.value : null,
        item: {
          ...current,
          props: [...current.params],
          categoryId: props.categoryId,
          productId: current.productId,
        },
      };
      return props.updateCartFields({ payload: [updatedItem] });
    };

    for (let i = 0; i < current.quantity; i++) {
      const removeHandler =
        current.quantity === 1
          ? () => props.removeFromCart([current.id])
          : () => props.changeQuantity(current.id, current.quantity - 1);
      result.push({
        ...current,
        removeHandler,
        updateHandler,
        duplicateHandler,
        quantity: 1,
      });
    }
    return result;
  }, []);

  // Here we use complex key to ensure
  // that input fileds are updated properly
  // during saving and remove operations
  const now = Date.now();
  const products = itemsPrepared.map((product, productIndex) => (
    <CartItemProduct
      key={`${productIndex}_${product.id}_${now}`}
      expandedComment={expandedComment}
      expandedAll={expandedAll}
      {...product}
      profile={props.profile}
      loading={props.updateState.loading}
    />
  ));

  return (
    <div className={css["item"]}>
      <div className={css["item-main"]}>
        <ul className={util.join("ut-theme-color", css["item-icons"])}>
          <li
            className={`${css["item-icon"]} ${css["item-red"]}`}
            onClick={removeHandler}
          >
            <ClearIcon />
          </li>
          <li>
            <ShareWidget
              items={props.items}
              categoryId={props.categoryId}
              productId={props.productId}
            />
          </li>
        </ul>

        <img src={props.image} className={css["item-image"]} alt="" />
        <div className={css["item-data"]}>
          <h3 className={css["item-title"]}>{props.name}</h3>
          <ul className={css["item-summary"]}>{itemsSummary}</ul>
          <div className={css["item-toggle-contaner"]}>
            {/* Цена {util.currencyFormat(props.total)} */}
            <span className={util.join(css["item-toggle"], "ut-theme-color")}>
              <input type="checkbox" defaultChecked={expandedAll} />
              <label
                className={util.join(
                  expandedAll ? css["item-toggle-activeLabel"] : ""
                )}
                onClick={() => toggleExpandedAll(!expandedAll)}
              >
                {util.getExpandText("acc", expandedAll)}
              </label>
            </span>
            {/* commentary */}
            <span className={util.join(css["item-toggle"], "ut-theme-color")}>
              <input type="checkbox" defaultChecked={expandedComment} />
              <label
                className={util.join(
                  expandedComment ? css["item-toggle-activeLabel"] : ""
                )}
                onClick={() => toggleExpandedComment(!expandedComment)}
              >
                {util.getExpandText()}
              </label>
            </span>
          </div>
        </div>
      </div>
      {/* <div
        className={
          expandedAll || expandedComment
            ? css["item-details--expanded"]
            : css["item-details"]
        }
      > */}
        {products}
      {/* </div> */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  favourites: state.user.favourites,
  updateState: state.user.updateState,
});

const mapDispatchToProps = (dispatch) => ({
  addFavourite: (itemData) => dispatch(actions.addFavourite(itemData)),
  removeFavourite: (itemData) => dispatch(actions.removeFavourite(itemData)),
  addToCart: (itemData) => dispatch(actions.addToCart(itemData)),
  removeFromCart: (items) => dispatch(actions.removeFromCart(items)),
  updateCartFields: (data) => dispatch(actions.updateCartFields(data)),
  changeQuantity: (positionId, newQuantity) =>
    dispatch(actions.changeQuantity(positionId, newQuantity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
