import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import css from "./styles/FavouritesList.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import * as routes from "../util/routes";
import * as actions from "../../store/actions/index";
import util from "../util/util";

const FavouritesList = (props) => {
	const items = props.favourites.map((item, index) => {
		const elementUrl = [
			routes.CATALOG_PREFIX,
			item.categoryCode,
			"/",
			item.partnerCode,
		].join("");
		return (
			<li key={index} className={util.join(css.item, "ut-theme-color")}>
				<Link to={elementUrl} className={css["item-link"]}>
					<img className={css["item-img"]} src={item.image} />
					<div className={css["item-padding"]}>
						<p className={css["item-title"]}>{item.name}</p>
						<p className={css["item-text"]}>
							{util.getText(item.text, 120)}
						</p>
					</div>
				</Link>
				<span
					className={css["item-remove"]}
					onClick={() => props.remove(item, props.isLogged)}
				>
					<CloseIcon fontSize="large" className={css["close"]} />
				</span>
			</li>
		);
	});

	return (
		<Fragment>
			<h3 className="ut-section__title">Мое избранное</h3>

			{items.length > 0 ? (
				<ul className={css.list}>{items}</ul>
			) : (
				<p className={css.message}>У вас нет избранных элементов</p>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	favourites: state.user.favourites,
	isLogged: state.user.isLogged,
});
const mapDispatchToProps = (dispatch) => ({
	remove: (item, isLogged) =>
		dispatch(actions.removeFavourite(item, isLogged)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FavouritesList);
