import util from "../util/util";
import Label from "../Label/Label";
import React, { useState, useEffect } from "react";

const searchUtil = {};

// made labels for card

searchUtil.labels = (product) => {
  const labels = [];
  if (product.params.stars) {
    labels.push(
      <Label key="stars">
        {product.params.stars} <i className="ut-ico-star"></i>
      </Label>
    );
  }

  if (Array.isArray(product.params.labels)) {
    product.params.labels.map((item, index) => {
      labels.push(
        <Label key={index} color={item.class}>
          {item.title}
        </Label>
      );
    });
  }
  if (!product.params.productName) {
    if (product.params.avgcheck) {
      labels.push(
        <Label key={"avgcheck"} color="green">
          Средний чек {util.currencyFormat(product.params.avgcheck)}
        </Label>
      );
    }
  }
  return labels;
};

// made labels for partner

searchUtil.labelPartner = (product, css) => {
  const labelPartner = [];
  if (product.params.stars) {
    labelPartner.push(
      <Label key="stars">
        {product.params.stars} <i className="ut-ico-star"></i>
      </Label>
    );
  }
  if (product.params.services) {
    product.params.productText
      ? labelPartner.push(util.getText(product.params.productText))
      : labelPartner.push(
          util.getText(product.params.services_desc)
          // ...product.params.services.map((item, index) => (
          // 	<li className={css["serviceParnter"]} key={index}>
          // 		<i className={"ut-ico-" + item.class}></i>
          // 		{item.title}
          // 	</li>
          // ))
        );
  }
  return labelPartner;
};

// made a data for insert into social share icons

searchUtil.prepareLink = (product, category) => {
  const dataLink = {};
  dataLink.url = `${window.location.href}catalog/${category.code}/${product.code}`;
  dataLink.title = product.name;
  dataLink.image = util.getPropertySafe(
    product,
    "params.photos.0.small",
    util.dummyImage().small
  );
  //   dataLink.image =
  //   product.category_id == 7
  // 	  ? util.getPropertySafe(
  // 			  product,
  // 			  "params.photos.0.small",
  // 			  util.dummyImage().small
  // 		)
  // 	  : util.getPropertySafe(
  // 			  product,
  // 			  "params.productPhoto.small",
  // 			  util.getPropertySafe(
  // 				  product,
  // 				  "params.photos.0.small",
  // 				  util.dummyImage().small
  // 			  )
  // 		);
  return dataLink;
};
//made inserting word before price

searchUtil.wordBeforePrice = (product, search) => {
  let correctWord = "от ";
  if (
    (!product.params.productText && !product.params.avgcheck) ||
    (!product.params.productText &&
      product.params.avgcheck &&
      product.params.price)
  ) {
    correctWord = "от ";
  }
  if (!product.params.productText && product.params.price == 0) {
    correctWord = " ";
  }
  if (search) {
    correctWord = " ";
  }
  return correctWord;
};

export default searchUtil;
