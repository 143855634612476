import commerce from "./commerce/commerce";
import search from "./search/search";
import user from "./user/user";
import catalog from "./catalog/catalog";
import config from "./API.config";

import API from "./API.class";

const endpoints = {
	commerce,
	search,
	user,
	catalog,
};

const api = new API(config.api_url, endpoints);

export default api;
