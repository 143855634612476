import createCart from "../../model/Cart";

const config = {
	methodName: "RemoveFromCart",
	isTest: false,
	isDummy: false,
	method: "post",
	dummyData: {},
	prepareRequest: (p) => {
		return {
			items: Array.isArray(p) ? [...p] : [p],
		};
	},
	prepareResponse: (r) => createCart(r),
	handleError: (e) => e,
};

export default config;
