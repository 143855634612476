import React from "react";
import Puff from "react-preloader-icon/loaders/Puff";
import PreloaderIcon from "react-preloader-icon";
import PropTypes from "prop-types";

export const Button = (props) => {
	const btnProps = { ...props };
	delete btnProps.loading;

	return (
		<button {...btnProps}>
			{props.loading ? (
				<PreloaderIcon
					style={{ margin: "0 auto" }}
					loader={Puff}
					size={props.size}
					strokeWidth={8}
					strokeColor="#666"
					duration={800}
				/>
			) : (
				<span>{props.children}</span>
			)}
		</button>
	);
};

Button.propTypes = {
	loading: PropTypes.bool.isRequired,
	size: PropTypes.number.isRequired,
};

Button.defaultProps = {
	loading: false,
	size: 16,
};
