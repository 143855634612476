import React, { useState } from "react";
import PropTypes from "prop-types";
import util from "../util/util";
import css from "./styles/ButtonItem.module.scss";

function ButtonItemCategory(props) {
  let classes = [css["hours-item"]];
  if (props.selected) {
    classes.push(css.active);
  }
  if (props.category) {
    classes.push(css.category);
  }
  let allCategory = () => {
    let all = null;
    props.number === "Все"
      ? props.pickHandler(null)
      : props.pickHandler(props.number);
  };
  return (
    <div className={classes.join(" ")} onClick={() => allCategory()}>
      {props.number}
    </div>
  );
}

export default ButtonItemCategory;
