import React, { Component, Fragment } from "react";
import Hero from "./../components/Hero/Hero";
import SearchResults from "./../components/SearchResults/SearchResults";
import ArticlesSlider from "../components/ArticlesSlider/ArticleSlider";
import CategorySlider from "./../components/CategorySlider/CategorySlider";
import { connect } from "react-redux";
import Banner from "../components/Banner/Banner";
import util from "../components/util/util";
import * as actions from "../store/actions/index";
import searchUtil from "../components/SearchResults/searchUtil";
import apiDev from "../components/API/API.dev";

import queryString from "query-string";
import { object } from "prop-types";

class LayoutHome extends Component {
  state = {
    count: false,
    parsed: {},
    emergency: true,
  };
  componentDidMount() {
    window.scrollTo(0, 0);

    document.title = "Uniqtrip - конструктор приключений: Главная";

    const values = queryString.parse(this.props.location.search);

    util.objectIterator(values, (value, name) => {
      if (name === "category") {
        const category = value;
        this.props.setCategory(category);
        this.props.history.replace("/");
      }
      if (name === "dateIn") {
        const dateIn = util.moment(value, "DD.MM.YYYY");
        this.props.setFilter({
          dateIn: dateIn,
        });
      }
      if (name === "dateOut") {
        const dateOut = util.moment(value, "DD.MM.YYYY");
        this.props.setFilter({
          dateOut: dateOut,
        });
      }
      if (name === "adults") {
        this.props.setFilter({
          adults: value,
        });
      }
      if (name === "children") {
        this.props.setFilter({
          children: value,
        });
      }
      if (name === "sort") {
        this.props.setSort(value);
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.category !== prevProps.category) {
      const newState = {
        ...this.state,
        count: true,
      };

      this.setState(
        {
          ...newState,
        },
        this.actualBanners
      );
    }
  }

  adults = (adult, children) => {
    let parsed = {
      ...this.state.parsed,
    };

    parsed.adults = adult;
    parsed.children = children;

    this.setState(
      {
        ...this.state,
        parsed: parsed,
      },
      () => {
        this.updateURL();
      }
    );
  };
  dateIn = (start, end) => {
    let parsed = {
      ...this.state.parsed,
    };
    const startDate = util.moment(start).format("DD.MM.YYYY");
    const endDate = util.moment(end).format("DD.MM.YYYY");
    parsed.dateIn = startDate;
    parsed.dateOut = this.props.category == null || "7" ? endDate : "";

    this.setState(
      {
        ...this.state,
        parsed: parsed,
      },
      () => {
        this.updateURL();
      }
    );
  };

  sortFilter = (e) => {
    let parsed = {
      ...this.state.parsed,
    };
    parsed.sort = e;
    this.setState(
      {
        ...this.state,
        parsed: parsed,
      },
      () => {
        this.updateURL();
      }
    );
  };
  updateURL = () => {
    const stringified = queryString.stringify(this.state.parsed);
    this.props.history.push(`/?${stringified}`);
  };

  actualBanners = () => {
    let banner = {};
    if (this.props.banners.length > 0) {
      if (this.state.count) {
        let getAllId = this.props.banners.map((item) => {
          return item.categoryId;
        });
        for (let i = 0; i < this.props.banners.length; i++) {
          let find;
          if (this.props.category) {
            find = this.props.category.id
              ? getAllId.includes(this.props.category.id)
                ? this.props.category.id
                : "all"
              : "all";
          } else {
            find = "all";
          }
          if (this.props.banners[i].categoryId === find) {
            banner = this.props.banners[i];
          }
        }
      } else {
        if (this.props.banners.length === 1) {
          return this.props.banners[0];
        }

        const _rnd = Math.floor(Math.random() * 3);
        for (let i = 0; i < this.props.banners.length; i++) {
          if (i === _rnd) {
            banner = this.props.banners[i];
          }
        }
      }
    }
    return banner;
  };

  render = () => {
    const sliders = this.props.category
      ? this.props.category.subcat.map((item) => {
          return (
            <CategorySlider
              key={item.id}
              category={this.props.category}
              subCategory={item}
            />
          );
        })
      : this.props.catalog.categories
          .filter((item) => item.code !== "acc")
          .filter((item) => item.code !== "rides")
          .filter((item) => item.code !== "tours")
          .map((item) => <CategorySlider key={item.id} category={item} />);
    return (
      <Fragment>
        <Hero
          filterUrl={this.state.parsed}
          dateIn={this.dateIn}
          adults={this.adults}
        />
        <SearchResults
          filterUrl={this.state.parsed}
          // subCat={this.subCat}
          dateIn={this.dateIn}
          adults={this.adults}
          sortFilter={this.sortFilter}
          routerLocation={this.props.location}
        />
        <Banner actual={this.actualBanners()} />
        <ArticlesSlider />
        {sliders}
      </Fragment>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    catalog: state.catalog,
    banners: state.catalog.banners,
    filter: state.user.customerData.filter,
    category: state.user.customerData.category,
    categories: state.catalog.categories,
    sort: state.user.customerData.sort,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCategories: (value) => dispatch({ type: "updateCategories", value }),
    updateCatalog: (value) => dispatch({ type: "updateCatalog", value }),
    setFilter: (filter) => dispatch(actions.setFilter(filter)),
    setSort: (sort) => dispatch(actions.setSort(sort)),
    setCategory: (category) => dispatch(actions.setCategory(category)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutHome);
