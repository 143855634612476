import util from "../components/util/util";
import img from "../assets/image/dummy_small.jpg";
import validator from "validator";

const userObject = {
  firstName: "",
  lastName: "",
  gender: "",

  photo: img,
  email: "",
  phone: "",
  id: "",
  theme: "azul",
  dateBirth: null,
  country: "",
  city: "",
  social: {
    vk: "",
    facebook: "",
    twitter: "",
    instagram: "",
    ok: "",
  },
  favourites: [],
};

const validationRules = {
  EMAIL: "email",
  PHONE: "phone",
  FIRSTNAME: "firstName",
  FNAME: "Fname",
  LASTNAME: "Lname",
  LNAME: "lastName",
  COMMENT: "comment",
  PASSWORD: "password",
  PASSWORD_MATCH: "passwordMatch",
};

class User {
  constructor(properties) {
    const _user = { ...properties };
    _user.photo = util.prependHostname(_user.photo, img);
    _user.favourites = util.getJSONSafe(properties.favourites, []);
    return util.mergeObjects(userObject, _user);
  }

  /**
   * Validates single field
   * @param {string} value
   * @param {string} type
   */

  static validateField(value, type, otherValue = "") {
    const trimmedValue = value.trim();
    let result = true;
    let error = "";
    let cleanedValue;
    switch (type) {
      case validationRules.EMAIL:
        result =
          validator.isEmail(trimmedValue) &&
          validator.isLength(trimmedValue, { min: 6 });
        error =
          "Поле E-mail должно иметь длину мин. 6 символов и быть валидным email";
        break;
      case validationRules.PASSWORD:
        result = validator.isLength(trimmedValue, { min: 6 });
        error = "Поле Пароль должно быть мин. 6 символов";
        break;
      case validationRules.FNAME:
      case validationRules.FIRSTNAME:
        cleanedValue = trimmedValue.replace(/[\s\.\-]/g, "");
        result =
          validator.isLength(trimmedValue, { min: 3, max: 24 }) &&
          (validator.isAlpha(cleanedValue) ||
            validator.isAlpha(cleanedValue, "ru-RU"));
        error = "Поле Имя должно быть мин. 3 символа, и состоять из букв";
        break;
      case validationRules.LNAME:
      case validationRules.LASTNAME:
        cleanedValue = trimmedValue.replace(/[\s\.\-]/g, "");
        result =
          validator.isLength(trimmedValue, { min: 3, max: 24 }) &&
          (validator.isAlpha(cleanedValue) ||
            validator.isAlpha(cleanedValue, "ru-RU"));
        error = "Поле Фамилия должно быть мин. 3 символа, и состоять из букв";
        break;
      case validationRules.PHONE:
        const trimmedPhone = trimmedValue.replace(/^\+/, "");
        result = validator.isMobilePhone(trimmedPhone);
        error = "Поле Телефон должно состоять только из цифр и знака +";
        break;
      case validationRules.PASSWORD_MATCH:
        const trimmedOtherValue = otherValue.trim();
        result = validator.equals(trimmedValue, trimmedOtherValue);
        error = "Пароли должны совпадать";
        break;
      case validationRules.COMMENT:
        result = validator.isLength(trimmedValue, { max: 400 });
        error = "Комментарий не может быть больше 400 символов";
        break;
      default:
        result = false;
    }

    return {
      result,
      error: !result && error,
    };
  }

  /**
   *
   * Validates a set of fields
   *
   * @param {array} fields
   */

  static validateFields(fields) {
    let result = true;
    let errors = [];

    fields.forEach((field) => {
      const validationResult = User.validateField(...field);
      if (!validationResult.result) {
        result = false;
        errors.push(validationResult.error);
      }
    });

    return {
      result,
      errors,
    };
  }

  /**
   *
   * Deprecated
   *
   *
   */
  static validateUserData(user) {
    if (!user.firstName || user.firstName.length < 3) {
      return {
        result: false,
        error: 'Поле "Имя" должно иметь длину мин. 3 символа',
      };
    }
    if (!user.lastName || user.lastName.length < 3) {
      return {
        result: false,
        error: 'Поле "Фамилия" должно иметь длину мин. 3 символа',
      };
    }

    if (user.phone && user.phone.length < 8) {
      return {
        result: false,
        error: 'Поле "Телефон" должно иметь длину мин. 8 символов',
      };
    }
    if (user.password || user.phone.passwordConfirm) {
      return User.validatePasswords(user.password, user.passwordConfirm);
    }

    return { result: true };
  }

  static validatePasswords(password, passwordConfirm) {
    if (password.length < 6 || passwordConfirm.length < 6) {
      return {
        result: false,
        error: "Длина пароля не может быть меньше 6 символов",
      };
    }

    if (password !== passwordConfirm) {
      return {
        result: false,
        error: "Пароли не совпадают",
      };
    }

    return { result: true };
  }
}

// Export User Model
export default User;

// Export validation rules const
export { validationRules };

/**
 *
 * Deprecated
 *
 */
export const createObject = (user) => {
  // test if its working
  console.warn("[!!! Deprecated export was used ] in ", __filename);
  let userImage = user.img;

  if (!user.img || user.img.length < 5) {
    userImage = img;
  }
  const _user = {
    firstName: user.fname,
    lastName: user.lname,
    gender: user.gender,
    photo: userImage,
    image: userImage,
    email: user.email,
    phone: user.phone,
    id: user.id,
    favourites: util.getJSONSafe(user.favourites, []),
  };
  return util.mergeObjects(userObject, _user);
};
