import React, { useRef, useEffect } from "react";
import css from "../../layouts/styles/LayoutInfo.module.scss";

const Refund = () => {
  // const buttonRef = useRef();

  //get open refund form button
  // const refundButton = document.querySelector(".b24-web-form-popup-btn-7");

  // useEffect(() => {
  //   if (buttonRef.current && refundButton) {
  //     //add refund button to component
  //     refundButton.classList.add(css["styled-b24-btn"]);
  //     refundButton.style.display = "block";
  //     buttonRef.current.appendChild(refundButton);
  //   } else if (buttonRef.current && !refundButton) {
  //     //if there is no refundButton create new and add it to component
  //     const clonedRefundBtn = document.createElement("button");
  //     clonedRefundBtn.className = `b24-web-form-popup-btn-7 ${css["styled-b24-btn"]}`;
  //     clonedRefundBtn.textContent = "Открыть форму на возврат денежных средств";
  //     buttonRef.current.appendChild(clonedRefundBtn);
  //   }

  //   //mount new Bitrix24 form
  //   window.b24form({
  //     id: "7",
  //     lang: "ru",
  //     sec: "ovs2ah",
  //     type: "button",
  //     click: "",
  //   });

  //   window.scrollTo(0, 0);
  // }, [buttonRef, refundButton]);

  return (
    <div className={css["container"]}>
      <div className={css["text"]}>
        <div className={css["text-from-server"]}>
          <div>
            <h3>Чтобы осуществить возврат денежных средств необходимо:</h3>
            <ol
              style={{ listStyleType: "decimal", paddingInlineStart: "1rem" }}
            >
              <li>
                <a
                  href="https://uniqtrip.ru/dokumenty/soglasie_na_obrabotku_personalnykh_dannykh_pri_vozvrate.docx"
                  download
                >
                  Скачать
                </a>{" "}
                <span>
                  и заполнить бланк на согласие на обработку персональных данных
                </span>
              </li>
              <li>
                <a
                  href="https://uniqtrip.ru/dokumenty/zayavleniye_na_vozvrat.docx"
                  download
                >
                  Скачать
                </a>{" "}
                <span>и заполнить бланк заявления на возврат</span>
              </li>
              <li>
                <span>Заполнить форму возврата денежных средств</span>
              </li>
            </ol>
          </div>
          {/* <div
            style={{
              marginTop: "20px",
            }}
            ref={buttonRef}
          ></div>

          <p className={css["text-important-info"]}>
            <span>ВАЖНО:</span> Оба бланка должны быть заполнены, т.к. их нужно
            загружать в форму возврата
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Refund;

