import { createObject } from "../../model/User";

const config = {
	methodName: "Register",
	isTest: false,
	isDummy: false,
	method: "POST",
	dummyData: {},
	prepareRequest: (r) => {
		return {
			fname: r.fname,
			lname: r.lname,
			phone: r.phone,
			email: r.email,
			password: r.password,
			password_confirm: r.password_confirm,
			createAccount: true,
		};
	},
	prepareResponse: (r) => {
		if (!r.error) {
			r.user = createObject(r);
		}
		return r;
	},
	handleError: (e) => e,
};

export default config;
