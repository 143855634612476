const config = {
    methodName: "AddFavourites",
    isTest: false,
    isDummy: false,
    method: "POST",
    dummyData: {},
    prepareRequest: r => {
        const favouritesWithoutTimestamp = r.map(e => {
            delete e.addedOn;
            return e;
        });
        return { favElements: favouritesWithoutTimestamp };
    },

    prepareResponse: r => {
        if (!Array.isArray(r)) {
            r = [];
        }
        return r;
    },
    handleError: e => e
};
export default config;
