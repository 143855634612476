import * as actionTypes from "../actionTypes";
import api from "../../../components/API/new/API";
import util from "../../../components/util/util";

export const uploadUserPhoto = (data, updateCallback) => {
    return dispatch => {
        api.user.UploadUserPhoto(data).then(response => {
            if (!response.data.error) {
                updateCallback();
                dispatch({
                    type: actionTypes.UPLOAD_PHOTO_SUCCESS,
                    photo: response.data.photo
                });
            }
        });
    };
};

export const update = (data, saveCallback) => {
    return dispatch => {
        dispatch({
            type: actionTypes.UPDATE_PENDING
        });
        api.user
            .Update({ ...data })
            .then(response => {
                if (!response.data.error) {
                    saveCallback();
                    return dispatch({
                        type: actionTypes.UPDATE_SUCCESS,
                        user: response.data.user
                            ? response.data.user
                            : response.data
                    });
                }
                const message = response.data.msg
                    ? response.data.msg
                    : "Операция завершилась неудачно";

                // dispatch error action only if credentials were provided

                if (data) {
                    return dispatch({
                        type: actionTypes.UPDATE_FAILED,
                        error: message
                    });
                }
                return dispatch({
                    type: actionTypes.UPDATE_RESET
                });
            })
            .catch(e => {
                const message = "Произошла ошибка сервера";
                dispatch({
                    type: actionTypes.UPDATE_FAILED,
                    error: message
                });
            });
    };
};

export const view = data => {
    return dispatch => {
        api.user
            .View()
            .then(response => {
                if (!response.data.error) {
                    return dispatch({
                        type: actionTypes.UPDATE_SUCCESS,
                        user: response.data.user
                            ? response.data.user
                            : response.data
                    });
                }
                const message = response.data.msg
                    ? response.data.msg
                    : "Операция завершилась неудачно";

                // dispatch error action only if credentials were provided

                if (data) {
                    return dispatch({
                        type: actionTypes.UPDATE_FAILED,
                        error: message
                    });
                }
                return dispatch({
                    type: actionTypes.UPDATE_RESET
                });
            })
            .catch(e => {
                const message = "Произошла ошибка сервера";
                dispatch({
                    type: actionTypes.UPDATE_FAILED,
                    error: message
                });
            });
    };
};
