import React, { useState, Fragment } from "react";
import util from "../util/util";
import css from "./Cart.module.scss";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import Puff from "react-preloader-icon/loaders/Puff";
import PreloaderIcon from "react-preloader-icon";

const QuantityInput = (props) => {
  const [isEdited, toggleEdit] = useState(false);
  const [value, setValue] = useState(parseInt(props.children));
  const [isLoading, toggleLoading] = useState(false);

  const updateValue = (value) => {
    let valToSet = value;

    if (value > 99) {
      valToSet = 99;
    } else if (value <= 0) {
      valToSet = 1;
    }

    setValue(valToSet);
  };
  
  const html = (
    <div className={css["product-quantity"]} onClick={() => toggleEdit(true)}>
      {isEdited ? (
        <Fragment>
          <input
            type="number"
            value={value}
            onChange={(e) => updateValue(e.target.value)}
            min="1"
            max="99"
          />
          <button
            title="Сохранить указанное количество"
            onClick={(e) => {
              e.preventDefault();
              toggleLoading(true);
              return props.quantityHandler(value, () => {
                toggleEdit(false);
                toggleLoading(false);
              });
            }}
            className={util.join(
              css["product-quantity-btn"],
              css["product-quantity-btn--done"]
            )}
          >
            {isLoading ? (
              <PreloaderIcon
                loader={Puff}
                size={16}
                strokeWidth={8}
                strokeColor="#666"
                duration={800}
              />
            ) : (
              <DoneOutlineIcon />
            )}
          </button>
        </Fragment>
      ) : (
        <input
          type="number"
          value={value}
          min="1"
          max="99"
          disabled={true}
        />
      )}
    </div>
  );

  return html;
};

export default QuantityInput;
