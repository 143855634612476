import React, { useState, Fragment, useEffect, useRef } from "react";
import classes from "./styles/LeftPanel.module.scss";
import Category from "./_LeftPanelCategory";
import * as actions from "../../store/actions/index";
import switchCatArr from "../../assets/scss/ut-icons/switch_category_arrow.png";
import { connect } from "react-redux";
import util from "../util/util";
import LocationSelect from "./_LocationSelect";
import { Scrollbars } from "react-custom-scrollbars";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
const gridSize = () => {
  const SMALL = 399;
  const MED = 1024;
  const GRID = {
    small: 1,
    med: 2,
    large: 3,
  };
  return window.innerWidth <= SMALL
    ? GRID.small
    : window.innerWidth <= MED
    ? GRID.med
    : GRID.large;
};

const checkWidth = () =>
  window.innerWidth > 1500 ? 285 : window.innerWidth > 1130 ? 190 : 210;

const LeftPanel = (props) => {
  // Drag
  let dragStartX = 0;
  let left = 0;
  let dragged = false;

  // FPS Limit
  let startTime;
  let fpsInterval = 1000 / 60;

  let pos = 0;

  useEffect(() => {
    window.addEventListener("touchend", onDragEndTouch);
    // setSize(gridSize());
    // if (size >= 3) {
    //   setSwipe(true);
    // }
    // return function cleanup() {
    // window.removeEventListener("touchend", onDragEndTouch);
    // };
  });

  const categories = useRef(null);

  const setCategory = (category) => {
    if (category) {
      if (!props.category) {
        return props.setCategory(category);
      } else if (category.id !== props.category.id) {
        return props.setCategory(category);
      } else {
        return props.setCategory(null);
      }
    }
    return props.setCategory(null);
  };
  const [locationModal, toggleLocation] = useState(false);
  const [swipe, setSwipe] = useState(true);
  // const [size, setSize] = useState(2);

  const categoriesHtml = [];
  //swipe for Tablet start
  const onDragStartTouch = (evt) => {
    const touch = evt.targetTouches[0];
    onDragStart(touch.clientX);
    window.addEventListener("touchmove", onTouchMove);
  };
  const onDragStart = (clientX) => {
    dragged = true;
    dragStartX = clientX;
    startTime = Date.now();
  };
  const onDragEndTouch = (evt) => {
    window.removeEventListener("touchmove", onTouchMove);
    onDragEnd();
  };
  const onDragEnd = () => {
    if (dragged) {
      dragged = false;
      if (left > 0) {
        setSwipe(false);
      } else {
        setSwipe(true);
      }
      // left = 0;
    }
  };
  const onTouchMove = (evt) => {
    const touch = evt.targetTouches[0];
    let lefter = touch.clientX - dragStartX;
    pos = dragStartX;
    const minPoint = 0;
    const maxPoint = (categories.current.offsetWidth / 40) * 22 * -1;
    // if (lefter < 0) {
    //   left = lefter;
    // }

    categories.current.style.transform = `translate(${lefter}px)`;

    if (lefter < maxPoint) {
      lefter = maxPoint;
    } else if (lefter > minPoint) {
      lefter = minPoint;
    }

    categories.current.style.transform = `translate(${lefter}px)`;

    pos = lefter;
  };
  //swipe for Tablet end

  props.categories.forEach((category, index) => {
    const checked = Boolean(props.category) && props.category.id === category.id;
    categoriesHtml.push(
      <Category
        key={index}
        index={index}
        checked={checked}
        category={category}
        onClick={() => setCategory(category)}
      />
    );
  });

  const moveCategories = (direction) => {
    let newPosition;
    const minPoint = 0;
    const maxPoint = (categories.current.offsetWidth / 40) * 22 * -1;

    if (direction === "forward") {
      newPosition = pos <= maxPoint ? pos : pos - 110;
      categories.current.style.transform = `translate(${newPosition}px)`;
    } else if (direction === "back") {
      newPosition = pos >= minPoint ? pos : pos + 110;
      categories.current.style.transform = `translate(${newPosition}px)`;
    }
    pos = newPosition;
  };

  return (
    <Fragment>
      {locationModal ? (
        <LocationSelect
          pickHandler={(location) => {
            props.setLocation(location);
            toggleLocation(false);
          }}
          hideHandler={() => toggleLocation(false)}
        />
      ) : null}
      <div
        className={util.join(
          classes["panel-left"],
          Boolean(props.category) && classes["panel-left--solid"]
        )}
      >
        <div className={classes["category-picked-info"]}>
          {props.category ? (
            <>
              <div
                className={classes["category-picked-info-icon"]}
                style={{
                  backgroundImage:
                    "url(" + util.prependHostname(props.category.icon) + ")",
                  backgroundRepeat: "no-repeat",
                  marginRight: "19px",
                  filter: "invert(100%)",
                }}
              ></div>
              <div className={classes["category-picked-info-name"]}>
                {props.category.name}
              </div>
            </>
          ) : (
            <div className={classes["category-picked-info-name"]}>
              Выберите категорию
            </div>
          )}
        </div>
        {/* <p className={classes["heading"]}>Вы ищете...</p> */}
        {/* <div className={classes["location"]}>
					<LocationOnOutlinedIcon
						size={24}
						className=" ut-material-icon"
					/>
					<a onClick={() => toggleLocation(true)}>
						{props.location.country}, {props.location.city}
					</a>
				</div> */}
        <img
          onClick={() => moveCategories("forward")}
          src={switchCatArr}
          alt=""
          className={`${classes["category-switch-arrows"]} ${classes["category-switch-arrows_forward"]}`}
        />
        <div className={classes["categories-container"]}>
          <form action="#search-category">
            <ul
              onTouchStart={onDragStartTouch}
              ref={categories}
              className={
                swipe ? classes["categories"] : classes["moveCategories"]
              }
            >
              {categoriesHtml}
            </ul>
          </form>
        </div>
        <img
          onClick={() => moveCategories("back")}
          className={`${classes["category-switch-arrows"]} ${classes["category-switch-arrows_back"]}`}
          src={switchCatArr}
          alt=""
        />

        {/* <div onClick={() => setSwipe(!swipe)} className={classes["forTablet"]}>
          {" "}
          . . .{" "}
        </div> */}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  categories: state.catalog.categories,
  category: state.user.customerData.category,
  location: state.user.location,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(actions.addToCart(item)),
  setCategory: (item) => dispatch(actions.setCategory(item)),
  setLocation: (location) => dispatch(actions.setLocation(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
