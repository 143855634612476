import React, { Component } from "react";
import SearchResultItem from "./_SearchResultItem";
import css from "./SearchResults.module.scss";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import util from "../util/util";
import { Scrollbars } from "react-custom-scrollbars";

class SearchResultsList extends Component {
  render() {
    let count = false;
    const itemsByGeozone = [];
    const items = [];

    for (let i = 0; i < this.props.products.length; i++) {
      const itemData = {
        productId: this.props.products[i].params.productid,
        categoryId: this.props.products[i].category_id,
        dateIn: this.props.filter.dateIn.format("YYYY-MM-DD"),
        dateOut: this.props.filter.dateOut.format("YYYY-MM-DD"),
        adults: this.props.filter.adults,
        children: this.props.filter.children,
        time: this.props.filter.time,
        Fname: "",
        Lname: "",
        email: "",
      };
      if (this.props.category.id === 7) {
        i >= this.props.countBest ? (count = true) : (count = false);
      }

      if (this.props.products[i].code === "_") continue;

      if (this.props.products[i].params.GEOZONE === this.props.filter.geozone) {
        itemsByGeozone.push(
          <SearchResultItem
            category={this.props.category}
            data={this.props.products[i]}
            key={i}
            addToCart={() => {
              this.props.addToCart(itemData);
            }}
            getHover={this.props.getHover}
            hoverId={this.props.hoverId}
            endPartners={this.props.endPartners}
            count={count}
            search={false}
          />
        );
      } else {
        items.push(
          <SearchResultItem
            category={this.props.category}
            data={this.props.products[i]}
            key={i}
            addToCart={() => {
              this.props.addToCart(itemData);
            }}
            getHover={this.props.getHover}
            hoverId={this.props.hoverId}
            endPartners={this.props.endPartners}
            count={count}
            search={false}
          />
        );
      }
    }

    const geozonesFilterLine = (
      <div>
        <div className={css["filter-geozones-line"]} />
        <div style={{ marginBottom: "1.2rem" }}>Другие предложения</div>
      </div>
    );

    const itemsTotal = (
      <>
        {itemsByGeozone.length > 0 ? (
          <>
            {itemsByGeozone}
            {geozonesFilterLine}{" "}
          </>
        ) : itemsByGeozone.length === 0 &&
          this.props.filter.geozone !== "all" ? (
          <>
            По выбранной геозоне предложений нет
            {geozonesFilterLine}
          </>
        ) : null}
        {items.length > 0 ? items : "Других предложений нет"}
      </>
    );

    return (
      <div className={css["data"]}>
        <ul
          className={
            this.props.disableBtn
              ? util.join(css["list"], css["list--height"])
              : css["list"]
          }
          ref={this.props.loadSize <= 2 ? null : this.props.scrollRef}
        >
          {this.props.loadSize <= 2 ? (
            itemsByGeozone || items ? (
              itemsTotal
            ) : (
              "На запрошенный период предложения не найдены"
            )
          ) : (
            <Scrollbars>
              {itemsByGeozone || items
                ? itemsTotal
                : "На запрошенный период предложения не найдены"}
            </Scrollbars>
          )}
          <li></li>
        </ul>
        {this.props.disableBtn ? null : (
          <button
            className={`ut-theme-color ${css["btn"]}`}
            onClick={this.props.onMoreBtnClick}
          >
            <span>Еще</span>
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.user.cart,
  filter: state.user.customerData.filter,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(actions.addToCart(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsList);

