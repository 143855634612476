import React, { Fragment } from "react";
import css from "./Hero.module.scss";
import util from "../util/util";
import { Link } from "react-router-dom";
const Offer = (props) => {
  const link = `/catalog/${props.parentCode}/${props.productId}`;
  return (
    <Fragment>
      <li
        className={css["offer"]}
        // style={{ backgroundImage: `url('${props.image}')` }}
      >
        <Link to={link} className={css["rightLink"]}></Link>
        <div className={`${css["offer-data"]}`}>
            <img src={props.image} alt="" className={css["offer-image"]} />
          <div className={css["offer-info"]}>
            <p className={css["offer-title"]}>{props.title}</p>
            <div className={css["offerButton"]}>
              <button
                onClick={props.cartHandler}
                className={`${css["offerBtn"]}`}
              >
                <span>{util.currencyFormat(props.price)}</span>
              </button>
            </div>
          </div>
        </div>
      </li>
    </Fragment>
  );
};

export default Offer;
