import React, { Fragment, useState } from "react";
import { PanelWide, Input, Select } from "../Interface/Interface";
import { connect } from "react-redux";
import css from "./styles/UserEdit.module.scss";
import { RadioGroup, Button } from "../Interface/Interface";
import * as actions from "../../store/actions/index";
import UserModel, { validationRules } from "../../model/User";

import util from "../util/util";

const themeVariants = [
    {
        title: "Синяя",
        value: "azul",
        selected: false
    },
    {
        title: "Красная",
        value: "rojo",
        selected: false
    }
];

const genderVariants = [
    {
        title: "Не указано",
        value: "",
        selected: false
    },
    {
        title: "Мужской",
        value: "male",
        selected: false
    },
    {
        title: "Женский",
        value: "female",
        selected: false
    }
];
const UserEdit = props => {
    const initialUserData = {
        ...props.user,
        password: "",
        passwordConfirm: ""
    };

    const [userFormData, updateForm] = useState(initialUserData);

    const updateUserField = field => {
        const updatedUserFormData = {
            ...userFormData
        };

        if (field.type && field.type.toLowerCase() === "file") {
            updatedUserFormData[field.name] = field.files[0];
        } else {
            updatedUserFormData[field.name] = field.value;
        }
        return updateForm(updatedUserFormData);
    };

    // Validate fields before sending for update
    const updateHandler = () => {
        const fieldsToValidate = [
            [userFormData.firstName, validationRules.FIRSTNAME],
            [userFormData.lastName, validationRules.LASTNAME],
            [userFormData.phone, validationRules.PHONE]
        ];
        if (userFormData.password.length > 0) {
            fieldsToValidate.push(
                [userFormData.password, validationRules.PASSWORD],
                [
                    userFormData.password,
                    validationRules.PASSWORD_MATCH,
                    userFormData.passwordConfirm
                ]
            );
        }
        const validationResult = UserModel.validateFields(fieldsToValidate);
        if (validationResult.result) {
            return props.update(userFormData, props.saveCallback);
        }
        return alert(validationResult.errors.join("\n"));
    };

    let countriesList = props.countries
        .sort((a, b) =>
            a.countryId < b.countryId ? -1 : a.countryId > b.countryId ? 1 : 0
        )
        .map(country => ({
            value: country.countryId,
            text: country.countryName
        }));

    countriesList = [
        { value: "0", text: "--- Выберите страну" },
        ...countriesList
    ];

    return (
        <Fragment>
            <h3 className="ut-section__title">Редактирование профиля</h3>
            <PanelWide className={css.panel}>
                <div className={css.column}>
                    <Input
                        label="Имя"
                        name="firstName"
                        value={userFormData.firstName}
                        onChange={e => updateUserField(e.target)}
                    />
                    <Input
                        label="Фамилия"
                        value={userFormData.lastName}
                        name="lastName"
                        onChange={e => updateUserField(e.target)}
                    />
                    <Input
                        label="Телефон"
                        value={userFormData.phone}
                        name="phone"
                        onChange={e => updateUserField(e.target)}
                    />

                    <h6 className={css["input-group__title"]}>Смена пароля</h6>
                    <Input
                        label="Новый пароль"
                        name="password"
                        onChange={e => updateUserField(e.target)}
                        value={userFormData.password}
                    />
                    <Input
                        label="Повторите новый пароль"
                        name="passwordConfirm"
                        onChange={e => updateUserField(e.target)}
                        value={userFormData.passwordConfirm}
                    />
                </div>
                <div className={css.column}>
                    <RadioGroup
                        title="Пол"
                        name="gender"
                        elements={[...genderVariants]}
                        currentValue={userFormData.gender}
                        clickHandler={updateUserField}
                    />
                    <RadioGroup
                        title="Тема"
                        name="theme"
                        elements={[...themeVariants]}
                        currentValue={userFormData.theme}
                        clickHandler={updateUserField}
                    />
                    <Input
                        label="Дата рождения"
                        name="dateBirth"
                        onChange={e => updateUserField(e.target)}
                        value={userFormData.dateBirth}
                    />
                    <Select
                        label="Страна проживания"
                        options={countriesList}
                        value={userFormData.country}
                        onChange={e => updateUserField(e.target)}
                        name="country"
                    />
                    <Input
                        label="Город"
                        name="city"
                        value={userFormData.city}
                        onChange={e => updateUserField(e.target)}
                    />
                </div>
                <div className={css["column-full"]}>
                    <Button
                        loading={props.updateState.loading}
                        onClick={updateHandler}
                    >
                        Сохранить
                    </Button>
                </div>
            </PanelWide>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    countries: state.catalog.countries,
    user: state.user.profile,
    updateState: state.user.updateState
});

const mapDispatchToProps = dispatch => ({
    update: (userData, saveCallback) =>
        dispatch(actions.update(userData, saveCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
