import axios from "axios";
import util from "../../util/util";

class API {
    constructor(url, endpoints) {
        this.url = url;
        this.axios = axios.create({
            baseURL: url,
            responseType: "text",
            headers: {
                "Content-Type": "text/plain"
            },
            withCredentials: true
        });
        if (!endpoints || (typeof endpoints).toLowerCase() !== "object") {
            throw new Error("Valid Endpoints set is required");
        }

        for (let e in endpoints) {
            let methods = {};
            for (let m in endpoints[e]) {
                let config = {
                    ...endpoints[e][m],
                    axios: this.axios,
                    endpoint: e
                };

                methods[m] = params => {
                    const o = new API_EndpointMethod(config);

                    return o.sendFiles
                        ? o.execWithFormData(params, url)
                        : o.exec(params);
                };
            }
            this[e] = { ...methods };
        }
    }
}

class API_EndpointMethod {
    constructor(
        methodName,
        isTest,
        isDummy,
        method = "GET",
        dummyData,
        prepareRequest = null,
        prepareResponse = null,
        handleError = null,
        axios,
        endpoint,
        sendFiles = false
    ) {
        if (typeof methodName === "object") {
            const config = { ...methodName };
            methodName = config.methodName;
            isTest = config.isTest || false;
            isDummy = config.isDummy || false;
            sendFiles = config.sendFiles || false;
            dummyData = config.dummyData || [];
            axios = config.axios || null;
            endpoint = config.endpoint || null;
            prepareRequest = config.prepareRequest || null;
            prepareResponse = config.prepareResponse || null;
            handleError = config.handleError || null;
            method = config.method || "GET";
        }
        this.methodName = methodName;
        this.isTest = isTest;
        this.isDummy = isDummy;
        this.sendFiles = sendFiles;
        this.dummyData = dummyData;
        this.axios = axios;
        this.method = method.toLowerCase();
        this.endpoint = endpoint;
        if (typeof prepareRequest === "function") {
            this.prepareRequest = prepareRequest;
        }
        if (typeof prepareResponse === "function") {
            this.prepareResponse = prepareResponse;
        }
        if (typeof handleError === "function") {
            this.handleError = handleError;
        }
    }

    makeURL() {
        const e = this.endpoint;
        const m = this.methodName;
        return `${e}.php?action=${m}`;
    }

    prepareRequest(params) {
        return params;
    }

    handleError(e) {
        return e;
    }

    prepareResponse(data) {
        return data;
    }

    // If method presumes file upload - create
    // separate Axios instance with corresponding params

    execWithFormData(files = {}, baseURL = "") {
        let methodUrl = this.makeURL();
        if (this.isDummy) {
            return new Promise(resolve => {
                resolve({ data: this.dummyData });
            });
        }

        const filesAxios = axios.create({
            baseURL,
            responseType: "text",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            withCredentials: true
        });

        const formData = new FormData();
        files.forEach(file => formData.append(...file));

        formData.append("source", "web");

        if (this.isTest) {
            methodUrl += "&test=1";
            formData.append("test", 1);
        }

        return filesAxios({
            method: "POST",
            url: methodUrl,
            data: formData,
            transformResponse: r => {
                const data = util.getJSONSafe(r);
                if (!data) return null;

                return data.error
                    ? this.handleError(data)
                    : this.prepareResponse(data);
            }
        });
    }

    exec(params = {}) {
        const requestParams = this.prepareRequest(params);
        const axios = this.axios;
        let methodUrl = this.makeURL();
        const data = {
            params: { ...requestParams },
            source: "web"
        };
        if (this.isTest) {
            methodUrl += "&test=1";
            data.test = 1;
        }
        if (this.isDummy) {
            return new Promise(resolve => {
                resolve({ data: this.dummyData });
            });
        }

        return axios({
            method: this.method,
            url: methodUrl,
            data,
            transformResponse: r => {
                const data = util.getJSONSafe(r);
                if (!data) return null;

                return data.error
                    ? this.handleError(data)
                    : this.prepareResponse(data);
            }
        });
    }
}

export default API;
export { API_EndpointMethod };
