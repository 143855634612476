import React from "react";
import util from "../util/util";
import css from "./styles/Filter.module.scss";

const SearchResultsButton = props => {
    const className = util.join(
        css["btn-category"],
        props.selected && css["btn-category--on"]
    );
    return (
        <button {...props} className={className} onClick={props.onClick}>
            {props.children}
        </button>
    );
};

export default SearchResultsButton;
