import createCart from "../../model/Cart";

const config = {
	methodName: "AddToCart",
	isTest: false,
	isDummy: false,
	method: "post",
	dummyData: {},
	prepareRequest: (p) => {
		return {
			productId: p.productId,
			categoryId: p.categoryId,
			dateIn: p.dateIn,
			dateOut: p.dateOut,
			adults: p.adults,
			children: p.children,
			time: p.time,
			Fname: p.Fname,
			Lname: p.Lname,
			email: p.email,
		};
	},
	prepareResponse: (r) => createCart(r),
	handleError: (e) => e,
};

export default config;
