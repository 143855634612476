import React, { Component } from "react";
import Hero from "./../components/Hero/Hero";
import CategorySlider from "./../components/CategorySlider/CategorySlider";
import SearchResults from "../components/SearchResults/SearchResults";
import { connect } from "react-redux";
import Banner from "../components/Banner/Banner";
import Wrapper from "../hoc/Wrapper/Wrapper";
import util from "../components/util/util";
import * as actions from "../store/actions/index";

class LayoutCategory extends Component {
  state = {
    category: null,
    offers: [],
    count: false,
  };

  componentDidMount = () => {
    if (this.props.catalog.length > 0) {
      this.syncUrlCategory();
    }
    window.scrollTo(0, 0);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.catalog.length !== prevProps.catalog.length) {
      return this.syncUrlCategory();
    }
    if (this.props.match.params.category !== prevProps.match.params.category) {
      return this.syncUrlCategory();
    }
    window.scrollTo(0, 0);
    if (this.props.category !== prevProps.category) {
      const newState = {
        ...this.state,
        count: true,
      };
      // this.props.loading(true);

      this.setState(
        {
          ...newState,
        },
        this.actualBanners
      );
    }
  };

  actualBanners = () => {
    let banner = {};

    if (this.props.banners.length > 0) {
      if (this.state.count) {
        let getAllId = this.props.banners.map((item) => {
          return item.categoryId;
        });

        for (let i = 0; i < this.props.banners.length; i++) {
          let find;
          if (this.props.category) {
            find = this.props.category.id
              ? getAllId.includes(this.props.category.id)
                ? this.props.category.id
                : "all"
              : "all";
          } else {
            find = "all";
          }

          if (this.props.banners[i].categoryId === find) {
            banner = this.props.banners[i];
          }
        }
      } else {
        const _rnd = Math.floor(Math.random() * 3);
        for (let i = 0; i < this.props.banners.length; i++) {
          console.log(_rnd);

          if (i === _rnd) {
            banner = this.props.banners[i];
          }
        }
      }
    }

    return banner;
  };
  syncUrlCategory() {
    const { category } = this.props.match.params;

    if (category && this.props.catalog.length > 0) {
      const _currentCategory = this.props.catalog.filter(
        (item) => item.code === category
      );
      if (_currentCategory.length > 0) {
        this.props.setCategory({ ..._currentCategory[0] });
      }
    }
  }

  render = () => {
    const sliders = this.props.category
      ? this.props.category.subcat.map((item, index) => {
          return (
            <CategorySlider
              key={item.id}
              category={this.props.category}
              subCategory={item}
            />
          );
        })
      : null;

    return (
      <Wrapper>
        <Hero />
        <SearchResults />
        <Banner actual={this.actualBanners()} />
        {sliders}
      </Wrapper>
    );
  };
}

const mapStateToProps = (state) => ({
  catalog: state.catalog.categories,
  category: state.user.customerData.category,
  banners: state.catalog.banners,
});
const mapDispatchToProps = (dispatch) => ({
  setCategory: (category) => dispatch(actions.setCategory(category)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LayoutCategory);
