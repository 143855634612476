import * as actionTypes from "../actionTypes";
import api from "../../../components/API/new/API";
import favouritesModel from "../../../model/Favourites";
import util from "../../../components/util/util";
/**
 *
 * Favourites
 *
 */

export const addFavourite = (itemData, isLogged) => {
    return dispatch => {
        if (isLogged) {
            api.user
                .AddFavourite(itemData)
                .then(response => {
                    dispatch({
                        type: actionTypes.SET_FAVOURITES,
                        data: response.data
                    });
                })
                .catch(e => util.log("error adding favourites"));
        } else {
            favouritesModel.add(itemData);
            dispatch({
                type: actionTypes.ADD_FAVOURITE,
                item: itemData
            });
        }
    };
};

export const removeFavourite = (itemData, isLogged) => {
    favouritesModel.remove(itemData);
    return dispatch => {
        if (isLogged) {
            api.user
                .RemoveFavourite(itemData)
                .then(response => {
                    dispatch({
                        type: actionTypes.SET_FAVOURITES,
                        data: response.data
                    });
                })
                .catch(e => util.log("error adding favourites"));
        } else {
            dispatch({
                type: actionTypes.REMOVE_FAVOURITE,
                item: itemData
            });
        }
    };
};
