import util from "../components/util/util";

const LOCALSTORAGE_NAME = "favourites";

class Favourites {
    constructor() {
        throw new Error(
            "Favourites object cannot be constructed. Use its static methods instead !"
        );
    }

    static get = () => {
        let favourites = util.getJSONSafe(
            localStorage.getItem(LOCALSTORAGE_NAME),
            []
        );

        // Get favourites newer than 2 days
        const onlyNewFavourites = favourites.filter(e => {
            if (!e.addedOn) return false;
            const addedOn = parseInt(e.addedOn);
            return Date.now() - addedOn < 3600 * 24 * 2 * 1000;
        });

        return onlyNewFavourites;
    };

    static set = data => {
        // Remove favourites older than 2 days
        const onlyNewFavourites = data.filter(e => {
            if (!e.addedOn) return false;
            const addedOn = parseInt(e.addedOn);
            return Date.now() - addedOn < 3600 * 24 * 2 * 1000;
        });

        const favourites = JSON.stringify(onlyNewFavourites);

        localStorage.setItem(LOCALSTORAGE_NAME, favourites);
    };

    static clear = () => {
        localStorage.clear(LOCALSTORAGE_NAME);
    };

    static add = item => {
        let favourites = Favourites.get();
        if (item) {
            item.addedOn = Date.now();
            favourites.push(item);
        }
        Favourites.set(favourites);
    };

    static remove = item => {
        let favourites = Favourites.get();
        favourites = favourites.filter(favItem => favItem.id != item.id);
        Favourites.set(favourites);
    };

    static merge = items => {
        const ids = [];
        const newItems = items.filter(item => {
            if (ids.indexOf(item.id) === -1) {
                ids.push(item.id);
                return true;
            }
            return false;
        });
        return newItems;
    };
}

export const createObject = (
    id,
    name,
    image,
    categoryCode,
    partnerCode,
    text
) => ({
    id,
    name,
    image,
    categoryCode,
    partnerCode,
    text
});

export default Favourites;
