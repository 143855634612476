import React, { useState, Fragment } from "react";
import TimeChart from "./TimeChart";
import css from "./styles/TimePicker.module.scss";

const MorePicker = (props) => {
  let [pickerVisible, setPickerVisible] = useState(props.toggleVisible);
  let sum = () => {
    if (props.adults < 2) {
      return " взрослый";
    } else {
      return " взрослых";
    }
  };
  let sumChild = () => {
    if (props.children < 1) {
      return "без детей";
    } else if (props.children === 1) {
      return props.children + " ребенок";
    } else {
      return props.children + " детей";
    }
  };
  let chart = (
    <TimeChart
      more={props.more}
      adults={props.adults}
      pickHandler={(ad, ch) => {
        setPickerVisible(false);
        props.pickHandler(ad, ch);
      }}
      hideHandler={() => setPickerVisible(!pickerVisible)}
      children={props.children}
    />
  );
  let main = props.main;
  let product = props.product;
  let classes = [css["moreWrapper"]];

  if (product) {
    classes.push(css.productAdults);
  }
  return (
    <Fragment>
      <div className={classes.join(" ")}>
        <p
          onClick={() => setPickerVisible(!pickerVisible)}
          className={main ? css["picker-input"] : css["input-subfilter"]}
        >
          {props.adults + sum()}
        </p>
        <p
          onClick={() => setPickerVisible(!pickerVisible)}
          className={main ? css["picker-input"] : css["input-subfilter"]}
        >
          {sumChild()}
        </p>
      </div>
      {pickerVisible ? chart : null}
      {/* <div
        onClick={() => setPickerVisible(!pickerVisible)}
        className={css["clickOpen"]}
      ></div> */}
    </Fragment>
  );
};
export default MorePicker;
