class Categories {
    constructor(data) {
        this.data = data;
    }

    byId(id) {
        const _category = this.data.filter(item => item.id == id);
        return _category.length > 0 ? _category[0] : null;
    }

    static byId(id, data) {
        const _category = data.filter(item => item.id == id);
        return _category.length > 0 ? _category[0] : null;
    }
}

Categories.types = {
    ACCOMMODATION: "acc",
    FOOD: "food"
};

export default Categories;
