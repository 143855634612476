import api_config from "../API.config";

const config = {
	methodName: "UploadUserPhoto",
	isTest: false,
	isDummy: false,
	method: "POST",
	sendFiles: true,
	dummyData: {},
	prepareRequest: (r) => r,
	prepareResponse: (r) => {
		let photo = r.photo;
		if (photo.indexOf("http") == -1) {
			photo = api_config.web_root + photo;
		}
		return {
			...r,
			photo,
		};
	},
	handleError: (e) => e,
};

export default config;
