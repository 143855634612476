import GetReviews from "./_GetReviews";
import GetSubCategory from "./_GetSubCategory";
import GetDiscounts from "./_GetDiscounts";
import GetCountriesList from "./_GetCountriesList";
import GetBanners from "./_GetBanners";
import GetFilterDataByPartnerCode from "./_GetFilterDataByPartnerCode";
import GetPartnersRating from "./_GetPartnersRating";

export default {
	GetReviews,
	GetSubCategory,
	GetDiscounts,
	GetCountriesList,
	GetBanners,
	GetFilterDataByPartnerCode,
	GetPartnersRating,
};
