import util from "../../../util/util";

const config = {
    methodName: "QuickOrder",
    isTest: false,
    isDummy: false,
    method: "POST",
    dummyData: {},
    prepareRequest: p => p,
    prepareResponse: r => {
        const url = r.payURL;
        if (url && url.length > 0) {
            return { url };
        }
        return r;
    },
    handleError: e => e
};

export default config;
