import React, { useState, Fragment } from "react";
import UserOrderItem from "./_UserOrderItem";
import util from "../../util/util";
import css from "../../Cart/Cart.module.scss";
import purchasesCss from "../styles/Orders.module.scss";
// import api from "../../API/new/API";
import Orders from "../../../model/Order";
import api from "../../API/API.dev";

const UserOrder = (props) => {
  const [expanded, toggleExpanded] = useState(false);
  const [orderProducts, setorderProducts] = useState([]);
  const [renderProducts, setRenderProducts] = useState("");
  // let renderProducts = [];

  const formatingDate = (e) => {
    const newOrderDate = new Orders().formatingDate(e);
    return util.moment(newOrderDate).format("DD-MM-YYYY");
  };
  const checkDate = () => {
    let beforeOneDay = new Date();
    beforeOneDay.setDate(beforeOneDay.getDate() - 1);
    const givenDate = new Orders().formatingDate(props.item.orderDate);
    const check = givenDate > beforeOneDay;
    return check;
  };
  const sendToPay = () => {
    const params = {
      orderId: props.item.orderId,
    };
    api("user/ProfilePayOrder", params).then((response) => {
      if (response.data) {
        const url = response.data.payURL;
        util.log(url);
        window.location = url;
      } else {
        alert("Регистрация заказа не удалась");
      }
    });
  };
  const checkDateForPay = () => {
    let check = checkDate();
    if (check) {
      return (
        <Fragment>
          {props.emergency || props.item.orderPayDate ? (
            formatingDate(props.item.orderPayDate)
          ) : (
            <button
              className={util.join(
                purchasesCss["purchases-btn"],
                "ut-theme-color"
              )}
              onClick={sendToPay}
              disabled={props.emergency}
            >
              <span>Оплатить</span>
            </button>
          )}
        </Fragment>
      );
    } else {
      if (props.item.orderPayDate) {
        return formatingDate(props.item.orderPayDate);
      } else {
        return null;
      }
    }
  };

  const writeStatus = () => {
    let check = checkDate();
    if (!check && !props.item.orderPayDate) {
      return "Время оплаты вышло";
    } else {
      return props.item.status;
    }
  };

  const fillOrders = () => {
    const params = {
      orderId: props.item.orderId,
    };
    api("user/OrderProducts", params)
      .then((response) => {
        if (response.data && !response.data.error) {
          setorderProducts(response.data);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  // let renderOrderDetail = "";

  const detailCall = () => {
    setorderProducts([]);
    toggleExpanded(!expanded);
    fillOrders();
  };
  let renderOrderDetail = orderProducts.map((item, index) => {
    return <UserOrderItem key={index} item={item} detailCall={detailCall} />;
  });
  const itemSumm = () => {
    return (
      <Fragment>
        <div className={purchasesCss["purchases-row"]}>
          <div className={purchasesCss["purchases-data"]}>
            <div className={purchasesCss["data"]}>{props.item.orderId}</div>
          </div>
          <div
            className={util.join(
              purchasesCss["purchases-data"],
              purchasesCss["purchases-data--hide-field"]
            )}
          >
            {formatingDate(props.item.orderDate)}
          </div>
          <div
            className={purchasesCss["purchases-data"]}
            onClick={checkDateForPay}
          >
            {checkDateForPay()}
          </div>
          <div
            className={util.join(
              purchasesCss["purchases-data"],
              purchasesCss["purchases-data--hide-field"]
            )}
          >
            {writeStatus()}
          </div>
          <div className={purchasesCss["purchases-data"]}>
            {util.currencyFormat(props.item.orderPrice)}
          </div>
          <div className={purchasesCss["purchases-data"]}>
            <span className={purchasesCss["purchases-btn--open"]}>
              <button
                className={util.join(
                  purchasesCss["purchases-btn"],
                  "ut-theme-color"
                )}
                onClick={detailCall}
              >
                <span>{expanded ? "Скрыть" : "Подробнее"}</span>
              </button>
            </span>
          </div>
        </div>

        {expanded
          ? orderProducts.length > 0
            ? renderOrderDetail
            : null
          : null}
      </Fragment>
    );
  };

  return itemSumm();
};
export default UserOrder;
