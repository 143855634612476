import React from "react";
import css from "./Interface.module.scss";
import util from "../util/util";
import Puff from "react-preloader-icon/loaders/Puff";
import PreloaderIcon from "react-preloader-icon";
import { element } from "prop-types";

export const Container = props => {
  return (
    <div className={util.join(css["container"], props.className)}>
      {props.children}
    </div>
  );
};

export const Form = props => {
  return <form className={props.className}>{props.children}</form>;
};

export const Input = props => {
  return (
    <div className={util.join("ut-form__group", props.className)}>
      {props.label ? (
        <label className="ut-form__label">{props.label}</label>
      ) : (
        ""
      )}
      <input
        type={props.type ? props.type : "text"}
        /* placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            onChange={props.onChange} */
        {...props}
        className={"ut-form__input"}
      />
    </div>
  );
};

export const Select = props => {
  const values = props.options.map((option, index) => (
    <option value={option.value} key={index}>
      {option.text}
    </option>
  ));
  return (
    <div className="ut-form__group">
      {props.label ? (
        <label className="ut-form__label">{props.label}</label>
      ) : (
        ""
      )}
      <select {...props} className={"ut-form__input"}>
        {values}
      </select>
    </div>
  );
};

export const RadioGroup = props => {
  const title = <label className="ut-form__label">{props.title}</label>;

  const values = props.elements.map((el, index) => {
    const selected = el.value == props.currentValue;
    const callbackData = {
      ...el,
      name: props.name
    };
    return (
      <li key={index}>
        <label
          className={util.join(
            css["radio-label"],
            "ut-theme-color",
            selected && css["radio-label--selected"]
          )}
          onClick={() => props.clickHandler(callbackData)}
        >
          <input
            type="radio"
            className={css["radio-input"]}
            name={props.name}
            value={el.value}
            readOnly={true}
            checked={selected}
          />

          <span>{el.title}</span>
        </label>
      </li>
    );
  });

  return (
    <div className="ut-form__group">
      {title}
      <ul>{values}</ul>
    </div>
  );
};

export const Button = props => {
  const r = props.loading ? (
    <div className="ut-form__preloader">
      <PreloaderIcon
        loader={Puff}
        size={32}
        strokeWidth={8}
        strokeColor="#666"
        duration={800}
      />
    </div>
  ) : (
    <button
      data-tip=""
      data-for={props.dataFor}
      type={props.type}
      className={util.join(
        "ut-theme-color",
        css["formButton"],
        props.className
      )}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <span>{props.children}</span>
    </button>
  );

  return r;
};

export const Panel = props => {
  return (
    <div
      className={util.join(css["panel"], css["centerPanel"], props.className)}
    >
      {props.children}
    </div>
  );
};

export const PanelWide = props => {
  return (
    <div
      className={util.join(
        css["panel"],

        props.className
      )}
    >
      {props.children}
    </div>
  );
};
export const EmergencyBanner = props => {
  return (
    <div
      className={util.join(
        `${props.toggle ? css["emergency"] : css["emergency--hide"]}`,
        "ut-theme-color",
        props.className
      )}
    >
      <p
        className={util.join(
          `${
            props.toggle ? css["emergency__text"] : css["emergency__text--none"]
          }`,

          props.classNameText
        )}
      >
        {props.text}
      </p>
      <span
        className={util.join(
          css["emergency__close-button"],
          props.classNameClose
        )}
        onClick={() => props.hideHandler()}
      >
        ✕
      </span>
    </div>
  );
};
