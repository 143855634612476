import React, { useState, useEffect, useRef } from "react";
import instaIcon from "../../assets/scss/ut-icons/instaIcon.svg";
import fbIcon from "../../assets/scss/ut-icons/fbIcon.svg";
import TikTokIcon from "../../assets/scss/ut-icons/TikTokIcon.svg";
import ArrowDownIcon from "../../assets/scss/ut-icons/arrow_down.svg";
import css from "./Hero.module.scss";
import Filter from "./_MainPanelFilter";
import RightPanel from "./_RightPanel";
import PropTypes from "prop-types";
import LeftPanel from "./_LeftPanel";

const getSloganSizeAdaptive = (currentSizeStr) => {
  const curWindowSize = window.innerWidth;
  const curSizeNum = parseInt(currentSizeStr);
  let fontSize;

  if (1500 < curWindowSize) {
    fontSize = curSizeNum;
  } else if (1300 < curWindowSize && curWindowSize < 1499) {
    fontSize = curSizeNum / 1.7;
  } else if (980 < curWindowSize && curWindowSize < 1299) {
    fontSize = curSizeNum / 1.8;
  } else if (650 < curWindowSize && curWindowSize < 979) {
    fontSize = curSizeNum / 2.5;
  } else if (500 < curWindowSize && curWindowSize < 649) {
    fontSize = curSizeNum / 2.7;
  } else if (300 < curWindowSize && curWindowSize < 499) {
    fontSize = curSizeNum / 3.5;
  }

  return fontSize;
};

const MainPanel = (props) => {
  const buttonDowEl = useRef();
  const heroMainEl = useRef();
  // const heroMainElCoordsY = heroMainEl.current.getBoundingClientRect().y;

  const scrollElementHandler = () => {
    if (heroMainEl.current === null) {
      window.removeEventListener("scroll", scrollElementHandler);
      return;
    }
    return heroMainEl.current.getBoundingClientRect().y !== 0
      ? (buttonDowEl.current.hidden = true)
      : (buttonDowEl.current.hidden = false);
  };

  if (heroMainEl.current) {
    window.addEventListener("scroll", scrollElementHandler);
  }

  const sloganSize = getSloganSizeAdaptive(document.body.dataset.slogansize);

  return (
    <div
      ref={heroMainEl}
      className={css["main"]}
      style={{
        backgroundImage: `url(${props.backgroundImage})`,
      }}
    >
      <div className={css["main-moto"]}>
        <div
          className={css["main-moto-bg"]}
          style={{ fontSize: `${sloganSize}px` }}
        >
          {document.body.dataset.slogan}
        </div>
        {/* <div className={css["main-moto-sm"]}>
          конструктор уникального путешествия в Сочи
        </div> */}
      </div>
      <div className={css["main-search-filter"]}>
        <LeftPanel
          onCategoryPick={props.categoryPickHandler}
          defaultCategory={props.selectedCategory}
        />
        <Filter />
      </div>
      {/* <div className={css['social-networks-el']}>
        <a href="https://www.instagram.com/un.trp/">
          <img src={instaIcon} alt="" />
        </a>
        <a href="https://www.facebook.com/UniqTripRu">
          <img src={fbIcon} alt="" />
        </a>
        <a href="https://www.tiktok.com/@uniqtrip?lang=ru">
          <img src={TikTokIcon} alt="" />
        </a>
      </div> */}
      <RightPanel offers={props.offers} cartHandler={props.cartHandler} />
      <a
        href="#category-filter"
        className={css["button-down-el"]}
        ref={buttonDowEl}
      >
        <div>
          <img src={ArrowDownIcon} alt="" />
        </div>
      </a>
      <div
        id="category-filter"
        style={{
          position: "absolute",
          bottom: "1px",
        }}
      ></div>
    </div>
  );
};

MainPanel.propTypes = {
  backgroundImage: PropTypes.string,
};

MainPanel.defaultProps = {
  backgroundImage: "",
};

export default MainPanel;

