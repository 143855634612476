import util from "../../components/util/util";

const config = {
	methodName: "SearchPartners",
	isTest: false,
	isDummy: false,
	method: "post",
	dummyData: [],
	/* prepareResponse: r => {
        const response = {
            ...r,
            totalItems: 9
        };
        return response;
    } */
};

export default config;
