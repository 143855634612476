import React, { Fragment } from "react";
import css from "./styles/TimeChart.module.scss";
import withOverlay from "../../hoc/Overlay/Overlay";
import ButtonItemCategory from "./ButtonItemCategory";
import util from "../util/util";

const CategoryChart = props => {
  let testHandler = e => {
    props.subCategories.forEach(element => {
      if (element.name === e) {
        return props.pickHandler(element);
      }
    });
  };

  let all = "Все";

  let subCategory = props.subCategories.map((item, index) => (
    <ButtonItemCategory
      key={index}
      number={item.name}
      category={true}
      pickHandler={val => testHandler(val)}
      selected={item.name == props.defaultValue}
    />
  ));
  subCategory.unshift(
    <ButtonItemCategory
      key={"id"}
      number={all}
      category={true}
      pickHandler={all => props.pickHandler(all)}
      selected={all == props.defaultValue}
    />
  );
  let displayData = (
    <div className={css["container"]}>
      <div className={css["wrap-time-category"]}>
        <div className={css["time-column"]}>
          <div
            className={util.join(
              "ut-theme-color",
              css["time-header-more-filter"]
            )}
          >
            Категории
          </div>
          <div className={css["container-hours"]}>
            <div className={css["wrapper-category"]}>{subCategory}</div>
          </div>
        </div>
      </div>
    </div>
  );

  return <Fragment>{displayData}</Fragment>;
};

export default withOverlay(CategoryChart);
