import React, { Fragment, useState, useEffect } from "react";
import CartItemField from "./_CartItemField";
import util from "../util/util";
import css from "./Cart.module.scss";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Button } from "../Interface/Interface";

import PropTypes from "prop-types";

const CartItemProduct = (props) => {
  const assocParams = util.arrayToAssoc(props.params, "code");
  const assocParamsRepeate = util.arrayToAssoc(props.params, "code");

  const [fields, updateFields] = useState(assocParams);
  const [controlRepeat, setControlRepeat] = useState(assocParamsRepeate);
  const [isChange, toggleIsChange] = useState(false);
  const [flagForProfileData, setFlafForProfileData] = useState(false);
  const [dirty, setdirty] = useState(true);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    checkEmpty();
  }, []);

  useEffect(() => {
    if (flagForProfileData) {
      fillDataFromProfile();
    }
  }, [flagForProfileData]);

  const fieldChangeHandler = (e) => {
    if (!fields[e.target.name]["edit"]) return fields;
    const updatedFields = { ...fields };
    updatedFields[e.target.name]["value"] = e.target.value;
    checkIdentical();
    return updateFields(updatedFields);
  };
  const checkIdentical = () => {
    setdirty(true);
    for (let propertyName in fields) {
      if (
        fields[propertyName]["value"] !== controlRepeat[propertyName]["value"]
      ) {
        setdirty(false);
        break;
      }
    }
  };

  const checkEmpty = () => {
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const edit = fields[key]["edit"];
        const value = fields[key]["value"];
        if (edit && value && value.length > 0 && value != 0) {
          toggleIsChange(true);
        }
      }
    }
  };

  const fillDataFromProfile = () => {
    if (!Object.keys(props.profile)) return;
    const updatedFields = { ...fields };

    for (const key of Object.keys(props.profile)) {
      if (key === "firstName") {
        updatedFields["Fname"]["value"] = { ...props.profile }["firstName"];
      } else if (key === "lastName") {
        updatedFields["Lname"]["value"] = { ...props.profile }["lastName"];
      } else if (key === "email") {
        updatedFields["email"]["value"] = { ...props.profile }["email"];
      } else if (key === "phone") {
        updatedFields["phone"]["value"] = { ...props.profile }["phone"];
      }
    }

    updateFields(updatedFields);
  };

  const changeButton = () => {
    toggleIsChange(false);
    setFocus(true);
  };

  const getAdditionalFields = (fields) => {
    const resultFields = [];
    const sortOrder = {
      dateIn: {},
      adults: {},
      children: {},
      time: {},
    };

    const sortedFields = util.mergeObjects(sortOrder, fields);

    for (let code in sortedFields) {
      const f = sortedFields[code];
      if (f.value && f.value.length > 0 && f.value != 0) {
        resultFields.push(<CartItemField {...f} key={code} />);
      }
    }
    return resultFields;
  };
  const additionalFields = getAdditionalFields(fields);
  const checkIfAuthorised = () => !Object.keys(props.profile).length;

  const button = isChange ? (
    <Button
      onClick={changeButton}
      className={`${
        !isChange
          ? !isChange && dirty
            ? css["product-button"]
            : util.join("ut-theme-color", css["product-button--dirty"])
          : dirty
          ? css["product-button"]
          : util.join("ut-theme-color", css["product-button--dirty"])
      }`}
      children={"Изменить"}
      loading={props.loading}
      // disabled={!isChange && dirty}
    ></Button>
  ) : (
    <Button
      onClick={() => props.updateHandler(fields)}
      className={`${
        !isChange
          ? !isChange && dirty
            ? css["product-button"]
            : util.join("ut-theme-color", css["product-button--dirty"])
          : dirty
          ? css["product-button"]
          : util.join("ut-theme-color", css["product-button--dirty"])
      }`}
      children={"Сохранить"}
      loading={props.loading}
      // disabled={!isChange && dirty}
    ></Button>
  );

  const expandedAll = (
    <div className={css["product"]}>
      <div className={css["product-title-container"]}>
        <h5 className={css["product-title"]}>{props.name}</h5>
        <div
          title="Убрать товар из корзины"
          className={css["product-remove"]}
          onClick={() => props.removeHandler(props.id)}
        >
          <DeleteForeverIcon />
        </div>
      </div>
      <p className={css["product-text"]}>{util.ellipse(props.text, 160)}</p>
      {additionalFields}
      <label className={css["product-toggler"]}>
        <input
          type="checkbox"
          // checked={additionalIsVisible}
          // onChange={(e) => toggleAdditional(e.target.checked)}
          onClick={() => {
            setFlafForProfileData(!flagForProfileData);
          }}
          disabled={checkIfAuthorised() || isChange}
        />{" "}
        Загрузить данные из личного кабинета
      </label>
      <ul>
        <div className={css["product-additional"]}>
          <CartItemField
            {...fields["Fname"]}
            changeHandler={fieldChangeHandler}
            isChange={!isChange}
            focus={focus}
          />
          <CartItemField
            {...fields["Lname"]}
            changeHandler={fieldChangeHandler}
            isChange={!isChange}
          />
          <CartItemField
            {...fields["email"]}
            changeHandler={fieldChangeHandler}
            isChange={!isChange}
          />
          <CartItemField
            {...fields["phone"]}
            changeHandler={fieldChangeHandler}
            isChange={!isChange}
          />
          <CartItemField
            {...fields["comment"]}
            changeHandler={fieldChangeHandler}
            isChange={!isChange}
          />
        </div>
      </ul>
      <div className="ut-theme-color">{button}</div>
    </div>
  );

  const expandedComment = (
    <div className={css["product"]}>
      <div className={css["product-title-container"]}>
        <h5 className={css["product-title"]}>{props.name}</h5>
        <div
          title="Убрать товар из корзины"
          className={css["product-remove"]}
          onClick={() => props.removeHandler(props.id)}
        >
          <DeleteForeverIcon />
        </div>
      </div>
      <p className={css["product-text"]}>{util.ellipse(props.text, 160)}</p>
      <ul>
        <CartItemField
          {...fields["comment"]}
          changeHandler={fieldChangeHandler}
          isChange={!isChange}
        />
      </ul>
      <div className="ut-theme-color">{button}</div>
    </div>
  );

  return (
    <>
      {props.expandedAll
        ? expandedAll
        : props.expandedComment && !props.expandedAll
        ? expandedComment
        : null}
    </>
  );
};

CartItemProduct.defaultProps = {
  text: "",
  params: [],
  id: 0,
  name: "",
  removeHandler: () => {
    alert("Not removed");
  },
  updateHandler: () => {},
};

CartItemProduct.propTypes = {
  text: PropTypes.string.isRequired,
  params: PropTypes.array.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  removeHandler: PropTypes.func.isRequired,
};

export default CartItemProduct;
