import React, { useEffect, useState, useCallback } from "react";
import "lightpick/css/lightpick.css";
import Litepicker from "../../lib/DatePickerModal/DatePickerModal";

import TimeChart from "../TimePicker/TimeChart";
import style from "../TimePicker/styles/TimePicker.module.scss";
import css from "../SearchResults/SearchResults.module.scss";
import util from "../util/util";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

import TimePicker from "../TimePicker/TimePicker";
// import Lightpick from "lightpick";
import MorePicker from "../TimePicker/MorePicker";

let picker;

const SearchResultsFilter = (props) => {
	const ref1 = React.createRef();
	const ref2 = React.createRef();
	const fieldDateIn = React.createRef();
	const fieldDateOut = React.createRef();
	const datePick = (dateIn, dateOut) => {
		const _dateOut = dateOut ? dateOut : util.moment(dateIn).add(1, "day");

		props.setFilter({
			dateIn,
			dateOut: _dateOut,
		});
	};

	const filterConfig = useCallback((param) => {
		let config = {};

		// If no category selected, or accommodation is selected -
		// double date picker,
		// else - single date

		config.singleMode = !(props.category.category_id == 7);
		config.datePickerClassName = config.singleMode
			? util.join(props.css["input-text-inline"], props.css["input-text"])
			: props.css["input-text"];
		//config.datePickerPlaceholder = getDateText(config.singleMode);
		config["field1_label"] = config.singleMode ? "Когда" : "Заезд";
		config["field2_label"] = config.singleMode ? "Во сколько" : "Выезд";
		if (param && config.hasOwnProperty(param)) {
			return config[param];
		}
		return config;
	});
	useEffect(() => {
		const config = filterConfig();
		const input_dateIn = fieldDateIn.current;
		const input_dateOut = fieldDateOut.current;
		if (picker) picker.destroy();

		picker = new Litepicker({
			element: input_dateIn,
			elementEnd: config.singleMode ? null : input_dateOut,
			singleMode: config.singleMode,
			numberOfMonths: 1,
			format: "DD.MM.YYYY",
			numberOfColumns: 1,
			minDate: props.config
				? props.config.minDate
				: util.moment().add(2, "day"),
			maxDate: props.config
				? props.config.maxDate
				: util.moment().add(365, "day"),
			lang: "ru-RU",
			tooltipText: {
				one: "день",
				few: "дня",
				many: "дней",
			},
			onSelect: function (start, end) {
				const _end = end || util.moment(start).add(1, "day");

				props.setFilter({
					dateIn: util.moment(start),
					dateOut: util.moment(_end),
				});
			},
		});
	}, [fieldDateIn, fieldDateOut, filterConfig, props, props.category]);

	let dateIn = props.filter.dateIn;

	if (
		props.config.minDate &&
		props.config.minDate.isAfter(props.filter.dateIn)
	) {
		dateIn = props.config.minDate;
	}

	let dateOut = props.filter.dateOut || props.filter.dateIn;

	if (props.config.maxDate && props.config.maxDate.isBefore(dateOut)) {
		dateOut = props.config.maxDate;
	}

	if (util.moment(dateIn).isAfter(dateOut)) {
		dateOut = util.moment(dateIn).add(1, "day");
	}

	dateIn = dateIn.format("DD.MM.YYYY");
	dateOut = dateOut.format("DD.MM.YYYY");

	const dateIn_input = (
		<input
			readOnly
			value={dateIn}
			name="hotel_date_out"
			className={css["filter-input"]}
			ref={fieldDateIn}
		/>
	);
	const dateOut_input = (
		<input
			readOnly
			value={filterConfig("singleMode") ? props.filter.time : dateOut}
			name="hotel_date_out"
			className={css["filter-input"]}
			ref={fieldDateOut}
		/>
	);

	const subCategoriesHTML = props.subCategories ? (
		<div className={css["filter-group"]}>
			<i className={"ut-ico-home " + css["filter-icon"]}></i>
			<div>
				<label className={props.css["filter-label"]}>Жилье</label>
				<select name="hotel_type" className={css["filter-select"]}>
					{props.subCategories.map((item, index) => (
						<option key={index} value="item.name">
							{item.name}
						</option>
					))}
				</select>
			</div>
		</div>
	) : (
		""
	);
	//logic for  more picker

	let [pickerVisible, setPickerVisible] = useState(props.toggleVisible);
	let sum = () => {
		if (props.filter.adults < 2) {
			return " взрослый";
		} else {
			return " взрослых";
		}
	};
	let sumChild = () => {
		if (props.filter.children < 1) {
			return "без детей";
		} else if (props.filter.children == 1) {
			return props.filter.children + " ребенок";
		} else {
			return props.filter.children + " детей";
		}
	};

	let chart = (
		<TimeChart
			more={true}
			adults={props.filter.adults}
			pickHandler={(ad, ch) => {
				setPickerVisible(false);
				props.setFilter({
					adults: parseInt(ad),
				});
				props.setFilter({
					children: parseInt(ch),
				});
			}}
			hideHandler={() => setPickerVisible(!pickerVisible)}
			children={props.filter.children}
			maxAdults={props.config ? props.config.maxAdults : null}
			maxChildren={props.config ? props.config.maxChildren : null}
		/>
	);
	let main = false;
	let product = true;
	let classes = [style["moreWrapper"]];

	if (product) {
		classes.push(style.productAdults);
	}

	//timePicker
	const [visible, setVisible] = useState(false);

	let time = props.filter.time.split(":");
	const hour = time[0];
	const minutes = time[1];
	let defaultValueTime = props.filter.time;

	let chartTime = (
		<TimeChart
			hour={hour}
			minutes={minutes}
			steps={15}
			pickHandler={(h, m) => {
				setVisible(false);
				props.setFilter({ time: h + ":" + m });
			}}
			hideHandler={() => setVisible(!visible)}
		/>
	);

	return (
		<div className={`${css["filter"]} ut-theme-color`}>
			{props.title ? (
				<a className="ut-section__title" href="#">
					{props.title}
				</a>
			) : (
				""
			)}

			{subCategoriesHTML}
			<div ref={fieldDateIn} className={css["filter-group"]}>
				<i
					className={util.join(
						"ut-ico-calendar1",
						css["filter-icon"],
						css["hideForClick"]
					)}
				></i>
				<div className={css["hideForClick"]}>
					<label className={css["filter-label"]}>
						{filterConfig("field1_label")}
					</label>
					{dateIn_input}
				</div>
			</div>
			{filterConfig("singleMode") ? (
				<div
					onClick={() => setVisible(!visible)}
					className={css["filter-group"]}
				>
					<i className={"ut-ico-clock " + css["filter-icon"]}></i>
					<div>
						<label className={css["filter-label"]}>
							{filterConfig("field2_label")}
						</label>
						<input
							type="text"
							value={defaultValueTime}
							readOnly
							className={style["searchInput"]}
						/>
					</div>
				</div>
			) : (
				<div ref={fieldDateOut} className={css["filter-group"]}>
					<i
						className={util.join(
							"ut-ico-calendar2 ",
							css["filter-icon"],
							css["hideForClick"]
						)}
					></i>
					<div className={css["hideForClick"]}>
						<label className={css["filter-label"]}>
							{filterConfig("field2_label")}
						</label>
						{dateOut_input}
					</div>
				</div>
			)}
			{visible ? chartTime : null}
			<div
				onClick={() => setPickerVisible(!pickerVisible)}
				className={css["filter-group"]}
			>
				<i className={"ut-ico-guest " + css["filter-icon"]}></i>
				<div className={css["wrappMore"]}>
					<label
						className={`${css["filter-label"]} ${css["label-more"]} `}
					>
						Гостей
					</label>

					<div className={classes.join(" ")}>
						<p
							className={
								main
									? style["picker-input"]
									: style["input-subfilter"]
							}
						>
							{props.filter.adults + sum()}
						</p>
						<p
							onClick={() => setPickerVisible(!pickerVisible)}
							className={
								main
									? style["picker-input"]
									: style["input-subfilter"]
							}
						>
							{sumChild()}
						</p>
					</div>
				</div>
			</div>
			{pickerVisible ? chart : null}
			<button
				onClick={props.getRefreshProducts}
				className={util.join("ut-theme-color", css["filter-btn"])}
			>
				<span>Изменить параметры поиска</span>
			</button>
		</div>
	);
};
const mapStateToProps = (state) => ({
	catalog: state.catalog,
	filter: state.user.customerData.filter,
});

const mapDispatchToProps = (dispatch) => ({
	setFilter: (filter) => dispatch(actions.setFilter(filter)),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchResultsFilter);
