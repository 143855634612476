import React, { Component } from 'react';
import css from './Loading.module.scss';

const withLoading = (WrappedComponent) => {
  return class extends Component {
    state = { isLoading: false };

    toggleLoadingState = (isLoading = false) => {
      this.setState({
        isLoading: Boolean(isLoading),
      });
    };

    render = () => {
      const loading = this.state.isLoading ? (
        <div className={css['loading']}>
          <div className={css['logo']}></div>
        </div>
      ) : (
        ''
      );

      return (
        <div>
          {loading}
          <WrappedComponent {...this.props} loading={this.toggleLoadingState} />
        </div>
      );
    };
  };
};

export default withLoading;
