import React from "react";
import { Panel } from "../Interface/Interface";
import css from "./page404.module.scss";
import { Link } from "react-router-dom";

const Page404 = props => {
    return (
        <Panel className={css.panel}>
            <h1 className={css.title}>
                <span>404 :(</span>
                Раздел не найден
            </h1>
            <p className={css.text}>
                Вернитесь на{" "}
                <Link className="ut-theme-color" to="/">
                    главную
                </Link>{" "}
                и попробуйте снова
            </p>
        </Panel>
    );
};

export default Page404;
