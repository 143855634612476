import React, { Component } from "react";
import css from "./LikeSwitch.module.scss";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import util from "../util/util";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
class LikeSwitch extends Component {
	getState = () => {
		return this.props.favourites.some(
			(e) => e.id == this.props.itemData.id
		);
	};

	toggleState = () => {
		return this.getState()
			? this.props.removeFavourite(
					this.props.itemData,
					this.props.isLogged
			  )
			: this.props.addFavourite(this.props.itemData, this.props.isLogged);
	};

	render = () => {
		const enabled = this.getState();

		return (
			<a className={`${css["like"]}`} onClick={this.toggleState}>
				<label>
					{enabled ? (
						<FavoriteIcon size={16} className={css["like--on"]} />
					) : (
						<FavoriteBorderIcon size={16} />
					)}
				</label>
			</a>
		);
	};
}

const mapStateToProps = (state) => ({
	favourites: state.user.favourites,
	isLogged: state.user.isLogged,
});

const mapDispatchToProps = (dispatch) => ({
	addFavourite: (itemData, isLogged) =>
		dispatch(actions.addFavourite(itemData, isLogged)),
	removeFavourite: (itemData, isLogged) =>
		dispatch(actions.removeFavourite(itemData, isLogged)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LikeSwitch);
