/**
 *
 * Utility functions
 *
 */
import { objectEquals } from "object-equals";
import imgSmall from "../../assets/image/dummy_small.jpg";
import imgMid from "../../assets/image/dummy_mid.jpg";
import imgBig from "../../assets/image/dummy_big.jpg";
import imgBanner from "../../assets/image/banner_dummy_image.jpg";
import imgOffer from "../../assets/image/offer_dummy.jpg";
import imgPartner1 from "../../assets/image/partner_dummy.jpg";
import imgPartner2 from "../../assets/image/partner_dummy2.jpg";
import imgSliderCard from "../../assets/image/slider_card_dummy.jpg";

import apiConfig from "../API/API.config";

const util = {};

/**
 * Development mode console.log
 */

util.log = (...args) => {
  return process.env.NODE_ENV == "production" ? () => {} : console.log(...args);
};

/**
 * Object Iterator wrapper
 */

util.objectIterator = require("iterate-object");

/**
 * Moment js
 */

util.moment = require("moment");

/**
 * HTML Entities decoder
 */
const Entities = require("html-entities").AllHtmlEntities;

util.entitiyDecoder = (input) => new Entities().decode(input);

/**
 * Text ellipsize
 */

util.ellipse = require("ellipsize");

/**
 * Number format
 */

util.currencyFormat = (value) => {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currencyDisplay: "code",
    currency: "Rub",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);
};

/**
 * Strip HTML tags
 *
 */
util.striptags = require("striptags");

/**
 * Prepare pure text ellipsis for html block
 */

util.getText = (input, length) =>
  util.ellipse(util.striptags(util.entitiyDecoder(input)), length);
/**
 * insert HTML
 */
const parser = require("html-react-parser");
util.getHTML = (input) => parser(util.entitiyDecoder(input));

/**
 * join classes Names
 */
util.join = (...args) => args.join(" ");

/** update object*/
util.mergeObjects = (source, target) => {
  const result = { ...source };
  for (let key in source) {
    if (source.hasOwnProperty(key) && target.hasOwnProperty(key)) {
      /* let resValue = target[key] ? target[key] : source[key];
            if (Array.isArray(resValue)) {
                resValue = [...resValue];
            } else if (typeof resValue === 'object') {
                resValue = {...resValue};
            } */
      result[key] = target[key] ? target[key] : source[key];
    }
  }
  return result;
};
/**
 * get property safely
 */
util.getPropertySafe = (object, path, replacement = false) => {
  path = path.split(".");
  let current = object;
  for (let i = 0; i < path.length; i++) {
    if (current && current[path[i]]) current = current[path[i]];
    else current = false;
  }
  return current ? current : replacement;
};

/**
 * Get JSON Safely
 */
util.getJSONSafe = (text, replacement = false) => {
  let result = replacement;
  try {
    result = JSON.parse(text);
    return result ? result : replacement;
  } catch (e) {
    return replacement;
  }
};

/**
 * Prepend hostname to url
 * */

util.prependHostname = (url, replacement) => {
  if (url.length < 1) return replacement;
  if (url.indexOf("http" !== 0)) {
    url = apiConfig.web_root + url;
  }
  return url;
};

/**
 * Objects are equal
 */

util.objectsEqual = (obj1, obj2) => objectEquals(obj1, obj2, { depth: 10 });

/**
 * get dummy image object
 */

util.dummyImage = () => ({
  big: imgBig,
  mid: imgMid,
  small: imgSmall,
  banner: imgBanner,
  offer: imgOffer,
  partner1: imgPartner1,
  partner2: imgPartner2,
  slider: imgSliderCard,
});

/**
 *
 * Get Position
 *
 */
util.getPosition = (text) => {
  if (!text) return null;
  const _position = text.split(",");
  if (_position.length < 2) return null;
  const lat = parseFloat(_position[0]);
  const lng = parseFloat(_position[1]);
  if (isNaN(lat) || isNaN(lng)) {
    return null;
  }
  return { lat, lng };
};

/**
 * Object is empty
 */
util.emptyObject = (obj = {}) => {
  return Object.is(obj, {});
};

/**
 *
 * converts array into associative array (object)
 *
 */
util.arrayToAssoc = (arr, key, value = false) => {
  const result = {};
  arr.forEach((element) => {
    if (element.hasOwnProperty(key)) {
      result[element[key]] = value ? element[value] : { ...element };
    }
  });
  return result;
};

/**
 *
 * Random integer
 *
 */
util.randomInt = require("random-int");

/**
 *
 * Add dummy image
 *
 * @param {Event} e
 */
util.addDummyImage = (e, type) => {
  let dummyImg;

  switch (type) {
    case "banner":
      dummyImg = util.dummyImage().banner;
      break;
    case "offer":
      dummyImg = util.dummyImage().offer;
      break;
    case "partner1":
      dummyImg = util.dummyImage().partner1;
      break;
    case "partner2":
      dummyImg = util.dummyImage().partner2;
      break;
    case "slider":
      dummyImg = util.dummyImage().slider;
      break;
    default:
      dummyImg = util.dummyImage().small;
  }

  e.target.src = dummyImg;

  return e.target.src;
};

/**
 *
 * Random array element
 *
 * @param {array} a
 *
 */
util.randomArrayElement = (a) => {
  if (!Array.isArray(a) || a.length == 0) {
    return false;
  }
  const randomInt = util.randomInt(0, a.length - 1);
  return a[randomInt];
};

/**
 *
 * Return message for expansion in cart item
 *
 * @param {string} category
 * @param {boolean} expanded
 */

util.getExpandText = (category, expanded) => {
  const DEFAULT_STRING = "Данные покупателя";

  switch (category) {
    case "acc":
      return expanded ? "Данные бронирования" : DEFAULT_STRING;
    case "other":
      return expanded ? "Данные покупателя" : DEFAULT_STRING;
    default:
      return "Комментарии";
  }
};

//// END
export default util;
