import React, { Component, Fragment } from "react";
import apiDev from "../components/API/API.dev";
import ProductData from "../components/ProductData/ProductData";
import Loading from "../hoc/Loading/Loading";
import util from "../components/util/util";
import api from "../api/API";
import { connect } from "react-redux";
import * as actions from "../store/actions/index";
import { object } from "prop-types";

class LayoutProduct extends Component {
  state = {
    data: false,
    photos: [],
    products: false,

    filterConfig: false,

    nights: 1,
  };

  componentDidMount = () => {
    this.props.loading(true);
    window.scrollTo(0, 0);
    const { category, code } = this.props.match.params;
    const params = {
      partnerCode: code,
      categoryCode: category,
    };

    apiDev("catalog/GetPartnerDataByID", params).then((response) => {
      this.setState(
        (state) => ({
          ...state,
          data: { ...response.data[0] },
        }),
        this.props.loading
      );
    });
    apiDev("catalog/GetPhotos", params).then((response) => {
      let photos = [];
      if (typeof response.data !== "object") {
        photos = [
          { small: util.dummyImage().small, mid: util.dummyImage().mid },
        ];
      } else if (
        response.data.params.photos &&
        Array.isArray(response.data.params.photos)
      ) {
        photos = response.data.params.photos;
      }
      this.setState((state) => ({
        ...state,
        photos: photos,
      }));
    });

    if (category == "acc") {
      api.catalog.GetFilterDataByPartnerCode({ code }).then((response) => {
        if (response.data && !response.data.error) {
          this.setState((state) => ({
            ...state,
            filterConfig: { ...response.data },
          }));
          this._getRefreshProducts(response.data);
        }
      });
    } else {
      this._getRefreshProducts();
    }
  };

  _getRefreshProducts = (filterData) => {
    this.setState(
      (state) => ({
        ...state,
        products: false,
      }),
      () => {
        const { category, code } = this.props.match.params;
        const params = {
          partnerCode: code,
          categoryCode: category,
          dateIn: this.props.filter.dateIn.format("YYYY-MM-DD"),
          dateOut: this.props.filter.dateOut.format("YYYY-MM-DD"),
          adults: this.props.filter.adults,
          children: this.props.filter.children,
          time: this.props.filter.time,
          Fname: "",
          Lname: "",
          email: "",
        };
        if (filterData && filterData.minDate && filterData.maxDate) {
          if (filterData.minDate.isAfter(this.props.filter.dateIn)) {
            params.dateIn = filterData.minDate.format("YYYY-MM-DD");
          }

          if (filterData.maxDate.isBefore(this.props.filter.dateOut)) {
            params.dateOut = filterData.maxDate.format("YYYY-MM-DD");
          }

          if (
            util
              .moment(params.dateIn, "YYYY-MM-DD")
              .isAfter(util.moment(params.dateOut, "YYYY-MM-DD"))
          ) {
            params.dateOut = util
              .moment(params.dateIn, "YYYY-MM-DD")
              .add(1, "day")
              .format("YYYY-MM-DD");
          }
          if (
            filterData.maxAdults &&
            filterData.maxAdults < this.props.adults
          ) {
            params.adults = filterData.maxAdults;
          }
        }
        const nights =
          util
            .moment(params.dateOut, "YYYY-MM-DD")
            .diff(util.moment(params.dateIn, "YYYY-MM-DD"), "days") || 1;
        api.catalog.GetProductsByPartnerID(params).then((response) => {
          const products =
            response.data && Array.isArray(response.data)
              ? response.data.sort((a, b) => a.price - b.price)
              : -1;
          this.setState((state) => ({
            ...state,
            nights,
            products: products,
          }));
        });
      }
    );
  };

  render = () => {
    return (
      <Fragment>
        {this.state.data ? (
          <ProductData
            data={this.state.data}
            nights={this.state.nights}
            photos={this.state.photos}
            params={this.state.data.params}
            products={this.state.products}
            category={this.props.match.params.category}
            getRefreshProducts={this._getRefreshProducts}
            filterConfig={this.state.filterConfig}
          />
        ) : (
          <div style={{ height: "80vh" }} />
        )}
      </Fragment>
    );
  };
}
const mapStateToProps = (state) => ({
  filter: state.user.customerData.filter,
});
const mapDispatchToProps = (dispatch) => ({
  setCategory: (category) => {
    dispatch(actions.setCategory(category));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loading(LayoutProduct));
