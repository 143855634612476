const config = {
  methodName: "Orders",
  isTest: false,
  isDummy: false,
  method: "POST",
  dummyData: [
    {
      orderId: "163",
      orderDate: "25.01.2020 12:52:43",
      orderPayDate: "27.01.2020 12:52:47",
      statusCode: "P",
      status: "Оплачен, формируется к отправке",
      orderPrice: 262100
    },
    {
      orderId: "164",
      orderDate: "28.01.2020 12:52:43",
      orderPayDate: null,
      statusCode: "N",
      status: "Принят, ожидается оплата",
      orderPrice: 872100
    },
    {
      orderId: "87",
      orderDate: "20.01.2020 12:52:43",
      orderPayDate: null,
      statusCode: "N",
      status: "Принят, ожидается оплата",
      orderPrice: 2100
    },
    {
      orderId: "79",
      orderDate: "26.01.2020 12:52:43",
      orderPayDate: "29.01.2020 12:52:47",
      statusCode: "F",
      status: "Выполнен",
      orderPrice: 62100
    },
    {
      orderId: "76",
      orderDate: "21.01.2020 12:52:43",
      orderPayDate: "30.01.2020 12:52:47",
      statusCode: "F",
      status: "Выполнен",
      orderPrice: 362100
    },
    {
      orderId: "80",
      orderDate: "27.01.2020 12:52:43",
      orderPayDate: "29.01.2020 12:42:47",
      statusCode: "F",
      status: "Выполнен",
      orderPrice: 292100
    }
  ],
  prepareRequest: r => r,
  prepareResponse: r => r,
  handleError: e => e
};

export default config;
