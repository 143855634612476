import React, { useState, Fragment, useEffect } from "react";
import css from "./styles/ReviewComposeForm.module.scss";
import LoadingMessage from "../Interface/LoadingMessage";
import util from "../util/util";
import EditIcon from "@material-ui/icons/EditOutlined";
import Slider from "react-rangeslider";
import "./styles/slider.css";
import { Button } from "../Interface/Button";

const SLIDER_DEFAULT_VALUE = 5;

const ReviewComposeForm = (props) => {
	const [formTouched, setFormTouched] = useState(false);
	const [sliderData, updateSliderData] = useState(false);
	const [formData, updateFormData] = useState({
		reviewText: "",
		reviewTitle: "",
	});

	const sliderInitialValues = props.fields
		? util.arrayToAssoc(
				props.fields.map((item) => ({
					...item,
					value: SLIDER_DEFAULT_VALUE,
				})),
				"code"
		  )
		: null;

	if (sliderInitialValues && !sliderData) {
		updateSliderData(sliderInitialValues);
	}
	const updateSlider = (code, value) => {
		if (!formTouched) {
			setFormTouched(true);
		}
		const updatedsliderData = { ...sliderData };
		updatedsliderData[code].value = value;
		updateSliderData(updatedsliderData);
	};

	const updateField = (name, value) => {
		if (!formTouched) {
			setFormTouched(true);
		}
		const updatedForm = { ...formData };
		updatedForm[name] = value;
		updateFormData(updatedForm);
	};
	const sliderInputs = sliderData
		? props.fields.map((item, index) => {
				return (
					<div key={item.code} className={css["slider-group"]}>
						<label className={css["slider-title"]}>
							{item.title}
						</label>
						<div className={css["slider-container"]}>
							<Slider
								value={sliderData[item.code].value}
								min={1}
								step={1}
								max={10}
								onChange={(value) =>
									updateSlider(item.code, value)
								}
							>
								{item.title}
							</Slider>
						</div>
						<label className={css["slider-value"]}>
							{sliderData[item.code].value}
						</label>
					</div>
				);
		  })
		: null;
	// form submit Handler
	const submitReview = () => {
		util.log(formData, sliderData);
		const reviewData = { ...formData, reviewFields: {} };
		for (let field in sliderData) {
			const code = sliderData[field].code;
			const value = sliderData[field].value;
			reviewData.reviewFields[code] = value;
		}
		props.submitHandler(reviewData);
	};

	// Render result
	let formContent;

	if (!props.fields) {
		formContent = <LoadingMessage>Загружаем форму...</LoadingMessage>;
	} else {
		formContent = (
			<Fragment>
				<div className={css.section}>
					<h6 className={css.title}>Поставить оценку</h6>
					{sliderInputs}
				</div>

				<h6 className={css.title}>
					<EditIcon className="ut-theme-color" /> Мой отзыв
				</h6>
				<div className={util.join(css.section, "ut-theme-color")}>
					<div>
						<label className={css.label}>Заголовок</label>
						<input
							onChange={(e) =>
								updateField("reviewTitle", e.target.value)
							}
							className={css.input}
							type="text"
						/>
					</div>
					<div>
						<label className={css.label}>Комментарий</label>
						<textarea
							rows="5"
							className={css.textarea}
							type="text"
							onChange={(e) =>
								updateField("reviewText", e.target.value)
							}
						></textarea>
					</div>

					<Button
						className={util.join(
							css["form-btn"],
							css.button,
							css["button-post"]
						)}
						loading={props.loading}
						disabled={!formTouched}
						onClick={submitReview}
					>
						<span>отправить отзыв</span>
					</Button>
				</div>
			</Fragment>
		);
	}

	return (
		<div className={css.form}>
			<span className={css.close} onClick={props.closeHandler}>
				&#10005;
			</span>

			{formContent}
		</div>
	);
};

export default ReviewComposeForm;
