import React, { useState, useEffect, useRef } from "react";
import css from "./SearchResults.module.scss";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import LikeSwitch from "../LikeSwitch/LikeSwitch";
import Label from "../Label/Label";
import util from "../util/util";
import searchUtil from "./searchUtil";

import ShareIcon from "@material-ui/icons/Share";

import {
  FacebookShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  VKIcon,
} from "react-share";

const AcomodationSearchItem = (props) => {
  const imageLink = useRef();
  const dataLink = searchUtil.prepareLink(props.data, props.category);

  const changeLinkBackgroundImage = () => {
    return (imageLink.current.style.backgroundImage = `url(${
      util.dummyImage().offer
    })`);
  };

  return (
    <li
      onMouseEnter={() => {
        props.change(props.data.id);
      }}
      onMouseLeave={() => {
        props.change(false);
      }}
      className={`${
        props.hoverId == props.data.id ? css["itemHover"] : css["item"]
      } ut-theme-color`}
    >
      <LikeSwitch itemData={props.itemData} />
      <ul
        className={
          props.pickerDisplay ? css["item-socials"] : css["displayBlock"]
        }
      >
        <li>
          <button onClick={props.changeDisplay}>
            <ShareIcon />
          </button>
        </li>
        <li>
          <VKShareButton
            url={dataLink.url}
            title={dataLink.title}
            image={dataLink.image}
          >
            <VKIcon
              size={20}
              className="ut-theme-color ut-share"
              round={true}
            />
          </VKShareButton>
        </li>
        <li>
          <WhatsappShareButton
            url={dataLink.url}
            title={dataLink.title}
            separator={" "}
            image={dataLink.image}
          >
            <WhatsappIcon
              size={20}
              className="ut-theme-color ut-share"
              round={true}
            />
          </WhatsappShareButton>
        </li>

        {/* <li>
          <TwitterShareButton
            url={dataLink.url}
            quote={dataLink.title}
            hashtag={"#UniqTrip"}
          >
            <TwitterIcon
              size={20}
              className="ut-theme-color ut-share"
              round={true}
            />
          </TwitterShareButton>
        </li> */}
        {/* <li>
          <FacebookShareButton
            className={css["shareBtn"]}
            url={dataLink.url}
            quote={dataLink.title}
            hashtag={"#UniqTrip"}
            image={dataLink.image}
          >
            <FacebookIcon
              size={20}
              className="ut-theme-color ut-share"
              round={true}
            />
          </FacebookShareButton>
        </li> */}
      </ul>
      <Link
        to={props.partnerLink}
        style={{ backgroundImage: `url('${dataLink.image}')` }}
        ref={imageLink}
        className={`${css["item-img"]}`}
      >
        <img
          src={dataLink.image}
          alt=""
          style={{ display: "none" }}
          onError={changeLinkBackgroundImage}
        />
      </Link>

      <div
        className={
          props.partnerOrNot
            ? `${css["item-data"]} ${css["spaceCorrect"]}`
            : `${css["item-data"]}`
        }
      >
        <Link to={props.partnerLink} className={`${css["item-link"]}`}></Link>
        <Link to={props.partnerLink} className={`${css["item-title"]}`}>
          {util.getText(props.data.name)}
        </Link>
        <div className={css["labels"]}>{searchUtil.labels(props.data)}</div>
        {/* wrapper info */}
        <div className={css["wrapper-info"]}>
          <div className={`${css["info-text"]} ${css["info-addPadding"]}`}>
            {props.prepareText()}
            <ul className={css["serviceAcomodation"]}> {props.icons}</ul>
          </div>
          {/* wrapper price */}
          <div className={css["wrapper-price"]}>
            {props.data.params.price == undefined ? (
              " "
            ) : (
              <div className={`${css["item-price"]}`}>
                <span>от</span> {props.price}
              </div>
            )}
            {props.partnerOrNot ? null : (
              <div className={`${css["item-hint"]}`}>
                {props.data.category_id == 7 ? "цена за ночь" : null}
              </div>
            )}

            {props.partnerOrNot ? (
              <Link
                to={props.partnerLink}
                className={`${css["item-buttons"]} ${css["buttonUnderline"]}`}
              >
                <button className={`${css["filter-btn"]} ut-theme-color`}>
                  <span>Подробнее</span>
                </button>
              </Link>
            ) : (
              <div className={`${css["item-buttons"]}`}>
                <button
                  onClick={props.addToCart}
                  className={`${css["filter-btn"]} ut-theme-color`}
                >
                  <i className={`${css["item-btn-icon"]} ut-ico-cart`}></i>
                  <span>В корзину</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};
export default AcomodationSearchItem;

