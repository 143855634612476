import React from 'react';
import Wrapper from '../../hoc/Wrapper/Wrapper';
const Preloader = (props) => {
    let images = [];
    const css = {
        zIndex:-1,
        opacity:0,
        position:'absolute',
        top:0,
        left:0,
        height:"1px",
        width:"1px"

    }
    
    if (Array.isArray(props.images)) {
        images = props.images.map( (url,index) => {            
            return <img key={index} style={css} src={url} alt="" />
        })
    }
    return (<Wrapper>
        {images}
    </Wrapper>

    );
}

export default Preloader;