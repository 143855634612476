/**
 * Model class definition
 *
 * Fetches partner's reviews from API
 * Posts new review from current logged in user
 *
 */

import api from "../api/API";
import util from "../components/util/util";

class ReviewsModel {
	data = { ...initialState };
	_next = [];
	params = {};

	/**
	 * Constructor function
	 *
	 * @param {int} partnersId
	 * @param {int} categoryId
	 * @param {int} offset
	 * @param {function} updateCallback
	 *
	 */

	constructor(partnersId, categoryId, offset = 1, updateCallback = () => {}) {
		if (!partnersId || !categoryId) return false;
		this.params.offset = offset;
		this.params.partnersId = partnersId;
		this.params.categoryId = categoryId;
		this.updateCallback = updateCallback;
		//this.getNext();
		this.getTotals().then(this.getNext);
	}

	_set = (updated) => {
		const newData = { ...this.data, ...updated };
		this.data = {...newData};
		this.updateCallback(newData);
	};

	getNext = () => {
		this._next = [];
		return new Promise((resolve) => {
			if (this.data.loading.items) return resolve(false);
			if (!this.data.hasItems || !this.data.hasNext)
				return resolve(false);
			this._set({ loading: { items: true } });
			api.catalog
				.GetReviews(this.params)
				.then((response) => {
					if (response.data.length > 0) {
						this._set({
							items: [...this.data.items, ...response.data],
						});
						this._next = [...response.data];
						this.params.offset += 1;
						if (
							this.data.totals.totalReviews &&
							this.data.totals.totalReviews > 0 &&
							this.data.totals.totalReviews ===
								this.data.items.length
						) {
							this._set({ hasNext: false });
						}
					} else if (this.data.items.length === 0) {
						this._set({ hasNext: false });
						this._set({ hasItems: false });
					} else {
						this._set({ hasNext: false });
					}
					resolve();
				})
				.finally(() => {
					this._set({ loading: { items: false } });
				});
		});
	};

	getReviewFields = () => {
		return new Promise((resolve) => {
			if (this.data.fields) return resolve(this.data.fields);
			if (this.data.loading.fields) return resolve(this.data.fields);
			this._set({ loading: { fields: true } });
			api.catalog
				.GetReviewFields(this.params)
				.then((response) => {
					if (response.data && Array.isArray(response.data)) {
						this._set({ fields: [...response.data] });
					}
					resolve(this.data.fields);
				})
				.finally(() => {
					this._set({ loading: { totals: false } });
				});
		});
	};

	getTotals = () => {
		return new Promise((resolve) => {
			if (this.data.totals) {return resolve(this.data.totals)};
			if (this.data.loading.totals) return resolve(this.data.totals);
			this._set({ loading: { totals: true } });
			api.catalog
				.GetPartnersRating(this.params)
				.then((response) => {
					if (response.data) {
						this._set({ totals: { ...response.data } });

						if (
							this.items &&
							this.data.totals.totalReviews === this.items.length
						) {
							this._set({ hasNext: false, hasItems: false });
						}

						if (this.data.totals.totalReviews === 0) {
							this._set({ hasNext: false, hasItems: false });
						}
					}

					resolve(this.totals);
				})
				.finally(() => {
					this._set({ loading: { totals: false } });
				});
		});
	};

	addReview = (reviewData) => {
		if (this.data.loading.items) return false;

		return new Promise((resolve) => {
			this._set({ loading: { review: true } });
			api.catalog.AddReview({ ...reviewData }).then((response) => {
				if (response.data) {
					resolve(response.data);
				} else {
					resolve(ErrorObject);
				}
			});
		}).finally(() => {
			this._set({ loading: { review: false } });
		});
	};
}

export default ReviewsModel;

/**
 *
 * Standard Error object
 *
 */

const ErrorObject = {
	error: true,
	msg: "Произошла ошибка",
};

let source = false;
let initParams = {};
/**
 *
 * Provides singleton source
 *
 */

export const getSource = (...params) => {
	if (!source || !util.objectsEqual(params, initParams)) {
		initParams = { ...params };
		source = new ReviewsModel(...params);
	}

	return source;
};

/**
 *
 * initial State object for hooks
 *
 * Use this initial state object to ensure
 * that source data can be reflected in state properly
 *
 */

export const initialState = {
	items: [],
	totals: false,

	hasItems: true,
	hasNext: true,
	loading: {
		items: false,
		totals: false,
		fields: false,
		review: false,
	},

	fields: false,
};
