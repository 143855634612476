import React, { Fragment, useState } from "react";
import Header from "./../components/Header/Header";
import Footer from "./../components/Footer/Footer";

import "./LayoutCommon.scss";

const LayoutCommon = (props) => {
  const [activeHeaderSearchField, setActiveHeaderSearchField] = useState(false);
  const [geozonePickerVisible, setGeozonePickerVisible] = useState(false)

  return (
    <Fragment>
      <Header
        activeSearchField={activeHeaderSearchField}
        geozonePickerVisible={geozonePickerVisible}
        setActiveSearchField={setActiveHeaderSearchField}
        setGeozonePickerVisible={setGeozonePickerVisible}
      />
      <div
        className="ut-main-container"
        onClick={() => {
          setActiveHeaderSearchField(false);
          setGeozonePickerVisible(false)
        }}
      >
        {props.children}
      </div>
      <Footer setActiveSearchField={setActiveHeaderSearchField} />
    </Fragment>
  );
};

export default LayoutCommon;
