const config = {
    methodName:'BestProducts',
    isTest:true,
    isDummy:false,
    method:'GET',
    dummyData:[],
    
}

export default config; 


