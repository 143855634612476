import React, { Component } from "react";
import css from "../components/Cart/Cart.module.scss";
import ShareIcon from "@material-ui/icons/Share";
import Cart from "../components/Cart/Cart";
import OrderTotals from "../components/OrderTotals/OrderTotals";
import api from "../components/API/new/API";
import apiDev from "../components/API/API.dev";
import CustomerData from "../components/CustomerData/CustomerData";

import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  VKIcon,
} from "react-share";
import { connect } from "react-redux";
import * as actions from "../store/actions/index";
import util from "../components/util/util";

class LayoutCart extends Component {
  state = {
    cart: {
      partners: [],
    },
    customerFormVisible: false,
    link: "",
    pickerDisplay: true,
    emergency: true,
    emergencyText: "",
  };

  componentDidMount = () => {
    this.props.getCart((cart) => {});
    window.scrollTo(0, 0);
    document.title = "Uniqtrip - конструктор приключений: Корзина";
    apiDev("settings/checkEmergencyStatus").then((resp) => {
      if (typeof resp.data == "object") {
        if (Object.entries(resp.data).length === 0) {
          this.setState({
            ...this.state,
            emergency: false,
          });
        } else {
          if (resp.data.stopSales == undefined) {
            this.setState({
              ...this.state,
              emergency: false,
            });
          } else {
            this.setState({
              ...this.state,
              emergency: resp.data.stopSales,
              emergencyText: resp.data.emergencyMessage,
            });
          }
        }
      }
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    //window.scrollTo(0, 0);
  };

  payHandler = (customerData, loadingToggler) => {
    let cartComment;

    if (this.props.user.customerData.cartComment) {
      cartComment = this.props.user.customerData.cartComment;
    }

    if (this.props.user.isLogged) {
      const userComment = cartComment
        ? { user_comment: cartComment }
        : undefined;

      return api.commerce.AddOrder(userComment).then((response) => {
        if (response.data) {
          const url = response.data.url;
          window.location = url;
        } else {
          alert("Регистрация заказа не удалась");
        }
      });
    } else {
      if (this.state.customerFormVisible) {
        const dataToSend = cartComment
          ? { ...customerData, user_comment: cartComment }
          : customerData;

        loadingToggler(true);
        return api.commerce
          .QuickOrder(dataToSend)
          .then((response) => {
            if (response.data && !response.data.error) {
              const url = response.data.url;
              window.location = url;
            } else {
              alert(
                util.join("Регистрация заказа не удалась.", response.data.msg)
              );
            }
          })
          .catch((e) => alert(e))
          .finally(() => loadingToggler(false));
      }
      return this.setState((state) => ({
        ...state,
        customerFormVisible: true,
      }));
    }
  };

  formHideHandler = () => {
    this.setState((state) => ({ ...state, customerFormVisible: false }));
  };
  params = () => {
    return this.props.cart.partners.map((item) => {
      let dateIn = "";
      let dateOut = "";
      let time = "";
      let adults = "";
      let children = "";
      item.items[0].params.forEach((res) => {
        if (res.code === "dateIn") {
          dateIn = res.value;
        }
        if (res.code === "dateOut") {
          dateOut = res.value;
        }
        if (res.code === "time") {
          time = res.value;
        }
        if (res.code === "adults") {
          adults = res.value;
        }
        if (res.code === "children") {
          children = res.value;
        }
      });

      return {
        productId: item.productId,
        categoryId: item.categoryId,
        dateIn: dateIn,
        dateOut: dateOut,
        adults: adults,
        children: children,
        time: time,
      };
    });
  };

  getLink = () => {
    let parsed = JSON.stringify(this.params());
    let test = {
      cart: parsed,
    };
    console.log(parsed);

    apiDev("commerce/GetCartShareLink", test).then((response) => {
      this.setState((state) => ({
        ...state,
        link: response.data,
      }));
    });
  };
  changeDisplayHandler = () => {
    if (this.state.pickerDisplay) {
      this.getLink();
    }

    let copyState = this.state.pickerDisplay;
    this.setState((state) => ({
      ...state,
      pickerDisplay: !copyState,
    }));
  };
  render = () => {
    return (
      <div className="ut-section__container">
        <div className={css["wrapped"]}>
          <h1 className={util.join("ut-section__title", css["headerBasket"])}>
            Моя корзина{" "}
          </h1>
          <a
            href="#share"
            className={
              this.state.pickerDisplay
                ? util.join("ut-section__title-link", css["mainLink"])
                : "ut-section__title-link"
            }
            onClick={this.changeDisplayHandler}
          >
            <ShareIcon />
            <span>
              {this.state.pickerDisplay
                ? "Поделиться своим списком покупок"
                : ""}
            </span>
          </a>

          <ul
            className={
              this.state.pickerDisplay
                ? css["item-socialsAll"]
                : css["displayBlockAll"]
            }
          >
            {/* <li className={" ut-theme-color"}>
							<FacebookShareButton
								url={this.state.link}
								quote={"Моя корзина на UniqTrip"}
								hashtag={"#UniqTrip"}
							>
								<FacebookIcon
									size={20}
									className="ut-theme-color ut-share"
									round={true}
								/>
							</FacebookShareButton>
						</li> */}
            <li className={" ut-theme-color"}>
              <VKShareButton
                url={this.state.link}
                title={"Моя корзина на UniqTrip"}
              >
                <VKIcon
                  size={20}
                  className="ut-theme-color ut-share"
                  round={true}
                />
              </VKShareButton>
            </li>
            <li className={" ut-theme-color"}>
              <WhatsappShareButton
                url={this.state.link}
                title={"Моя корзина на UniqTrip"}
                separator={" "}
              >
                <WhatsappIcon
                  size={20}
                  className="ut-theme-color ut-share"
                  round={true}
                />
              </WhatsappShareButton>
            </li>
            {/* <li className={" ut-theme-color"}>
							<TwitterShareButton
								url={this.state.link}
								quote={"Моя корзина на UniqTrip"}
								hashtag={"#UniqTrip"}
							>
								<TwitterIcon
									size={20}
									className="ut-theme-color ut-share"
									round={true}
								/>
							</TwitterShareButton>
						</li> */}
          </ul>
        </div>
        <div className={css.layout}>
          <div className={css.col_left}>
            <Cart
              profile={this.props.user.profile}
              removeHandler={this.props.removeFromCart}
              partners={this.props.cart.partners}
              categories={[...this.props.categories]}
            />
            {this.state.customerFormVisible ? (
              <CustomerData
                visible={false}
                user={this.props.user}
                saveHandler={this.payHandler}
                hideHandler={this.formHideHandler}
              />
            ) : null}
          </div>
          <div className={css.col_right}>
            <OrderTotals
              isLogged={this.props.user.isLogged}
              payHandler={this.payHandler}
              total={this.props.cart.total}
              partners={[...this.props.cart.partners]}
              emergency={this.state.emergency}
              emergencyText={this.state.emergencyText}
            />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  user: state.user,
  cart: state.user.cart,
  categories: state.catalog.categories,
});

const mapDispatchToProps = (dispatch) => ({
  getCart: () => dispatch(actions.getCart()),
  removeFromCart: (items) => dispatch(actions.removeFromCart(items)),
  register: (formData) => dispatch(actions.register(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutCart);

