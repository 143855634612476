import React, { Component } from "react";
import { connect } from "react-redux";

class ThemeControls extends Component {
    componentDidMount() {
        document.body.classList.add("theme-azul");
    }

    componentDidUpdate(prevProps, prevState) {
        const themeSuffix = this.props.theme ? this.props.theme : "azul";
        if (prevProps.theme !== this.props.theme) {
            document.body.classList.forEach(e => {
                if (e.substr(0, 6) === "theme-") {
                    document.body.classList.remove(e);
                }
            });
            const theme = "theme-" + themeSuffix;
            document.body.classList.add(theme);
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = state => ({
    theme: state.user.profile.theme
});

export default connect(mapStateToProps)(ThemeControls);
