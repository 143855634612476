import * as actionTypes from "../actionTypes";

export const setFilter = data => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FITLER,
            filter: data
        });
    };
};

export const setCategory = data => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_CATEGORY,
            category: data
        });
    };
};

export const setTerm = term => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TERM,
            term
        });
    };
};

export const setSort = sort => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_SORT,
            sort
        });
    };
};

export const addCartComment = comment => {
    return dispatch => {
        dispatch({
            type: actionTypes.ADD_CART_COMMENT,
            comment
        });
    };
}
