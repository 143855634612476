import React, { useState, Fragment } from "react";
import css from "./styles/TimeChart.module.scss";
import withOverlay from "../../hoc/Overlay/Overlay";
import ButtonItem from "./ButtonItem";

import util from "../util/util";
import PropTypes from "prop-types";

const TimeChart = (props) => {
  const [hour, setHour] = useState(props.hour);
  const [minutes, setMinutes] = useState(props.minutes);
  const [adults, setAdults] = useState(props.adults);
  const [children, setChild] = useState(props.children);
  const more = props.more;
  const MAX_ADULTS = props.maxAdults || 8;
  const MAX_CHILDREN = props.maxChildren || 4;
  let items = 24;
  let startCount = 0;
  let select = hour;
  let setterState = (val) => {
    setHour(val);
  };
  let setterStateChild = (val) => {
    setChild(val);
  };
  let setterStateAdult = (val) => {
    setAdults(val);
  };

  const stepMinutes = () => {
    let devideHour = 60 / props.steps;
    let allMinutes = [];
    let step = -props.steps;

    for (let i = 0; i < devideHour; i++) {
      let value;
      if (i === 0) {
        value = String((step += props.steps)).padStart(2, 0);
      } else {
        value = step += props.steps;
      }
      allMinutes.push(
        <ButtonItem
          key={i}
          number={value}
          minutes={props.minutes}
          clickHandler={(val) => setMinutes(val)}
          selected={minutes == value}
        />
      );
    }
    return allMinutes;
  };
  //
  const moreChild = () => {
    let itemsChild = MAX_CHILDREN + 1;
    let startCountChild = 0;
    let selectChild = children;
    let allChild = [];
    for (let i = startCountChild; i < itemsChild; i++) {
      let value = i;
      allChild.push(
        <ButtonItem
          key={i}
          number={value}
          clickHandler={(val) => setterStateChild(val)}
          selected={selectChild == value}
        />
      );
    }
    return allChild;
  };
  const moreAdult = () => {
    let itemsAdult = MAX_ADULTS + 1;
    let startCountAdult = 1;
    let selectAdult = adults;
    let moreAdult = [];
    for (let i = startCountAdult; i < itemsAdult; i++) {
      let value = i;
      moreAdult.push(
        <ButtonItem
          key={i}
          number={value}
          clickHandler={(val) => setterStateAdult(val)}
          selected={selectAdult == value}
        />
      );
    }
    return moreAdult;
  };

  let allHour = [];
  let classes = [css["time-column"]];

  classes.push(css.extra);

  let displayData = (
    <div className={css["container"]}>
      <div className={css["wrap-time"]}>
        <div className={css["time-column"]}>
          <div className={util.join("ut-theme-color", css["time-header"])}>
            Часы
          </div>
          <div className={css["container-hours"]}>
            <div className={css["hours-wrapper"]}>{allHour}</div>
          </div>
        </div>
        <div className={classes.join(" ")}>
          <div className={util.join("ut-theme-color", css["time-header"])}>
            Минуты
          </div>
          <div className={css["hours-wrapper"]}>{stepMinutes()}</div>
        </div>
      </div>
      <div className={css["chart-container-actions"]}>
        <button
          className={util.join("ut-theme-color", css["actions-button"])}
          onClick={() => props.pickHandler(hour, minutes)}
        >
          <span>Сохранить</span>
        </button>
      </div>
    </div>
  );
  if (more) {
    classes.push(css.withoutPadding);
    displayData = (
      <div className={css["container"]}>
        <div className={css["wrap-time"]}>
          <div className={css["time-column-more"]}>
            <div
              className={util.join(
                "ut-theme-color",
                css["time-header-more-filter"]
              )}
            >
              Количество взрослых
            </div>
            <div
              className={
                more ? css["container-hours-more"] : css["container-hours"]
              }
            >
              <div
                className={
                  more ? css["hours-wrapper-more"] : css["hours-wrapper"]
                }
              >
                {moreAdult()}
              </div>
            </div>
          </div>
          <div className={`${css["time-column-more"]} ${css["extraMore"]}`}>
            <div
              className={util.join(
                "ut-theme-color",
                more ? css["time-header-more-filter"] : css["time-header"]
              )}
            >
              Количество детей
            </div>
            <div
              className={
                more ? css["hours-wrapper-more"] : css["hours-wrapper"]
              }
            >
              {moreChild()}
            </div>
          </div>
        </div>
        <div className={css["chart-container-actions"]}>
          <button
            className={util.join("ut-theme-color", css["actions-button"])}
            onClick={() => props.pickHandler(adults, children)}
          >
            <span>Сохранить</span>
          </button>
        </div>
      </div>
    );
  }

  for (let i = startCount; i < items; i++) {
    let value = i;
    allHour.push(
      <ButtonItem
        key={i}
        number={value}
        clickHandler={(val) => setterState(val)}
        selected={select == value}
      />
    );
  }
  return <Fragment>{displayData}</Fragment>;
};
TimeChart.propTypes = {
  steps: PropTypes.number,
};
export default withOverlay(TimeChart);
