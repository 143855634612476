import React from "react";
import css from "./Footer.module.scss";
import util from "../util/util";
import BusinessIcon from "@material-ui/icons/Business";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import CompanyLogo from "../../assets/scss/ut-icons/logo 2 white (2) 1.svg";

const Footer = (props) => {
  return (
    <footer
      className={`${css["footer"]}`}
      onClick={() => props.setActiveSearchField(false)}
    >
      <div className={util.join(css["container"], "ut-theme-color")}>
        <div
          className={[css["form-adress"], "ut-section__container"].join(" ")}
        >
          <section className={css["form-container"]}>
            <div id="bx24_form_inline">
              <script data-b24-form="inline/28/m9yi35" data-skip-moving="true">
                {(function (w, d, u) {
                  var s = d.createElement("script");
                  s.async = true;
                  s.src = u + "?" + ((Date.now() / 180000) | 0);
                  var h = d.getElementsByTagName("script")[0];
                  h.parentNode.insertBefore(s, h);
                })(
                  window,
                  document,
                  "https://cdn-ru.bitrix24.ru/b11725818/crm/form/loader_28.js"
                )}
              </script>
            </div>
          </section>
          <section className={css["address-container"]}>
            <h3>Наши контакты</h3>
            <address className={css["address-container-address"]}>
              <ul>
                <li>
                  <BusinessIcon />
                  <h4>Наш адрес</h4>
                  <p>пгт Сириус, Триумфальный проезд 1, офис 2–021 </p>
                </li>
                <li>
                  <ScheduleIcon />
                  <h4>Время работы</h4>
                  <p>Пн - сб: 09.00 -18.00</p>
                </li>
                <li>
                  <a href="tel:+78622777042">
                    <PhoneCallbackIcon />
                    <h4>Номер телефона</h4>
                    <p>+7 862 277 70 42</p>
                  </a>
                </li>
                <li>
                  <a href="mailto:info@uniqtrip.ru">
                    <MailOutlineIcon />
                    <h4>Email</h4>
                    <p>info@uniqtrip.ru</p>
                  </a>
                </li>
              </ul>
            </address>
            <p className={css["address-container-descript"]}>
              Общество с ограниченной ответственностью "УНИКАЛЬНОЕ ПУТЕШЕСТВИЕ"
              <br />
              ИНН 2367010913 ОГРН 1192375066586 КПП 236701001
              <br />
              Расчетный счет 407028104
              <br />
              Банк АО «Тинькофф Банк»
              <br />
            </p>
            <div className={css["footer-app"]}>
              <a href="https://play.google.com/store/apps/details?id=com.uniqtrip.uniqtrip">
                <AndroidIcon className={"ut-theme-color"} />
              </a>
              <a href="https://apps.apple.com/us/app/uniqtrip/id1499071482?l=ru&ls=1">
                <AppleIcon className={"ut-theme-color"} />
              </a>
              <span>Наше приложение в AppStore и Google Play</span>
            </div>
          </section>
        </div>
        <div className={css["logo"]}>
          <p className={css["logo__body"]}>
            <span className={css["logo__text"]}>
              © {new Date().getFullYear()} All rights reserved{" "}
            </span>
            <a
              href="https://uniqtripsoft.ru/"
              target="_blanc"
              className={[css["logo-link"], "ut-theme-color"].join(" ")}
            >
              <img src={CompanyLogo} alt="Log" />
            </a>
          </p>
          <a
            target="_blanc"
            href="https://uniqtrip.ru/mobile/security_policy.pdf"
            className={[css["linkBottom"], "ut-theme-color"].join(" ")}
          >
            Политика конфиденциальности
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

