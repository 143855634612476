const config = {
	methodName: "GetPartnersRating",
	isTest: false,
	isDummy: false,
	method: "post",
	dummyData: {},

	prepareRequest: (r) => r,

	prepareResponse: (r) => r,

	handleError: (e) => e,
};

export default config;
