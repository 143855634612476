import React, { useState, useEffect, Fragment } from "react";
import css from "./styles/UserData.module.scss";
import util from "../util/util";
import { PanelWide, Button } from "../Interface/Interface";
import FavouritesList from "./_FavouritesList";
import { Link } from "react-router-dom";
import UserEdit from "./_UserEdit";
import UserOrders from "./_UserOrders";
import Community from "./_Community";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

const interfaceModes = {
	EDIT: "EDIT",
	FAVOURITES: "FAVOURITES",
	CALENDAR: "CALENDAR",
	ORDERS: "ORDERS",
	COMMUNITY: "COMMUNITY",
};

const UserData = (props) => {
	let defaultMode = interfaceModes.FAVOURITES;
	if (!props.favourites || props.favourites.length < 1) {
		defaultMode = interfaceModes.COMMUNITY;
	}

	const [mode, setMode] = useState(defaultMode);

	// Fires after successfull profile update
	const saveCallback = () => {
		alert("Данные успешно обновлены");
		setMode(interfaceModes.FAVOURITES);
	};

	// Fires after successfull profile image update
	const uploadCallback = () => {
		setMode(interfaceModes.FAVOURITES);
	};

	let actionPanel;

	switch (mode) {
		case interfaceModes.FAVOURITES:
			actionPanel = <FavouritesList />;
			break;

		case interfaceModes.ORDERS:
			actionPanel = <UserOrders emergency={props.emergency} />;
			break;

		case interfaceModes.COMMUNITY:
			actionPanel = <Community />;
			break;

		case interfaceModes.EDIT:
			actionPanel = <UserEdit saveCallback={saveCallback} />;
			break;

		default:
			actionPanel = <FavouritesList />;
	}

	const editAction =
		mode === interfaceModes.EDIT ? (
			<Button
				className={util.join("ut-theme-color", css["button-cancel"])}
				onClick={() => setMode(interfaceModes.FAVOURITES)}
			>
				отмена
			</Button>
		) : (
			<Button
				className={util.join("ut-theme-color", css["button-edit"])}
				onClick={() => setMode(interfaceModes.EDIT)}
			>
				Редактировать
			</Button>
		);

	// Use selected avatar photo
	const avatarPickHandler = (event) => {
		const image = util.getPropertySafe(event.target, "files.0", null);
		if (!image) {
			return;
		}
		const approve = window.confirm("Загрузить обновленный аватар?");
		if (approve) {
			const data = [["photoImage", image, image.name]];
			return props.uploadUserPhoto(data, uploadCallback);
		}
		event.target.value = "";
	};

	const editAvatarPic =
		mode == interfaceModes.EDIT ? (
			<div className={css["img-edit"]}>
				<i
					className={util.join(css["img-edit__icon"], "ut-ico-view1")}
				></i>
				<input
					type="file"
					name="photo"
					onChange={(e) => avatarPickHandler(e)}
					className={css["img-edit__input"]}
				/>
			</div>
		) : null;

	return (
		<Fragment>
			<PanelWide>
				<div className={css["wrapper"]}>
					<div className={css["col-image"]}>
						<img src={props.profile.photo} />
						{editAvatarPic}
					</div>
					<div className={css["col-info"]}>
						<p className={css["name"]}>
							{props.profile.firstName} {props.profile.lastName}
						</p>
						<p className={css["text"]}>
							Email: {props.profile.email}
						</p>
						<p className={css["text"]}>
							Телефон: {props.profile.phone}
						</p>
						<p>{editAction}</p>
					</div>
					<div
						className={util.join(css["col-menu"], "ut-theme-color")}
					>
						<ul className={css["menu-container"]}>
							<li
								className={util.join(
									css["menu-item"],
									mode == interfaceModes.FAVOURITES &&
										css["menu-item--active"]
								)}
								onClick={() =>
									setMode(interfaceModes.FAVOURITES)
								}
							>
								<span>
									<i className="ut-ico-calendar"></i> Мое
									Избранное
								</span>
							</li>
							{/*                             <li
                                className={css["menu-item"]}
                                onClick={() => setMode(interfaceModes.CALENDAR)}
                            >
                                <i className="ut-ico-calendar"></i> Мой
                                календарь и события
                            </li> */}
							<li
								className={util.join(
									css["menu-item"],
									mode == interfaceModes.ORDERS &&
										css["menu-item--active"]
								)}
								onClick={() => setMode(interfaceModes.ORDERS)}
							>
								<span>
									<i className="ut-ico-calendar"></i> Мои
									покупки
								</span>
							</li>
							<li
								className={util.join(
									css["menu-item"],
									mode == interfaceModes.COMMUNITY &&
										css["menu-item--active"]
								)}
								onClick={() =>
									setMode(interfaceModes.COMMUNITY)
								}
							>
								<span>
									<i className="ut-ico-calendar"></i>{" "}
									Сообщество
								</span>
							</li>
							<li
								className={css["menu-item"]}
								onClick={props.logout}
							>
								<Link
									className={util.join(
										"ut-theme-color",
										css["linkHome"]
									)}
									to={"/"}
								>
									<span>
										<i className="ut-ico-more"></i> Выйти
									</span>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</PanelWide>

			{actionPanel}
		</Fragment>
	);
};

const mapDispatchToProps = (dispatch) => ({
	uploadUserPhoto: (data, callback) =>
		dispatch(actions.uploadUserPhoto(data, callback)),
});

export default connect(null, mapDispatchToProps)(UserData);
