import React, { Fragment } from "react";

const style = {
  border: "none",
  overflow: "hidden",
  margin: "0 10px",
};
const Community = () => {
  return (
    <Fragment>
      <h3 className="ut-section__title">Сообщество</h3>
      <div>
        <iframe
          src="https://vk.com/widget_community.php?gid=198887666&width=340&height=500&mode=4"
          width="340"
          height="500"
          style={style}
          scrolling="no"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        />

        <iframe
          src="https://vk.com/widget_community.php?gid=219131266&width=340&height=500&mode=4"
          width="340"
          height="500"
          style={style}
          scrolling="no"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        />

        <iframe
          src="https://vk.com/widget_community.php?gid=72782696&width=340&height=500&mode=4"
          width="340"
          height="500"
          scrolling="no"
          frameborder="0"
          allowTransparency="true"
          allow="encrypted-media"
        />
      </div>
    </Fragment>
  );
};

export default Community;

