import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import util from "../util/util";
import css from "../SearchInput/SearchInput.module.scss";
const SearchInput = (props) => {
	const [isInput, toggleInput] = useState(false);
	const [term, updateTerm] = useState(props.term);

	const searchHandler = () => {
		toggleInput(false);
		if (term.trim().length < 1) {
			updateTerm(props.term);
			return alert("Введите строку для поиска");
		}
		props.searchHandler(term);
	};

	useEffect(() => {
		updateTerm(props.term);
	}, [props.term]);

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			searchHandler();
		}
	};

	return (
		<div
			className={"ut-theme-color " + css["container"]}
			onClick={(e) => {
				if (e.target.tagName.toLowerCase() == "div") {
					toggleInput(!isInput);
				}
			}}
		>
			<div className={css["input-group"]}>
				<label
					onClick={(e) => {
						if (e.target.tagName.toLowerCase() == "label") {
							toggleInput(true);
						}
					}}
					className={!isInput ? css["label"] : css["labelInput"]}
				>
					<i className="ut-ico-search"></i> <span>Вы искали</span>
					{isInput ? (
						<Fragment>
							<input
								className={css["field"]}
								type="text"
								defaultValue={term}
								onChange={(e) => updateTerm(e.target.value)}
								onKeyDown={handleKeyDown}
							/>
							<button
								className={css["button"]}
								onClick={searchHandler}
							>
								<span
									className={css["btnSpan"]}
									onClick={searchHandler}
								>
									Искать
								</span>{" "}
							</button>
						</Fragment>
					) : (
						<em
							className={css["searchInput"]}
							onClick={() => toggleInput(true)}
						>
							{" "}
							"{props.term}"
						</em>
					)}
				</label>
			</div>
		</div>
	);
};

SearchInput.propTypes = {
	term: PropTypes.string.isRequired,
	searchHandler: PropTypes.func.isRequired,
};

export default SearchInput;
