import * as actionTypes from "../actionTypes";
import api from "../../../components/API/new/API";
import util from "../../../components/util/util";
import FavouritesModel from "../../../model/Favourites";

export const login = data => {
    return dispatch => {
        dispatch({
            type: actionTypes.AUTH_PENDING
        });
        api.user
            .Login({ ...data })
            .then(response => {
                if (!response.data.error) {
                    // sync favourites from localstorage
                    const localFavourites = FavouritesModel.get();
                    api.user.AddFavourites(localFavourites).then(response => {
                        //util.log(data);
                        if (!response.data.error) {
                            return dispatch({
                                type: actionTypes.SET_FAVOURITES,
                                data: response.data
                            });
                        }
                    });
                    return dispatch({
                        type: actionTypes.AUTH_SUCCESS,
                        user: response.data.user
                            ? response.data.user
                            : response.data
                    });
                }
                const message = response.data.msg
                    ? response.data.msg
                    : "Авторизация не пройдена";
                // dispatch error action only if credentials were provided

                if (data) {
                    return dispatch({
                        type: actionTypes.AUTH_FAILED,
                        error: message
                    });
                }
                return dispatch({
                    type: actionTypes.AUTH_RESET
                });
            })
            .catch(e => {
                const message = "Произошла ошибка сервера";
                dispatch({
                    type: actionTypes.AUTH_FAILED,
                    error: message
                });
            });
    };
};

export const authReset = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.AUTH_RESET
        });
    };
};

export const logout = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.AUTH_PENDING
        });
        api.user
            .Logout()
            .then(response => {
                if (!response.data.error) {
                    dispatch({
                        type: actionTypes.AUTH_NOT_PERFORMED
                    });
                } else {
                    const message = response.data.msg
                        ? response.data.msg
                        : "Завершение сессии не удалось";
                    dispatch({
                        type: actionTypes.AUTH_FAILED,
                        error: message
                    });
                }
            })
            .catch(e => {
                const message = "Произошла ошибка сервера";
                dispatch({
                    type: actionTypes.AUTH_FAILED,
                    error: message
                });
            });
    };
};
