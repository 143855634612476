import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useRef,
} from "react";
import css from "./SearchResults.module.scss";
import style from "../TimePicker/styles/TimePicker.module.scss";
import { Link } from "react-router-dom";
import * as routes from "../util/routes";

import Litepicker from "../../lib/DatePickerModal/DatePickerModal";

import CategoryChart from "../TimePicker/CategoryChart";
import SortIcon from "@material-ui/icons/Sort";
import { grey } from "@material-ui/core/colors";
import util from "../util/util";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import TimeChart from "../TimePicker/TimeChart";
import searchUtil from "./searchUtil";
import Icon from "@material-ui/core/Icon";
import geozoneIcon from "../../assets/scss/ut-icons/geozoneIcon.svg";
import geozoneTriangle from "../../assets/scss/ut-icons/geozones_triangle.svg";

let picker;
const SearchResultsFilter = (props) => {
  const fieldDateIn = React.createRef();
  const fieldDateOut = React.createRef();

  const [category, setCategory] = useState(props.category.id);
  const [visible, setVisible] = useState(false);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [pickerVisibleAdults, setPickerVisibleAdults] = useState(false);

  const geozonesRef = useRef();

  const filterConfig = useCallback((param) => {
    let config = {};
    // If no category selected, or accommodation is selected -
    // double date picker,
    // else - single date
    config.singleMode = !(props.category.id == 7);
    config.datePickerClassName = config.singleMode
      ? util.join(css["input-text-inline"], css["input-text"])
      : css["input-text"];
    //config.datePickerPlaceholder = getDateText(config.singleMode);
    config["field1_label"] = config.singleMode ? "Когда" : "Заезд";
    config["field2_label"] = config.singleMode ? "Во сколько" : "Выезд";
    if (param && config.hasOwnProperty(param)) {
      return config[param];
    }
    return config;
  });
  useEffect(() => {
    const config = filterConfig();
    const input_dateIn = fieldDateIn.current;
    const input_dateOut = fieldDateOut.current;
    if (picker) {
      picker.destroy();
      document.body.classList.remove("litepicker-open");
    }
    picker = new Litepicker({
      element: input_dateIn,
      elementEnd: config.singleMode ? null : input_dateOut,
      singleMode: config.singleMode,
      numberOfMonths: 1,
      format: "DD.MM.YYYY",
      numberOfColumns: 1,
      minDate: util.moment().add(2, "day"),
      lang: "ru-RU",
      tooltipText: {
        one: "день",
        few: "дня",
        many: "дней",
      },
      startDate: props.filter.dateIn.format("DD.MM.YYYY"),
      endDate: config.singleMode
        ? null
        : props.filter.dateOut.format("DD.MM.YYYY"),
      onSelect: function (start, end) {
        const _end = end || util.moment(start).add(0, "day");

        props.setFilter({
          dateIn: util.moment(start),
          dateOut: util.moment(_end),
        });
      },
    });
  }, [fieldDateIn, fieldDateOut, filterConfig, props, props.category]);
  //state for CategoryPiker
  const dateIn = props.filter.dateIn.format("DD.MM.YYYY");
  const dateOut =
    props.filter.dateOut.format("DD.MM.YYYY") ||
    props.filter.dateIn.format("DD.MM.YYYY");
  const categoryUrl = routes.CATEGORY_PREFIX + props.category.code;

  const dateOut_input = (
    <input
      readOnly
      value={filterConfig("singleMode") ? props.filter.time : dateOut}
      name="hotel_date_out"
      className={css["filter-input"]}
      ref={fieldDateOut}
    />
  );

  // set sort type for filter
  const toggleSort = () => {
    const sort = props.sort;
    props.setSort(sort === "ASC" ? "DESC" : "ASC");
  };
  if (category !== props.category.id) {
    setCategory(props.category.id);
    props.filter.subCategory = null;
  }
  //logic for CategoryPicker
  let defaultValue =
    props.filter.subCategory === null ? "Все" : props.filter.subCategory.name;
  let nameSubcategories = props.subCategories.map((el) => {
    return el.name;
  });
  let chart = (
    <CategoryChart
      arraySubCategory={nameSubcategories}
      defaultValue={defaultValue}
      subCategories={props.subCategories}
      subCategory={props.filter.subCategory}
      test={true}
      pickHandler={(sub) => {
        setPickerVisible(false);
        props.setFilter({
          subCategory: sub,
        });
      }}
      hideHandler={() => setPickerVisible(false)}
    />
  );
  //logic for adults & children picker
  let sumAdults = () => {
    if (props.filter.adults < 2) {
      return " взрослый";
    } else {
      return " взрослых";
    }
  };
  let sumChild = () => {
    if (props.filter.children < 1) {
      return "без детей";
    } else if (props.filter.children == 1) {
      return props.filter.children + " ребенок";
    } else {
      return props.filter.children + " детей";
    }
  };

  let chartAdult = (
    <TimeChart
      more={true}
      adults={props.filter.adults}
      pickHandler={(ad, ch) => {
        setPickerVisibleAdults(false);
        props.setFilter({
          adults: parseInt(ad),
        });
        props.setFilter({
          children: parseInt(ch),
        });
      }}
      hideHandler={() => setPickerVisibleAdults(!pickerVisibleAdults)}
      children={props.filter.children}
    />
  );
  let main = false;
  let product = true;
  let classes = [style["moreWrapper"]];
  if (product) {
    classes.push(style.productAdults);
  }

  //timePicker
  let time = props.filter.time.split(":");
  const hour = time[0];
  const minutes = time[1];
  let defaultValueTime = props.filter.time;
  let chartTime = (
    <TimeChart
      hour={hour}
      minutes={minutes}
      steps={15}
      pickHandler={(h, m) => {
        setVisible(false);
        props.setFilter({ time: h + ":" + m });
      }}
      hideHandler={() => setVisible(!visible)}
    />
  );

  //geozone
  const setGeozone = (geozoneId) => {
    props.setFilter({ geozone: geozoneId });
  };

  const checkCurTranslate = () => {
    if (!!geozonesRef.current.style.transform) {
      const style = window.getComputedStyle(geozonesRef.current);
      const matrix = new DOMMatrix(style.transform);
      return matrix.m41;
    }
    return 0;
  };

  const moveGeozones = (direction) => {
    const curPosition = checkCurTranslate();
    const movementLength = 100;
    let newPosition;

    switch (direction) {
      case "back":
        newPosition = curPosition > 0 ? curPosition - movementLength : 0;
        geozonesRef.current.style.transform = `translate(${newPosition}px)`;
        break;
      case "forward":
        newPosition =
          curPosition < geozonesRef.current.clientWidth - movementLength * 4
            ? curPosition + movementLength
            : 0;
        geozonesRef.current.style.transform = `translate(${newPosition}px)`;
        break;
      default:
        break;
    }
  };

  const geozonesFromOuter = props.geozoneList
    ? props.geozoneList
    : props.catalog.geozoneList;

  const geozonesToRender = geozonesFromOuter
    ? geozonesFromOuter.map((zone) => (
        <li
          className={
            props.filter.geozone === zone.id
              ? `${css["geozones_item"]} ${css["geozones_item__selected"]}`
              : css["geozones_item"]
          }
          key={zone.id}
          onClick={() => setGeozone(zone.id)}
        >
          <img
            src={geozoneIcon}
            alt=""
            className={css["geozones_item-image"]}
          />
          <div className={css["geozones_item-text-container"]}>
            <div className={css["geozones_item-text"]}> {zone.name} </div>
            <label className={css["geozones_item-label"]}>
              {zone.district}
            </label>
          </div>
        </li>
      ))
    : [];

  const MAX_GEOZONES_WITHOUT_CAROUSEL = 3;

  return (
    <>
      <div className={`${css["filter"]} ut-theme-color`}>
        <Link
          className={util.join("ut-section__title", css["filter-title"])}
          to={categoryUrl}
        >
          {props.title}
        </Link>
        <div
          onClick={() => setPickerVisible(!pickerVisible)}
          className={css["filter-group"]}
        >
          <i
            className={util.join(
              "ut-ico-" + props.category.icon,
              css["filter-icon"]
            )}
          ></i>
          <div>
            <label className={css["filter-label"]}>Тип</label>
            <p className={style["input-category"]}>{defaultValue}</p>
            {pickerVisible ? chart : null}
          </div>
        </div>
        <div ref={fieldDateIn} className={css["filter-group"]}>
          <i
            className={util.join(
              "ut-ico-calendar2 ",
              css["filter-icon"],
              css["hideForClick"]
            )}
          ></i>
          <div className={css["hideForClick"]}>
            <label className={css["filter-label"]}>
              {filterConfig("field1_label")}
            </label>
            <input
              readOnly
              value={dateIn}
              name="hotel_date_in"
              className={css["filter-input"]}
              ref={fieldDateIn}
            />
          </div>
        </div>{" "}
        {filterConfig("singleMode") ? (
          <div
            onClick={() => setVisible(!visible)}
            className={css["filter-group"]}
          >
            <i className={"ut-ico-clock " + css["filter-icon"]}></i>

            <div>
              <label className={css["filter-label"]}>
                {filterConfig("field2_label")}
              </label>
              <input
                type="text"
                value={defaultValueTime}
                readOnly
                className={style["searchInput"]}
              />
            </div>
          </div>
        ) : (
          <div ref={fieldDateOut} className={css["filter-group"]}>
            <i
              className={util.join(
                "ut-ico-calendar1 ",
                css["filter-icon"],
                css["hideForClick"]
              )}
            ></i>
            <div className={css["hideForClick"]}>
              <label className={css["filter-label"]}>
                {filterConfig("field2_label")}
              </label>
              {dateOut_input}
            </div>
          </div>
        )}
        {visible ? chartTime : null}
        <div
          onClick={() => setPickerVisibleAdults(!pickerVisibleAdults)}
          className={css["filter-group"]}
        >
          <i className={"ut-ico-guest " + css["filter-icon"]}></i>
          <div className={css["wrappMore"]}>
            <label className={`${css["filter-label"]} ${css["label-more"]} `}>
              Гостей
            </label>
            <div className={classes.join(" ")}>
              <p
                className={
                  main ? style["picker-input"] : style["input-subfilter"]
                }
              >
                {props.filter.adults + sumAdults()}
              </p>
              <p
                onClick={() => setPickerVisibleAdults(!pickerVisibleAdults)}
                className={
                  main ? style["picker-input"] : style["input-subfilter"]
                }
              >
                {sumChild()}
              </p>
            </div>
          </div>
        </div>
        {pickerVisibleAdults ? chartAdult : null}
        <div onClick={toggleSort} className={css["filter-group"]}>
          <SortIcon style={{ color: grey[400] }} />
          <div>
            <label className={css["filter-label"]}>Сортировка</label>
            <p className={css["filter-text"]} onClick={toggleSort}>
              {props.sort === "ASC" ? "По возрастанию" : "По убыванию"}
            </p>
          </div>
        </div>
        {!props.hideButton && (
          <button
            className={util.join("ut-theme-color", css["filter-btn"])}
            onClick={props.refresh}
          >
            <span>Поиск</span>
          </button>
        )}
        {geozonesFromOuter ? (
          <div className={css["geozones"]}>
            {geozonesToRender.length > MAX_GEOZONES_WITHOUT_CAROUSEL ? (
              <img
                className={`${css["geozones_arrows"]} ${css["geozones_arrows__back"]}`}
                src={geozoneTriangle}
                alt=""
                onClick={() => moveGeozones("back")}
              />
            ) : null}
            <div className={css["geozones_carousel-container"]}>
              <ul ref={geozonesRef}>{geozonesToRender}</ul>
            </div>
            {geozonesToRender.length > MAX_GEOZONES_WITHOUT_CAROUSEL ? (
              <img
                className={`${css["geozones_arrows"]} ${css["geozones_arrows__forward"]}`}
                src={geozoneTriangle}
                alt=""
                onClick={() => moveGeozones("forward")}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  catalog: state.catalog,
  sort: state.user.customerData.sort,
  filter: state.user.customerData.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filter) => dispatch(actions.setFilter(filter)),
  setSort: (sort) => dispatch(actions.setSort(sort)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultsFilter);

