import React from "react";
import classes from "./HeaderSearch.module.scss";
import { useHistory } from "react-router-dom";
import * as routes from "../util/routes";

import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import headerSearchIcon from "../../assets/scss/ut-icons/search_icon.png";

const HeaderSearch = (props) => {
  const history = useHistory();

  const searchByKey = () => {
    let searchLink = "/";
    if (props.searchTerm.length > 0) {
      const categoryParam = props.category ? `${props.category.code}/` : "";
      searchLink =
        routes.SEARCH_PREFIX +
        categoryParam +
        decodeURIComponent(props.searchTerm);
    }
    return searchLink;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const link = searchByKey();
      history.push(link);
    }
  };

  const searchByClick = () => {
    const link = searchByKey();
    history.push(link);
  };

  const handleInput = (e) => {
    const value = e.target.value;
    props.setTerm(value);
  };

  const placeHolderText = props.activeSearchField
    ? "Введите название..."
    : "Поиск";

  return (
    <div className={classes["header-search"]}>
      <img
        className={classes["header-search_icon"]}
        src={headerSearchIcon}
        onClick={searchByClick}
        alt=""
      />
      <input
        onClick={() => props.setActiveSearchField(true)}
        className={classes["header-search_input"]}
        placeholder={placeHolderText}
        onKeyDown={handleKeyDown}
        onChange={handleInput}
      />
      <div className={classes["header-search_separator"]}>
        <div></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  category: state.user.customerData.category,
  filter: state.user.customerData.filter,
  searchTerm: state.user.customerData.searchTerm,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filter) => dispatch(actions.setFilter(filter)),
  setTerm: (filter) => dispatch(actions.setTerm(filter)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch);
