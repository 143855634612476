import React, { Component } from "react";
import css from "./Overlay.module.scss";

import util from "../../components/util/util";

const withOverlay = WrappedComponent => {
    return class extends Component {
        render = () => {
            return (
                <div>
                    <div className={css["container"]}>
                        <div className={css["mainContainer"]}>
                            <WrappedComponent {...this.props} />
                            <span
                                fontSize="large"
                                className={util.join(css["close"])}
                                onClick={this.props.hideHandler}
                            >
                                ✕
                            </span>
                        </div>
                        <div
                            onClick={this.props.hideHandler}
                            className={css["wrapperClick"]}
                        ></div>
                    </div>
                </div>
            );
        };
    };
};

export default withOverlay;
