import React, { useEffect, Fragment } from "react";
import css from "./styles/Video.module.scss";
import PropTypes from "prop-types";

const ref = React.createRef();
const MOBILE_MAX = 767;
const Video = props => {
    useEffect(() => {
        const video = ref.current;
        if (props.category) {
            video.pause();
        } else {
            video.play();
        }
    }, [props.category]);

    return (
        <div
            className={css["container"]}
            style={{ opacity: props.category ? 0 : 1 }}
        >
            <video loop ref={ref} muted={true} poster={props.poster}>
                {window.innerWidth > MOBILE_MAX ? (
                    <Fragment>
                        <source src={props.src} type="video/mp4" />
                        Your browser does not support the video tag.
                    </Fragment>
                ) : null}
            </video>
        </div>
    );
};

Video.defaultProps = {
    src: "",
    category: null,
    poster: ""
};

Video.propTypes = {
    src: PropTypes.string.isRequired,
    category: PropTypes.object,
    poster: PropTypes.string
};

export default Video;
