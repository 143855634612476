import React, { useState } from "react";
import PropTypes from "prop-types";
import util from "../util/util";
import css from "./styles/ButtonItem.module.scss";

function ButtonItem(props) {

	let classes = [css["hours-item"]];
	if (props.selected) {
		classes.push(css.active);
	}
	if (props.category) {
		classes.push(css.category);
	}

	return (
		<div
			className={classes.join(" ")}
			onClick={() => props.clickHandler(props.number)}
		>
			{props.number}
		</div>
	);
}

ButtonItem.propTypes = {};

export default ButtonItem;
