import React from "react";
import css from "./OrderTotals.module.scss";
import withOverlay from "../../hoc/Overlay/Overlay";
import { Button } from "../Interface/Interface";

const CheckBeforePayment = (props) => {
  return (
    <div className={css["check"]}>
      <div className={css["check-container"]}>
        <div className={css["check-caption"]}>
          <p>
            Если у вас остались пожелания к заказу, вы можете вернуться и
            оставить комментарий
          </p>
        </div>
        <div
          className={css["check-buttons-container"]}
          onClick={props.hideHandler}
        >
          <Button className={css["check-button"]}>
            <span className={css["check-button-first-word"]}>Оставить</span>{" "}
            комментарий
          </Button>
          <Button
            className={css["check-button"]}
            onClick={props.continuePayment}
          >
            <span className={css["check-button-first-word"]}>Продолжить</span>{" "}
            покупку
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withOverlay(CheckBeforePayment);
