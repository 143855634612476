import createCart from "../../model/Cart";

const config = {
	methodName: "GetCart",
	isTest: false,
	isDummy: false,
	method: "POST",
	dummyData: {
		partners: [
			{
				name: "Rixos Красная поляна",
				id: 1,
				image: "https://hotels.sletat.ru/i/im/86865_0_1024_569_1.jpg",
				items: [
					{
						name: "Делюкс номер",
						id: 0,
						text: "Большой просторный номер",
						params: [
							{
								name: "Дата заезда",
								value: "01-08-2018",
								required: true,
							},
							{
								name: "Дата выезда",
								value: "01-08-2018",
								required: true,
							},
							{
								name: "Взрослых",
								value: "2",
								required: true,
							},
							{
								name: "Детей",
								value: "1",
								required: true,
							},
							{
								name: "Фамилия гостя",
								value: "",
								required: true,
								edit: true,
							},
							{
								name: "Имя гостя",
								value: "",
								required: true,
								edit: true,
							},
							{
								name: "Email гостя",
								value: "",
								required: true,
								edit: true,
							},
						],
						quantity: 1,
						price: 20000,
					},
					{
						name: "Стандартный номер",
						id: 1,
						text: "Большой просторный номер",
						params: [
							{
								name: "Дата заезда",
								value: "01-08-2018",
								required: true,
							},
							{
								name: "Дата выезда",
								value: "01-08-2018",
								required: true,
							},
							{
								name: "Взрослых",
								value: "2",
								required: true,
							},
							{
								name: "Детей",
								value: "1",
								required: true,
							},
							{
								name: "Фамилия гостя",
								value: "",
								required: true,
								edit: true,
							},
							{
								name: "Имя гостя",
								value: "",
								required: true,
								edit: true,
							},
							{
								name: "Email гостя",
								value: "",
								required: true,
								edit: true,
							},
						],
						quantity: 1,
						price: 10000,
					},
				],
				total: 30000,
			},

			{
				name: "Мегазиплайн GorkyFly",
				id: 2,
				image:
					"https://funsochi.ru/sites/default/files/styles/watermark_fun/public/gorky_zipline_0.jpg",
				items: [
					{
						name: "Спуск",
						id: 0,
						text: "Захватывающее мероприятие",
						params: [
							{
								name: "Дата",
								value: "01-08-2018",
								required: true,
							},
							{
								name: "Категория",
								value: "Взрослый",
								required: true,
							},
						],
						quantity: 2,
						price: 2000,
					},
					{
						name: "Спуск",
						id: 1,
						text: "Захватывающее мероприятие",
						params: [
							{
								name: "Дата",
								value: "01-08-2018",
								required: true,
							},
							{
								name: "Категория",
								value: "Детский",
								required: true,
							},
						],
						quantity: 1,
						price: 1000,
					},
				],
				total: 5000,
			},
		],
		total: 35000,
	},

	prepareRequest: (p) => p,

	prepareResponse: (response) => createCart(response),
	handleError: (e) => e,
};

export default config;
