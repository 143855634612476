import React, { Component } from "react";
import { connect } from "react-redux";
import apiDev from "../components/API/API.dev";
import { Panel } from "../components/Interface/Interface";
import * as routes from "../components/util/routes";

import * as actions from "../store/actions/index";
import Wrapper from "../hoc/Wrapper/Wrapper";
import util from "../components/util/util";
import { Form, Input, Button } from "../components/Interface/Interface";
import css from "../components/UserForm/UserForm.module.scss";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

class LayoutRestore extends Component {
	componentDidMount() {
		//this.props.view();
	}
	state = {
		confirm: "",
		password: "",
		recovery: false,
		mailRecover: "",
		msg: "",
		allHiden: false,
		token: "",
	};
	// componentDidUpdate(prevProps, prevState) {
	//   if (this.props.auth.error) {
	//     const error = this.props.auth.error;
	//     this.props.reset();
	//     alert(error);
	//   }
	// }

	handleFieldChange = (e) => {
		this.getUrl();
		e.preventDefault();
		const name = e.target.getAttribute("name");
		const value = e.target.value;
		this.setState((state) => {
			const newState = { ...state };
			newState[name] = value;
			return newState;
		});
	};

	replaceHeader = () => {
		let header = "Авторизация";
		if (this.state.recovery) {
			header = "Восстановление пароля";
		}
		return header;
	};
	//for hide/show other button
	toggleRecovery = () => {
		this.setState({
			recovery: !this.state.recovery,
		});
	};
	sendRecover = (e) => {
		const params = {
			password: this.state.password,
			passwordConfirm: this.state.confirm,
			token: this.state.token,
		};

		apiDev("user/RecoverPassword", params).then((response) => {
			console.log(response);

			if (response.data.error) {
				const msg = response.data.msg;
				let check = "совпадают";
				let answer = msg.toLowerCase().indexOf(check.toLowerCase());
				if (answer > -1) {
					this.setState((state) => {
						const newState = {
							...state,
							msg: msg,
							recovery: false,
						};
						return newState;
					});
				} else {
					this.setState((state) => {
						const newState = {
							...state,
							msg: msg,
							recovery: true,
						};
						return newState;
					});
				}
			} else {
				const msg = response.data.msg;
				this.setState((state) => {
					const newState = { ...state, msg: msg, recovery: true };
					return newState;
				});
			}
		});

		// .catch(error => {
		//   // console.log(error.response);

		// });
	};
	getUrl = () => {
		let url = window.location.pathname;
		url = url.slice(9);
		this.setState((state) => {
			const newState = { ...state, token: url };
			return newState;
		});
	};
	render = () => {
		return (
			<section className="ut-section__container">
				<Panel>
					<div className="ut-form__container">
						<Wrapper>
							<h3 className={css["title"]}>
								{/* {this.replaceHeader()} */}
								Заполните поля ввода
							</h3>
							<p className={css["message"]}>{this.state.msg}</p>
							<Form
								className={util.join(
									this.state.allHiden ? css["input-hide"] : ""
								)}
							>
								<Input
									className={util.join(
										this.state.recovery
											? css["input-hide"]
											: ""
									)}
									onChange={this.handleFieldChange}
									name="password"
									placeholder="Ваш новый пароль"
									label="Введите Ваш пароль"
									type="text"
								/>
								<Input
									className={util.join(
										this.state.recovery
											? (css["inputLast"],
											  css["input-hide"])
											: css["inputLast"]
									)}
									name="confirm"
									onChange={this.handleFieldChange}
									label="Повторите пароль"
									type="password"
								/>

								<Button
									className={util.join(
										this.state.recovery
											? css["input-hide"]
											: css["inputLast"]
									)}
									onClick={(e) => {
										e.preventDefault();
										return this.sendRecover();
									}}
									// loading={this.props.auth.loading}
								>
									Отправить
								</Button>
								<Button
									className={util.join(
										this.state.recovery
											? ("ut-theme-color", css["toHome"])
											: css["input-hide"]
									)}
								>
									<Link
										to={"/"}
										className={util.join(
											"ut-theme-color",
											css["toHome"]
										)}
									>
										<span>Hа главную</span>
									</Link>
								</Button>
							</Form>
						</Wrapper>
						<Link to={"/"} className={css["item-remove"]}>
							<CloseIcon
								fontSize="large"
								className={css["close"]}
								// onClick={this.props.hideHandler}
							/>
						</Link>
					</div>
				</Panel>
			</section>
		);
	};
}

const mapStateToProps = (state) => ({
	user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
	login: (email, password) => dispatch(actions.login(email, password)),
	logout: () => dispatch(actions.logout()),
	view: () => dispatch(actions.view()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutRestore);
