import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import UserOrder from "./UserOrder/UserOrder";
import css from "./styles/Orders.module.scss";
import api from "../API/new/API";
import Order from "../../model/Order";
import util from "../util/util";

class UserOrders extends Component {
	state = {
		orders: [],
		number: false,
		datePay: false,
		date: false,
		price: false,
		status: false,
		sortFlag: true,
	};

	componentDidMount = () => {
		this.makeOrders();
	};

	formHideHandler = () => {
		this.setState((state) => ({ ...state, customerFormVisible: false }));
	};
	makeOrders = () => {
		api.user
			.Orders()
			.then((response) => {
				let orders = [];
				if (response.data && !response.data.error) {
					orders = this.state.orders.concat(response.data);
				}
				this.setState(
					(state) => ({
						...state,
						orders,
					}),
					this.sortByStatusCode
				);
			})
			.catch((err) => {
				alert(err);
			});
	};

	fillOrderProducts = () => {
		let order = this.state.orders.map((item, index) => {
			return (
				<UserOrder
					emergency={this.props.emergency}
					key={index}
					item={item}
				/>
			);
		});
		return order;
	};
	sortId = () => {
		const idSorted = new Order(this.state.orders).sortId(
			this.state.sortFlag
		);
		let sortFlag = !this.state.sortFlag;
		this.setState((state) => ({
			...state,
			number: true,
			price: false,
			datePay: false,
			date: false,
			status: false,
			orders: idSorted,
			sortFlag: sortFlag,
		}));
	};
	sortPrice = () => {
		let priceSorted = new Order(this.state.orders).sortPrice(
			this.state.sortFlag
		);
		let sortFlag = !this.state.sortFlag;
		this.setState((state) => ({
			...state,
			price: true,
			number: false,
			datePay: false,
			date: false,
			status: false,
			orders: priceSorted,
			sortFlag: sortFlag,
		}));
	};

	sortByStatusCode = () => {
		let byCode = new Order(
			this.state.orders,
			this.state.sortFlag
		).sortByStatusCode();
		let sortFlag = !this.state.sortFlag;
		this.setState((state) => ({
			...state,
			status: true,
			price: false,
			number: false,
			datePay: false,
			date: false,
			orders: byCode,
			sortFlag: sortFlag,
		}));
	};
	sortByDate = () => {
		let byDate = new Order(this.state.orders).sortByDate(
			this.state.sortFlag
		);
		let sortFlag = !this.state.sortFlag;
		this.setState((state) => ({
			...state,
			status: false,
			price: false,
			number: false,
			datePay: false,
			date: true,
			orders: byDate,
			sortFlag: sortFlag,
		}));
	};
	sortByPayDate = () => {
		let byPayDate = new Order(this.state.orders).sortByPayDate(
			this.state.sortFlag
		);
		let sortFlag = !this.state.sortFlag;
		this.setState((state) => ({
			...state,
			status: false,
			price: false,
			number: false,
			datePay: false,
			date: false,
			datePay: true,
			orders: byPayDate,
			sortFlag: sortFlag,
		}));
	};
	render = () => {
		return (
			<div className="ut-section__container">
				<h2 className="ut-section__title">Мои покупки </h2>
				<div className={css["purchases"]}>
					{" "}
					{this.state.orders.length > 0 ? (
						<div className={css["purchases-history"]}>
							<h3 className={css["purchases-head"]}>
								История заказов
							</h3>
							<div
								className={util.join(
									css["purchases-row"],
									css["purchases-header"]
								)}
							>
								<div
									className={util.join(
										css["purchases-data"],
										css["purchases-header"]
									)}
									onClick={this.sortId}
								>
									<div
										className={util.join(
											css["data"],
											`${
												this.state.number
													? `${
															this.state.sortFlag
																? css[
																		"data--triangle"
																  ]
																: css[
																		"data--triangle-reverse"
																  ]
													  }`
													: ""
											}`
										)}
									>
										Номер заказа
									</div>
								</div>
								<div
									className={util.join(
										css["purchases-data"],
										css["purchases-header"],
										css["purchases-data--hide-field"]
									)}
									onClick={this.sortByDate}
								>
									<div
										className={util.join(
											css["header-field"],
											`${
												this.state.date
													? `${
															this.state.sortFlag
																? css[
																		"data--triangle"
																  ]
																: css[
																		"data--triangle-reverse"
																  ]
													  }`
													: ""
											}`
										)}
									>
										дата заказа
									</div>
								</div>
								<div
									className={util.join(
										css["purchases-data"],
										css["purchases-header"]
									)}
									onClick={this.sortByPayDate}
								>
									<div
										className={util.join(
											css["header-field"],
											`${
												this.state.datePay
													? `${
															this.state.sortFlag
																? css[
																		"data--triangle"
																  ]
																: css[
																		"data--triangle-reverse"
																  ]
													  }`
													: ""
											}`
										)}
									>
										дата оплаты
									</div>
								</div>
								<div
									className={util.join(
										css["purchases-data"],
										css["purchases-header"],
										css["purchases-data--hide-field"]
									)}
									onClick={this.sortByStatusCode}
								>
									<div
										className={util.join(
											css["header-field"],
											`${
												this.state.status
													? `${
															this.state.sortFlag
																? css[
																		"data--triangle"
																  ]
																: css[
																		"data--triangle-reverse"
																  ]
													  }`
													: ""
											}`
										)}
									>
										статус
									</div>
								</div>
								<div
									className={util.join(
										css["purchases-data"],
										css["purchases-header"]
									)}
									onClick={this.sortPrice}
								>
									<div
										className={util.join(
											css["header-field"],
											`${
												this.state.price
													? `${
															this.state.sortFlag
																? css[
																		"data--triangle"
																  ]
																: css[
																		"data--triangle-reverse"
																  ]
													  }`
													: ""
											}`
										)}
									>
										сумма
									</div>
								</div>
								<div
									className={util.join(
										css["purchases-data"],
										css["purchases-header"]
									)}
								></div>
							</div>
							{this.fillOrderProducts()}
						</div>
					) : (
						"У вас пока не было заказов"
					)}
				</div>
			</div>
		);
	};
}

const mapStateToProps = (state) => ({
	user: state.user,
	cart: state.user.cart,
});

const mapDispatchToProps = (dispatch) => ({
	getCart: () => dispatch(actions.getCart()),
	removeFromCart: (items) => dispatch(actions.removeFromCart(items)),
	register: (formData) => dispatch(actions.register(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserOrders);
