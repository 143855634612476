const config = {
    methodName: "GetCountriesList",
    isTest: false,
    isDummy: false,
    method: "get",
    dummyData: {},

    prepareRequest: p => p,

    prepareResponse: r => r,

    handleError: e => e
};

export default config;
