import util from "../../components/util/util";
const config = {
	methodName: "GetReviews",
	isTest: false,
	isDummy: false,
	method: "POST",
	dummyData: {},

	prepareRequest: (p) => p,

	prepareResponse: (r) => {
		if (!Array.isArray(r)) return r;
		const result = r.map((item) => ({
			...item,
			userPhoto: util.prependHostname(item.userPhoto),
		}));
		return result;
	},

	handleError: (e) => e,
};

export default config;
