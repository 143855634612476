import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/index";
import util from "../../components/util/util";

const mapDispatchToProps = dispatch => ({
  addToCart: item => dispatch(actions.addToCart(item)),
  setFilter: filter => dispatch(actions.setFilter(filter)),
  setCategory: category => dispatch(actions.setCategory(category))
  // setOrders: orders => dispatch(actions.getOrders(orders))
});

const withDispatcher = wrappedComponent => {
  return connect(null, mapDispatchToProps)(wrappedComponent);
};

export default withDispatcher;
