import User from "../../model/User";

const config = {
	methodName: "Update",
	isTest: false,
	isDummy: false,
	method: "POST",

	dummyData: {},
	prepareRequest: (r) => {
		const request = { ...r };

		// Removing password fields if they are empty strings
		if (
			r.hasOwnProperty("password") &&
			r.hasOwnProperty("passwordConfirm")
		) {
			if (r.password.length < 1 && r.passwordConfirm.length < 1) {
				delete request.password;
				delete request.passwordConfirm;
			}
		}

		// Removing email for it not to be changed
		if (r.hasOwnProperty("email")) {
			delete request.email;
		}

		return request;
	},
	prepareResponse: (r) => {
		if (r.error) return r;
		return new User(r);
	},
	handleError: (e) => e,
};

export default config;
