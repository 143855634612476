import React, { Component } from "react";
import { connect } from "react-redux";
import apiDev from "../components/API/API.dev";
import { Link } from "react-router-dom";
import util from "../components/util/util";
import cssCommon from "./styles/LayoutInfo.module.scss";
import css from "./styles/LayoutNews.module.scss";
import articleArr from "../assets/image/article-icon.svg";

export class LayoutNews extends Component {
  state = {
    articles: null,
    tag: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { tag } = this.props.match.params;

    let articlesToState = [];
    if (tag) {
      const params = { term: tag };
      apiDev("news/SearchArticles", params)
        .then((response) => {
          if (
            response.data &&
            Array.isArray(response.data) &&
            response.data.length > 0
          ) {
            articlesToState = response.data;

            this.setState((state) => ({
              ...state,
              tag: tag,
              articles: articlesToState,
            }));
          }
        })
        .catch(() => alert("Ошибка загрузки новости"));
    }
  }

  removeTagHandler() {
    this.setState((state) => ({
      ...state,
      tag: null,
      articles: null,
    }));
  }

  render() {
    let articlesToRender =
      this.state.articles && this.state.articles.length > 0 ? (
        this.state.articles
      ) : this.props.articles && this.props.articles.length > 0 ? (
        this.props.articles
      ) : (
        <p>Нет новостей</p>
      );

    const tagEl = this.state.tag ? (
      <div className={css["news-title-tag"]}>
        <span>{this.state.tag}</span>&nbsp;&nbsp;
        <Link
          to="/news"
          title="Показать все новости"
          className={css["news-title-tag-cross"]}
          onClick={this.removeTagHandler.bind(this)}
        >
          <span>&#10005;</span>
        </Link>{" "}
      </div>
    ) : null;

    const renderEl =
      articlesToRender.length > 0 ? (
        <div className={cssCommon["container"]}>
          <div className={css["news-title-container"]}>
            <Link
              to="/news"
              className={`${cssCommon["text-title"]} ${css["news-title-top"]}`}
              onClick={this.removeTagHandler.bind(this)}
            >
              Новости
            </Link>
            {tagEl}
          </div>
          {articlesToRender.map((article) => (
            <Link key={article.id} to={`/news/${article.id}`}>
              <div className={css["news-container"]}>
                <div
                  style={{ backgroundImage: `url(${article.image})` }}
                  className={css["news-image"]}
                ></div>
                <div className={css["news-all-data"]}>
                  <div style={{ marginBottom: 18 }}>
                    <div className={css["news-title"]}>{article.title}</div>
                    <div className={css["news-info"]}>
                      {util.getHTML(article.preview_text)}
                    </div>
                  </div>
                  <div className={css["news-all-data-bottom"]}>
                    <div className={css["news-created-at"]}>
                      {article.created_at.split(" ")[0]}
                    </div>
                    <div className={css["news-button"]}>
                      <button>
                        Читать
                        <span>
                          <img src={articleArr} alt="" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div>Новостей нет</div>
      );

    return <React.Fragment>{renderEl}</React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  articles: state.catalog.articles,
});

export default connect(mapStateToProps)(LayoutNews);
