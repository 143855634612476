/**
 *
 * API connection config
 * On new installation, copy with name API.config.js
 * and provide proper endpoint address connection
 *
 */


const rootUrl = window.location.protocol + "//" + window.location.hostname;

const development = {
    api_url: "https://dev.uniqtrip.ru/rest/",
    web_root: "https://dev.uniqtrip.ru"
};
const production = {
    api_url: "/rest/",
    web_root: rootUrl
};
const test = {
    ...development
};
const _default = {
    ...development
};

let config = { ..._default };

switch (process.env.NODE_ENV) {
    case "development":
        config = { ...development };
        break;
    case "test":
        config = { ...test };
        break;
    case "production":
        config = { ...production };
        break;
}

export default config;
