const config = {
  methodName: "GetFavourites",
  isTest: false,
  isDummy: false,
  method: "POST",
  dummyData: {},
  prepareRequest: r => r,
  prepareResponse: r => r,
  handleError: e => e
};

export default config;
