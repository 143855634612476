import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ArticleItem from "./_ArticleItem";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

/**
 * Mobile Detect
 */

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 770 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 769, min: 400 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 399, min: 0 },
    items: 1,
  },
};

export class ArticleSlider extends Component {
  render() {
    const items =
      this.props.articles && this.props.articles.length > 0
        ? this.props.articles.map((item) => (
            <ArticleItem key={item.id} item={item} />
          ))
        : [];

    if (items.length === 0) return null;

    return (
      <section>
        <div className="ut-section__container ut-theme-color">
          <Link to="/news" className="ut-section__title">
            Новости
          </Link>
          <div style={{ marginTop: 33 }}>
            <Carousel responsive={responsive}>{items}</Carousel>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  articles: state.catalog.articles,
});

export default connect(mapStateToProps)(ArticleSlider);
