import React, { useState } from "react";
import css from "./OrderTotals.module.scss";
import util from "../util/util";
import CheckBeforePayment from "./_OrderCheckBeforePayment";
import FinishPaymentMsg from "./_FinishPaymentMsg";

const OrderTotals = (props) => {
  const [checkVisible, setCheckVisible] = useState(false);
  const [isFinishVisible, setFinishVisible] = useState(false);

  const changeSetVisible = () => setCheckVisible(true);

  const checkBeforePayment = (
    <CheckBeforePayment
      hideHandler={() => setCheckVisible(false)}
      continuePayment={() => setFinishVisible(true)}
    />
  );

  const finishMsg = (
    <FinishPaymentMsg
      hideHandler={() => {
        setFinishVisible(false);
        setCheckVisible(false);
      }}
    />
  );

  const partners = props.partners.map((partner, partnerIndex) => (
    <li className={css["partner"]} key={partnerIndex}>
      <div className={css["partner-name"]}>{partner.name}</div>
      <ul>
        {partner.items.map((item, itemIdx) => (
          <li key={itemIdx} className={css["item"]}>
            <p className={css["item-name"]}>{item.name}</p>
            <span className={css["item-quantity"]}> x {item.quantity}</span>
            <p className={css["item-price"]}>
              {util.currencyFormat(item.total)}
            </p>
          </li>
        ))}
      </ul>
    </li>
  ));

  return props.total > 0 ? (
    <div className={util.join(css.container, "ut-theme-color")}>
      {checkVisible ? checkBeforePayment : null}
      {isFinishVisible && finishMsg}
      <h5 className={css["title"]}>Cписок приключений готов к оплате</h5>
      <ul>{partners}</ul>
      <div className={css["total"]}>
        <p className={css["total-caption"]}>Итого</p>
        <p className={css["total-price"]}>
          {util.currencyFormat(props.total)}
          <span className={css["total-hint"]}>*сумма без НДС</span>
        </p>
      </div>
      {props.emergency ? (
        <p className={css["emergencyText"]}>{props.emergencyText}</p>
      ) : (
        <button
          data-for="emergency"
          onClick={changeSetVisible}
          className="ut-form__button-inverse ut-form__button-wide"
          // disabled={props.emergency}
        >
          {" "}
          <span>Оплатить</span>{" "}
        </button>
      )}
      <div className={css["total-taxes"]}>
        *НДС не облагается на основании статей 346.12 и 346.13 главы 26.2 НК РФ.
      </div>
    </div>
  ) : null;
};

export default OrderTotals;

