import React, { Fragment, useState, useEffect } from "react";
import css from "./SearchResults.module.scss";
import util from "../util/util";
import { Link } from "react-router-dom";
import Label from "../Label/Label";

const SearchResultsMapItem = (props) => {
	const gridSize = () => {
		const SMALL = 399;
		const MED = 767;
		const GRID = {
			small: 1,
			med: 2,
			large: 3,
		};
		return window.innerWidth <= SMALL
			? GRID.small
			: window.innerWidth <= MED
			? GRID.med
			: GRID.large;
	};

	const [balloonOn, toggleBalloon] = useState(false);
	const [showDetail, toggleshowDetail] = useState(false);

	const labels = [];

	useEffect(() => {
		if (props.hoverId == props.data.id) {
			toggleBalloon(true);
		} else {
			toggleBalloon(false);
		}
	});
	const change = (e) => {
		props.getHover(props.data.id);
		// toggleshowDetail(e);
	};
	const changeShowDetail = (e) => {
		// props.getHover(props.data.id);
		toggleshowDetail(e);
	};

	if (util.getPropertySafe(props, "data.params.stars")) {
		labels.push(
			<li key={"stars"}>
				<Label>
					{props.data.params.stars} <i className="ut-ico-star"></i>
				</Label>
			</li>
		);
	}

	if (util.getPropertySafe(props, "data.params.rating")) {
		labels.push(
			<li key={"rating"}>
				<Label>{props.data.params.rating}</Label>
			</li>
		);
	}

	const closedDetails = (
		<div
			onClick={() => {
				changeShowDetail(true);
			}}
			onMouseLeave={() => changeShowDetail(false)}
			className={util.join(
				`${balloonOn ? css["itemHoverBaloon"] : css["map-item"]}`,
				"ut-theme-color"
			)}
		>
			<div
				className={css["closedDetails--absolute-wrapper"]}
				onClick={() => {
					changeShowDetail(true);
				}}
				onMouseEnter={() => {
					changeShowDetail(true);
				}}
			></div>
			{props.children}
		</div>
	);
	const openedDetails = (
		<div
			onMouseLeave={() => changeShowDetail(false)}
			onClick={() => changeShowDetail(false)}
			className={css["item--open"]}
		>
			<div
				className={util.join(css["itemHoverBaloon"], "ut-theme-color")}
			>
				<div
					className={css["closedDetails--absolute-wrapper"]}
					onClick={() => {
						changeShowDetail(false);
					}}
				></div>
				{props.children}
			</div>
			<Link
				to={`/catalog/${props.data.category.code}/${props.data.code}`}
			>
				<div className={css["map-balloon"]}>
					{labels.length > 0 ? <ul>{labels}</ul> : null}
					<Link
						to={`/catalog/${props.data.category.code}/${props.data.code}`}
					>
						<img src={props.data.params.photos[0].small} />
						<p className={css["mapNameProduct"]}>
							{props.data.name}
						</p>
					</Link>
				</div>
			</Link>
		</div>
	);
	let size = gridSize();

	const show = showDetail && size > 2 ? openedDetails : closedDetails;
	return <Fragment>{show}</Fragment>;
};

export default SearchResultsMapItem;
