import React, { Fragment } from "react";
import PropTypes from "prop-types";
import css from "./OrderPayment.module.scss";
import util from "../util/util";

const OrderPayment = props => {
    const result = props.match.params.result;

    return (
        <div className={util.join("ut-section__container", css["container"])}>
            {result == "success" ? (
                <Fragment>
                    <h1 className={css["title"]}>Спасибо!</h1>
                    <p className={css["text"]}>Вы успешно оплатили заказ!</p>
                </Fragment>
            ) : (
                <Fragment>
                    <h1 className={css["title"]}>Нам очень жаль!</h1>
                    <p className={css["text"]}>
                        Произошла ошибка при оплате заказа.
                        <br />
                        Подробности Вы можете уточнить по номеру горячей линии.
                    </p>
                </Fragment>
            )}
        </div>
    );
};

OrderPayment.defaultProps = {
    result: "failure"
};
OrderPayment.propTypes = {
    result: PropTypes.string.isRequired
};

export default OrderPayment;
