import React,{Component} from 'react';
import axios from 'axios';
import UserForm from '../UserForm/UserForm';
import CategorySlider from '../CategorySlider/CategorySlider';
import api from '../API/new/API';
import Reviews from '../Reviews/Reviews';
import util from '../util/util';
import Dispatcher from '../../store/dispatcher/Dispatcher';


const Test = (props) => { 
         props.addToCart();
        return ( 
        <div className="ut-section__container">
            
        </div>
        );
}

 
 
export default Dispatcher(Test);