import React, { Fragment } from "react";
import css from "./styles/Reviews.module.scss";
import PropTypes from "prop-types";
import util from "../util/util";
import Label from "../Label/Label";
import ReviewsTotal from "./_ReviewsTotal";
import { Button } from "../Interface/Button";
import ReviewComposeForm from "./_ReviewComposeForm";

const Reviews = (props) => {
  const postButton = (
    <button
      disabled={props.postedReview}
      className={util.join(css.button, css["button-post"])}
      onClick={props.postBtnClick}
    >
      <span>{props.postedReview ? "Отзыв отправлен" : "Написать отзыв"}</span>
    </button>
  );

  const moreButton = props.hasNext ? (
    <Button
      disabled={props.loading.items}
      onClick={props.getNext}
      className={util.join(css.button, css["button-more"])}
      loading={props.loading.items}
      size={16}
    >
      Еще отзывы
    </Button>
  ) : null;

  const totalsSection = props.totals ? (
    <ReviewsTotal {...props.totals} />
  ) : null;

  const reviewItems = props.items.map((item, index) => (
    <li className={css["item"]} key={index}>
      <div>
        <img
          src={item.userPhoto}
          className={css["item-img"]}
		  onError={util.addDummyImage}
		  alt=""
        />
      </div>
      <div>
        <p className={css["item-name"]}>{item.userName}</p>
        <p className={css["item-title"]}>
          <Label>{item.reviewAvgRating}</Label>&nbsp;
          {item.reviewTitle}
        </p>
        <p className={css["item-text"]}>{item.reviewText}</p>
      </div>
    </li>
  ));

  const composeFormProps = {
    closeHandler: () => props.toggleComposeMode(false),
    fields: props.fields,
    submitHandler: props.composeFormSubmitHandler,
    loading: props.loading.review,
  };

  return (
    <div className={css["container"]}>
      {props.totals && props.totals.totalReviews === 0 ? (
        props.composeMode ? (
          <ReviewComposeForm {...composeFormProps} />
        ) : (
          <Fragment>
            <div className={css.empty}>
              <p className={css["empty-text"]}>
                В данный момент отзывов нет. Ваш может стать первым!
              </p>
              <div className="ut-theme-color">{postButton}</div>
            </div>
          </Fragment>
        )
      ) : (
        <div className={css["flex"]}>
          {totalsSection}

          {props.composeMode ? (
            <ReviewComposeForm {...composeFormProps} />
          ) : (
            <div className={css["items-col"]}>
              <ul>{reviewItems}</ul>
              <div className="ut-theme-color">
                {moreButton}
                {postButton}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Reviews.propTypes = {
  /* partnerId: PropTypes.string.isRequired,
	categoryId: PropTypes.string.isRequired,
	isLogged: PropTypes.bool.isRequired,
	passReview: PropTypes.func, */
};

Reviews.defaultProps = {
  /* partnerId: 0,
	categoryId: 0,
	isLogged: false, */
};

export default Reviews;
