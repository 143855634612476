import React, { Component } from "react";
import LayoutHome from "./layouts/LayoutHome";
import LayoutSearch from "./layouts/LayoutSearch";
import LayoutProduct from "./layouts/LayoutProduct";
import LayoutCart from "./layouts/LayoutCart";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import catalogReducer from "./store/reducer/catalog";
import userReducer from "./store/reducer/user";
import { composeWithDevTools } from "redux-devtools-extension";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LayoutCommon from "./layouts/LayoutCommon";
import thunk from "redux-thunk";
import Test from "./components/Test/Test";
import Profile from "./layouts/LayoutProfile";
import ThemeControls from "./layouts/ThemeControls";
import LayoutCategory from "./layouts/LayoutCategory";
import LayoutInfo from "./layouts/LayouInfo";
import OrderPayment from "./components/OrderPayment/OrderPayment";
import LayoutRestore from "./layouts/LayoutRestore";
import LayoutArticle from "./layouts/LayoutArticle";
import LayoutNews from "./layouts/LayoutNews";
import Page404 from "./components/404/page404";
import Refund from "./components/Refund/Refund";

const reducer = combineReducers({
  catalog: catalogReducer,
  user: userReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeControls />
        <Router>
          <LayoutCommon>
            <Switch>
              <Route path="/" component={LayoutHome} exact />
              <Route
                path="/search/:category/:term"
                component={LayoutSearch}
                exact
              />
              <Route path="/search/:term" component={LayoutSearch} exact />
              <Route path="/category/:category" component={LayoutCategory} />
              <Route
                path="/catalog/:category/:code"
                component={LayoutProduct}
              />
              <Route path="/restore" component={LayoutRestore} />
              <Route path="/cart" exact component={LayoutCart} />
              <Route path="/info" exact component={LayoutInfo} />
              <Route path="/info/refund" exact component={Refund} />
              <Route path="/test" exact component={Test} />
              <Route path="/profile" exact component={Profile} />
              <Route path="/payment/:result" component={OrderPayment} />
              <Route path="/news/tags/:tag" component={LayoutNews} />
              <Route path="/news/:id" component={LayoutArticle} />
              <Route path="/news" component={LayoutNews} exact />
              <Route component={Page404} />
            </Switch>
          </LayoutCommon>{" "}
        </Router>{" "}
      </Provider>
    );
  }
}

export default App;

