import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import CartItem from "./_CartItem";
import CartItemAcc from "./_CartItem_Acc";
import CartItemOther from "./_CartItem_Other";
import css from "./Cart.module.scss";
import CategoriesModel from "../../model/Categories";
import { Button } from "../Interface/Interface";
import * as actions from "../../store/actions/index";
import util from "../util/util";

const Cart = (props) => {
  const COMMENT_PLACEHOLDER = "Комментарий к заказу";

  const [cartComment, setCartComment] = useState("");

  useEffect(() => {
    let initialCartComment = "";
    if (props.cartComment) {
      initialCartComment = props.cartComment;
    }
    setCartComment(initialCartComment);
  }, []);

  const saveCartComment = (comment) => {
    if (comment !== props.cartComment) {
      props.addCartComment(comment);
      alert("Добавлен комментарий к заказу");
    }
  };

  const button = (
    <Button
      onClick={() => saveCartComment(cartComment)}
      className={css["product-button"]}
      children={"Сохранить"}
      loading={props.loading}
    ></Button>
  );

  const partners = props.partners.map((item, index) => {
    const partnerCategory = CategoriesModel.byId(
      item.categoryId,
      props.categories
    );

    // If we have no categories loaded yet
    if (!partnerCategory) {
      return <CartItem key={index} {...item} onRemove={props.removeHandler} />;
    }

    switch (partnerCategory.code) {
      case CategoriesModel.types.ACCOMMODATION:
        return <CartItemAcc profile={props.profile} key={index} {...item} />;
      default:
        return (
          <CartItemOther
            profile={props.profile}
            key={index}
            {...item}
            onRemove={props.removeHandler}
          />
        );
    }
  });

  const cartAllItems = (
    <>
      {partners}{" "}
      <div className={css["item"]}>
        <div className={css["product-total-comment"]}>
          <div
            className={util.join(
              css["product-param"],
              css["product-param-comment"]
            )}
          >
            <span className={css["product-param-caption"]}>
              {COMMENT_PLACEHOLDER}
            </span>
            <textarea
              onChange={(e) => setCartComment(e.target.value)}
              className={`${css["product-param-input"]} ${css["product-param-input-comment"]}`}
              rows="3"
              maxLength="400"
              placeholder={COMMENT_PLACEHOLDER}
              name={props.code}
              value={cartComment}
            ></textarea>
            {button}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className={css["container"]}>
      {partners.length > 0 ? cartAllItems : "Ваша корзина пуста"}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartComment: state.user.customerData.cartComment,
});

const mapDispatchToProps = (dispatch) => ({
  addCartComment: (comment) => dispatch(actions.addCartComment(comment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
