import User from "../../../../model/User";

const config = {
    methodName: "Login",
    isTest: false,
    isDummy: false,
    method: "POST",
    dummyData: {
        firstName: "Мария",
        lastName: "Петрова",
        gender: "f",
        image:
            "https://www.rd.com/wp-content/uploads/2017/09/01-shutterstock_476340928-Irina-Bg-1024x683.jpg",
        email: "mary@me.com",
        phone: "+79480010203",
        id: "004",
        social: {
            vk: "https://vk.com/id45281251",
            facebook: "https://www.facebook.com/petrova.maria1"
        }
    },
    prepareRequest: p => {
        return {
            email: p.email,
            password: p.password
        };
    },
    prepareResponse: r => {
        if (!r.error) {
            return new User(r);
        }

        return r;
    },
    handleError: e => e
};

export default config;
