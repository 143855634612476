import React, { Component } from "react";
import classes from "./Header.module.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import util from "../util/util";
import phoneIcon from "../../assets/scss/ut-icons/phone.svg";
import cartIcon from "../../assets/scss/ut-icons/cart.svg";
import personIcon from "../../assets/scss/ut-icons/person.svg";
import img from "../../assets/image/dummy_small.jpg";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import UserForm from "../UserForm/UserForm";
import HeaderSearch from "./_HeaderSearch";
import * as actions from "../../store/actions/index";
import * as routes from "../util/routes";
import apiDev from "../API/API.dev";
import geozoneIcon from "../../assets/scss/ut-icons/geozone_icon_for_main_screen.svg";
import geozoneCheckIcon from "../../assets/scss/ut-icons/geozone_check.svg";
import arrayMove from "array-move";
// emergency
import { EmergencyBanner } from "../Interface/Interface";

const GEOZONES_HEADER = "header";
const GEOZONES_SIDE_PANEL = "panel";

class Header extends Component {
  state = {
    formVisible: false,
    panelVisible: false,
    emergencyBannerVisible: false,
    geozonePickerType: GEOZONES_HEADER,
    emergencyText:
      "Ввиду нынешней ситуации, связанной с коронавирусом, мы приостанавливаем бронирование гостиниц и услуг.",
  };

  componentDidMount() {
    this.props.login();
    this.props.getCart();
    this.props.getCategories();
    this.props.getCountriesList();
    this.props.getBanners();
    this.props.getGeozoneList();
    this.props.getArticles();
    apiDev("settings/checkEmergencyStatus").then((resp) => {
      if (typeof resp.data == "object") {
        if (Object.entries(resp.data).length === 0) {
          this.setState({
            ...this.state,
            emergencyBannerVisible: false,
          });
        } else {
          this.setState({
            ...this.state,
            emergencyBannerVisible: true,
            emergencyText: resp.data.emergencyMessage,
          });
        }
      }
    });
  }

  formToggle = () => {
    this.setState((state) => ({
      ...state,
      formVisible: !state.formVisible,
    }));
  };

  toggleFormTimer = () => {
    setTimeout(
      () => this.setState({ ...this.state, formVisible: false }),
      3000
    );
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.isLogged && this.state.formVisible && !prevProps.isLogged) {
      this.toggleFormTimer();
    }
  };

  panelToggle = () => {
    this.setState((state) => ({
      ...state,
      panelVisible: !state.panelVisible,
    }));
  };
  setCategory = (category) => {
    if (category) {
      if (!this.props.category) {
        this.panelToggle();
        return this.props.setCategory(category);
      } else if (category.id !== this.props.category.id) {
        this.panelToggle();
        return this.props.setCategory(category);
      } else {
        this.panelToggle();
        return this.props.setCategory(null);
      }
    }
    this.panelToggle();
    return this.props.setCategory(null);
  };
  quantityProducts = () => {
    let quantity = 0;

    this.props.user.cart.partners.forEach((element) => {
      quantity += element.quantity;
    });
    return quantity;
  };
  hideHandlerBanner = () => {
    this.setState((state) => ({
      ...state,
      emergencyBannerVisible: false,
    }));
  };

  setActiveSearchField = (field) => {
    console.log(field);
  };

  changeGeozone = (geozoneId) => {
    this.props.setFilter({ geozone: geozoneId });
    this.handleGeozonePickerVisibility();
  };

  getGeozoneName = () => {
    if (this.props.catalog.geozoneList) {
      for (const zone of this.props.catalog.geozoneList) {
        if (zone.id === this.props.filter.geozone) {
          return zone.name;
        }
      }
    }
  };

  handleGeozonePickerVisibility = (type) =>
    this.setState(
      { ...this.state, geozonePickerType: type },
      this.props.setGeozonePickerVisible(!this.props.geozonePickerVisible)
    );

  sortGeozonesToRender = () => {
    const INDEX_FOR_PICKED_GEOZONE = 0;
    const pickedGeozoneIndex = this.props.catalog.geozoneList.findIndex(
      (zone) => this.props.filter.geozone === zone.id
    );

    const sortedGeozones = arrayMove(
      this.props.catalog.geozoneList,
      pickedGeozoneIndex,
      INDEX_FOR_PICKED_GEOZONE
    );

    return sortedGeozones;
  };

  renderGeozonePickerModal = (geozonesArr) => {
    const pickerTypeClassKey =
      this.state.geozonePickerType === GEOZONES_HEADER
        ? GEOZONES_HEADER
        : GEOZONES_SIDE_PANEL;

    const pickerTriangle =
      pickerTypeClassKey === GEOZONES_HEADER ? (
        <div className={classes["geozones-picker-modal-triangle"]} />
      ) : null;

    const geozonePicker =
      this.props.geozonePickerVisible && !this.props.activeSearchField ? (
        <div
          className={`${classes["geozone-picker-modal-container"]} ${
            classes[`geozone-picker-modal-${pickerTypeClassKey}`]
          }`}
        >
          {pickerTriangle}
          <div className={classes["geozone-picker-modal"]}>
            {geozonesArr.map((zone) => {
              const pickedIndicator = (
                <div
                  className={
                    zone.id === this.props.filter.geozone
                      ? `${classes["geozone-picked-indicator"]} ${classes["geozone-picked"]}`
                      : `${classes["geozone-picked-indicator"]} ${classes["geozone-unpicked"]}`
                  }
                >
                  <img src={geozoneCheckIcon} alt="" />
                </div>
              );

              return (
                <div
                  key={zone.id}
                  onClick={() => this.changeGeozone(zone.id)}
                  className={classes["geozone-to-pick"]}
                >
                  {pickedIndicator}
                  <div className={classes["geozone-to-pick-name-container"]}>
                    <div className={classes["geozone-name"]}>{zone.name}</div>
                    <label className={classes["geozone-label"]}>
                      {zone.district}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null;

    return geozonePicker;
  };

  render() {
    this.quantityProducts();
    const dayOfTheYear = util.moment().format("DDD");
    const geozoneName = this.getGeozoneName();
    const form = this.state.formVisible ? (
      <UserForm hideHandler={this.formToggle} />
    ) : null;

    const profileIcon = this.props.isLogged ? (
      <Link to={routes.PROFILE_PREFIX}>
        <img
          src={util.getPropertySafe(this.props.user, "profile.photo", img)}
          alt=""
        />
      </Link>
    ) : (
      <div
        title="Вход"
        className={classes["profileInEl"]}
        onClick={this.formToggle}
      >
        <img src={personIcon} alt="" />
        {/* <div className={classes["btnInn"]}>вход</div> */}
      </div>
      // <img src={profileImg} onClick={this.formToggle} />
    );

    const geozonesArrToRender = this.sortGeozonesToRender();

    const geozonePickerModal =
      this.renderGeozonePickerModal(geozonesArrToRender);

    const lefPanelProfileElem = this.props.isLogged ? (
      <Link to={routes.PROFILE_PREFIX} className={classes["profileInEl"]}>
        <img
          src={util.getPropertySafe(this.props.user, "profile.photo", img)}
          alt=""
          className={classes["left-panel-user-avatar"]}
        />
        <span>Профиль</span>
      </Link>
    ) : (
      <div className={classes["profileInEl"]} onClick={this.formToggle}>
        <img src={personIcon} alt="" />
        <span>Вход</span>
      </div>
    );

    const panelCategories = this.props.catalog.categories.map(
      (category, index) => {
        const checked =
          Boolean(this.props.category) &&
          this.props.category.id === category.id;
        const link = routes.CATEGORY_PREFIX + category.code;
        return (
          <Link
            key={index}
            className={util.join(
              "ut-theme-color",
              classes["panel-category"],
              checked && classes["panel-category--on"]
            )}
            to={link}
            onClick={this.panelToggle}
          >
            <div
              className={classes["panel-icon"]}
              style={{
                backgroundImage:
                  "url(" + util.prependHostname(category.icon) + ")",
              }}
            />
            <li key={index}>
              <span>{category.name}</span>
            </li>
          </Link>
        );
      }
    );

    const panel = (
      <div
        className={util.join(
          classes["panel"],
          this.state.panelVisible && classes["panel--on"]
        )}
        onClick={(e) => {
          if (e.target.classList.contains(classes["panel"])) {
            this.panelToggle();
            this.props.setGeozonePickerVisible(false);
          }
        }}
      >
        <div className={classes["panel-content"]}>
          <div className={classes["panel-categories-container"]}>
            <div
              className={util.join("ut-theme-color", classes["panel-category"])}
              onClick={() =>
                this.handleGeozonePickerVisibility(GEOZONES_SIDE_PANEL)
              }
            >
              <img
                src={geozoneIcon}
                alt=""
                className={classes["left-panel-img-icon"]}
              />
              <span>{geozoneName}</span>
            </div>
            <div className={classes["left-panel-separator-container"]}>
              <div className={classes["left-panel-separator"]} />
            </div>
            <ul className="ut-theme-color">{panelCategories}</ul>
            <div className={classes["left-panel-separator-container"]}>
              <div className={classes["left-panel-separator"]}></div>
            </div>
            <Link
              className={util.join("ut-theme-color", classes["panel-category"])}
              to="/info"
              onClick={this.panelToggle}
            >
              <div className={classes["left-panel-info-icon"]}>i</div>{" "}
              <span>Информация для клиентов</span>
            </Link>
            {/* <div className={classes["left-panel-separator-container"]}>
            <div className={classes["left-panel-separator"]}></div>
          </div> */}
            <a
              className={util.join("ut-theme-color", classes["panel-category"])}
              href="tel:+78622777042"
            >
              <img
                src={phoneIcon}
                alt=""
                className={classes["left-panel-img-icon"]}
              />
              <span>Позвонить по (862)-277-70-42</span>
            </a>
            <div className={classes["left-panel-separator-container"]}>
              <div className={classes["left-panel-separator"]}></div>
            </div>
            <div
              className={util.join("ut-theme-color", classes["panel-category"])}
            >
              <div className={classes["profile"]} onClick={this.panelToggle}>
                {lefPanelProfileElem}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <Wrapper>
        {panel}
        {form}
        {geozonePickerModal}
        <div
          className={`${
            this.state.emergencyBannerVisible
              ? classes["wrapper--open"]
              : classes["wrapper"]
          }`}
        >
          <div className={classes["wrapper-emergency"]}>
            <EmergencyBanner
              text={this.state.emergencyText}
              toggle={this.state.emergencyBannerVisible}
              hideHandler={this.hideHandlerBanner}
            />
            <header className={classes["header"]}>
              <div className={classes["logo"]}>
                <div
                  className={[classes["menu-hamburger"]]}
                  onClick={this.panelToggle}
                >
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <a
                  href="/"
                  className={[
                    classes["logo-link"],
                    (dayOfTheYear > 360 || dayOfTheYear < 14) &&
                      classes["logo--ny"],
                  ].join(" ")}
                >
                  <span>Uniq</span>Trip
                </a>
                <div className={classes["line-separator"]}>
                  <div></div>
                </div>
                <div className={classes["header-moto"]}>
                  конструктор уникального путешествия
                </div>
              </div>
              <nav className={classes["menu"]}>
                <ul>
                  {/*    <li className="v-desktop">
                  <a
                    className={[
                      classes["menu-link"],
                      "ut-theme-color",
                      "ut-brand"
                    ].join(" ")}
                    href="#club"
                  >
                    Uniq<span>Trip</span>Club
                  </a>
                </li> */}
                  {/* <li className={classes["header-app"]}>
                    <span>Наше приложение в AppStore и Google Play</span>
                    <a href="https://play.google.com/store/apps/details?id=com.uniqtrip.uniqtrip">
                      <AndroidIcon className={"ut-theme-color"} />
                    </a>
                    <a href="https://apps.apple.com/us/app/uniqtrip/id1499071482?l=ru&ls=1">
                      <AppleIcon className={"ut-theme-color"} />
                    </a>
                  </li>
                  <li className="v-desktop">
                    <a
                      className={[
                        classes["menu-link"],
                        classes["menu-link-simple"],
                      ].join(" ")}
                      href="https://uniqtrip.ru"
                      target="_blank"
                    >
                      Для Бизнеса
                    </a>
                  </li> */}
                  {/*                                 <li className="v-desktop">
                                    <a className={[classes["menu-link"],classes["menu-link-simple"]].join(' ')} href="#deferred">Рассрочка и Кредит</a>
                                </li> */}

                  {!this.props.activeSearchField ? (
                    <li>
                      <div
                        onClick={() =>
                          this.handleGeozonePickerVisibility(GEOZONES_HEADER)
                        }
                        className={classes["geozone-header-container"]}
                      >
                        <img src={geozoneIcon} alt="" />
                        <div
                          className={
                            this.props.geozonePickerVisible &&
                            this.state.geozonePickerType === GEOZONES_HEADER
                              ? `${classes["geozone-picked-name"]} ${classes["geozone-module-open"]}`
                              : classes["geozone-picked-name"]
                          }
                        >
                          {geozoneName}
                        </div>
                      </div>
                    </li>
                  ) : null}

                  <li>
                    <HeaderSearch
                      setActiveSearchField={this.props.setActiveSearchField}
                      activeSearchField={this.props.activeSearchField}
                    />
                  </li>
                  <li>
                    <Link
                      title="Информация для клиентов"
                      className={classes["info-shortcut"]}
                      to="/info"
                    >
                      i
                    </Link>
                  </li>
                  <li className={classes["line-separator"]}>
                    <div></div>
                  </li>
                  <li className={classes["v-desktop"]}>
                    <a
                      title="Позвонить нам по телефону (862)-277-70-42 "
                      className={[classes["phoneEl"]].join(" ")}
                      href="tel:+78622777042"
                    >
                      <img src={phoneIcon} alt="" />
                    </a>
                  </li>
                  <li className={classes["line-separator"]}>
                    <div></div>
                  </li>
                  <li>
                    <Link
                      title="Корзина"
                      to="/cart"
                      className={[classes["menu-link"]].join(" ")}
                    >
                      <div className={classes["cart-elem"]}>
                        <img src={cartIcon} alt="" />
                        {/* <ShoppingCartIcon size={24} /> */}
                        {this.props.user.cart.positions > 0 ? (
                          <sub>
                            <span>{this.quantityProducts()}</span>
                          </sub>
                        ) : null}
                      </div>
                    </Link>
                  </li>
                  <li className={classes["line-separator"]}>
                    <div></div>
                  </li>
                  <li className={classes["profile"]}>{profileIcon}</li>
                </ul>
              </nav>
            </header>
          </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  isLogged: state.user.isLogged,
  user: state.user,
  catalog: state.catalog,
  category: state.user.customerData.category,
  filter: state.user.customerData.filter,
});

const mapDispatchToProps = (dispatch) => ({
  login: () => dispatch(actions.login()),
  getCart: () => dispatch(actions.getCart()),
  getCategories: () => dispatch(actions.getCategories()),
  getBanners: () => dispatch(actions.getBanners()),
  getGeozoneList: () => dispatch(actions.getGeozoneList()),
  getCountriesList: () => dispatch(actions.getCountriesList()),
  getArticles: () => dispatch(actions.getArticles()),
  setFilter: (filter) => dispatch(actions.setFilter(filter)),
  setTerm: (term) => dispatch(actions.setTerm(term)),
  setCategory: (category) => dispatch(actions.setCategory(category)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

