import util from "../../../util/util"
import img from '../../../../assets/image/dummy_small.jpg';
import { createObject } from '../../../../model/User';
 

const config = {
    methodName:'Logout',
    isTest:false,
    isDummy:false,
    method:'GET',
    dummyData:{
    },
    prepareRequest: (r)=> r,
    prepareResponse: (r)=> r,
    handleError: (e) => e        
}

export default config; 


