import util from "../components/util/util";
import img from "../assets/image/dummy_small.jpg";

const responseObject = {
    partners: [],
    total: 0,
    positions: 0,
    shareData: ""
};

const partnerObject = {
    name: "",
    id: 0,
    image: "",
    items: [],
    total: 0,
    quantity: 0,
    productId: 0,
    categoryId: 0
};

const itemObject = {
    name: "",
    productId: 0,
    id: 0,
    text: "",
    params: "",
    total: 0,
    quantity: 0,
    price: 0,
};

const shareObject = {
    email: "",
    phone: "",
    adults: 1,
    dateOut: "",
    dateIn: "",
    children: 0,
    Fname: "",
    Lname: "",
    productId: 0,
    categoryId: 0,
    time: ""
};

const paramObject = {
    code: "",
    name: "",
    value: "",
    required: true,
    edit: false,
    title: ""
};

const createCart = response => {
    if (!Array.isArray(response)) {
        return cartFactory.createResponse({});
    }
    let total = 0;
    let totalForItem = 0;
    let numPositions = 0;
    const partners = {};
    const shareData = [];
    response.map(element => {
        //util.log("[E]", element);
        const mainPartnerId = element.partner[0].id;
        const categoryId = element.partner[0].categoryId;
        const partnerId = element.partner[0].id;
        if (!partners[partnerId]) {
            const _image = util.getPropertySafe(
                element,
                "partner.0.photo.0.small",
                img
            );
            const _partner = {
                ...element.partner[0],
                items: [],
                productId: mainPartnerId,
                categoryId: categoryId,
                image: _image
            };
            partners[partnerId] = cartFactory.createPartner(_partner);
            partners[partnerId].total = element.price * element.quantity;
            partners[partnerId].quantity = element.quantity;
        } else {
            partners[partnerId].total += element.price * element.quantity;
            partners[partnerId].quantity += element.quantity;
        }
        totalForItem = element.price * element.quantity
        total += element.price * element.quantity;
        numPositions++;
        
        let params = [];
        if (Array.isArray(element.props)) {
            params = element.props.map(prop => {
                const _prop = {
                    ...prop,
                    name: prop.title
                };
                return cartFactory.createParam(_prop);
            });
        }

        const _item = {
            ...element,
            total: totalForItem,
            productId: element.productid,
            params
        };

        const assocParams = util.arrayToAssoc(element.props, "code", "value");

        const _shareItem = {
            productId: element.productid,
            categoryId,
            dateIn: assocParams.dateIn,
            dateOut: assocParams.dateOut,
            time: assocParams.time,
            children: assocParams.children,
            adults: assocParams.adults
        };
        const item = cartFactory.createItem(_item);
        const shareItem = cartFactory.createShareItem(_shareItem);
        //util.log("[I]", item);
        partners[partnerId].items.push(item);
        for (let _i = 0; _i < element.quantity; _i++) {
            shareData.push(shareItem);
        }
    });

    //    util.log("[P]", partners);
    return cartFactory.createResponse({
        partners: Object.values(partners),
        total,
        positions: numPositions,
        shareData
    });
};

const cartFactory = {
    createResponse: response => util.mergeObjects(responseObject, response),
    createPartner: partner => util.mergeObjects(partnerObject, partner),
    createItem: item => util.mergeObjects(itemObject, item),
    createShareItem: item => util.mergeObjects(shareObject, item),
    createParam: param => util.mergeObjects(paramObject, param)
};

export default createCart;
