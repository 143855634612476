const config = {
	methodName: "AddReview",
	isTest: false,
	isDummy: false,
	method: "POST",
	dummyData: {},

	prepareRequest: (p) => p,

	prepareResponse: (r) => r,

	handleError: (e) => e,
};

export default config;
