const config = {
	methodName: "AddFavourite",
	isTest: false,
	isDummy: false,
	method: "POST",
	dummyData: {},
	prepareRequest: (r) => {
		delete r.addedOn;
		return { favElement: r };
	},
	prepareResponse: (r) => r,
	handleError: (e) => e,
};

export default config;
