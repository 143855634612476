const config = {
  methodName: "GetProductsByPartnerID",
  isTest: false,
  isDummy: false,
  method: "POST",
  dummyData: {},

  prepareRequest: (p) => {
    const params = {
      ...p,
      adults: p.adults.toString(),
      children: p.children.toString(),
    };
    return params;
  },

  prepareResponse: (r) => r,

  handleError: (e) => e,
};

export default config;
