import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loading from "../hoc/Loading/Loading";
import apiDev from "../components/API/API.dev";
import cssCommon from "./styles/LayoutInfo.module.scss";
import css from "./styles/LayoutArticle.module.scss";
import util from "../components/util/util";

export class LayoutArticle extends Component {
  state = {
    id: null,
    title: null,
    text: null,
    createdAt: null,
    image: null,
    tags: null,
    photoSourceName: null,
    newsSourceName: null,
    newsSourceUrl: null,
  };

  componentDidMount() {
    this.props.loading(true);
    window.scrollTo(0, 0);
    const { id } = this.props.match.params;
    const params = { article_id: id };
    apiDev("news/GetArticleById", params)
      .then((response) => {
        this.setState(
          (state) => ({
            ...state,
            id: response.data.id,
            title: response.data.title,
            text: response.data.text,
            createdAt: response.data.created_at,
            image: response.data.image,
            tags: response.data.tags,
            photoSourceName: response.data.photo_source_name,
            newsSourceName: response.data.news_source_name,
            newsSourceUrl: response.data.photo_source_url,
          }),
          this.props.loading
        );
      })
      .catch(() => alert("Ошибка загрузки новости"));
  }

  render() {
    if (!this.state.id) {
      return <div></div>;
    }

    const tagsArrEl = this.state.tags
      ? this.state.tags.split(",").map((tag) => (
          <Link
            to={`/news/tags/${tag}`}
            className={css["single-article-tag"]}
            key={tag}
          >
            {tag}
          </Link>
        ))
      : [];

    const newsSourceEl = this.state.newsSourceName ? (
      <div className={css["single-article-source-container"]}>
        <span className={css["single-article-source-caption"]}>Источник:</span>
        &nbsp;{" "}
        {this.state.newsSourceUrl ? (
          <a
            href={this.state.newsSourceUrl}
            className={css["single-article-source-info"]}
          >
            {this.state.newsSourceName}
          </a>
        ) : (
          <span className={css["single-article-source-info"]}>
            {this.state.newsSourceName}
          </span>
        )}
      </div>
    ) : null;

    return (
      <div className={cssCommon["container"]}>
        <div className={cssCommon["text"]}>
          <Link to="/news" className={css["single-article-category-container"]}>
            <span className={css["single-article-category-name"]}>Новости</span>
          </Link>
          <div className={css["single-article-title"]}>{this.state.title}</div>
          <div className={css["single-article-credentials"]}>
            <div>{this.state.createdAt.split(" ")[0]}</div>
          </div>
          <div className={css["single-article-image-container"]}>
            <div
              className={css["single-article-image"]}
              style={{
                background: `center/100% no-repeat url("${this.state.image}")`,
              }}
            ></div>
            <div className={css["single-article-photo-source"]}>
              {this.state.photoSourceName
                ? "Фото: " + this.state.photoSourceName
                : null}
            </div>
          </div>
          <div className={css["single-article-text"]}>
            {util.getHTML(this.state.text)}
          </div>
          <div style={{ marginTop: 21 }}>
            {newsSourceEl}
            <div style={{ display: "flex" }}>{tagsArrEl}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading(LayoutArticle);
