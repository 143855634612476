/**
 *
 * Refactored version
 *
 * General cart product layout
 *
 */

import React, { useState } from "react";
import css from "./Cart.module.scss";
import ClearIcon from "@material-ui/icons/Clear";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import ShareWidget from "../Share/ShareWidget";
import CartItemProduct from "./_CartItemProduct_Other";
import UserModel from "../../model/User";
import QuantityInput from "./_QuantityInput";
import util from "../util/util";

const CartItem = (props) => {
  const itemsSummary = props.items.map((item, index) => (
    <li key={index}>
      <div className={css["item-summary-info"]}>
        <div className={css["item-summary-info-other"]}>
          {item.name} x{" "}
          <QuantityInput
            quantityHandler={(quantity, callback) =>
              props.changeQuantity(item.id, quantity, callback)
            }
          >
            {item.quantity}
          </QuantityInput>
        </div>
        <div className={css["item-summary-info-price"]}>
          {util.currencyFormat(item.total)}
        </div>
      </div>
    </li>
  ));

  const removeHandler = (idItemToRemove) => {
    let itemsToRemove = idItemToRemove;
    if (idItemToRemove === "entire") {
      itemsToRemove = props.items.map((item) => item.id);
    }
    props.removeFromCart(itemsToRemove);
  };

  const [expandedAll, toggleExpandedAll] = useState(false);
  const [expandedComment, toggleExpandedComment] = useState(false);

  // Here we use complex key to ensure
  // that input fileds are updated properly
  // during saving and remove operations
  const now = Date.now();
  const products = props.items.map((product, productIndex) => {
    const updateHandler = (fields) => {
      const fieldsToValidate = Object.values(fields)
        .filter((field) => field.edit && field.value.length > 0)
        .map((field) => [field.value, field.code]);

      const validationResult = UserModel.validateFields(fieldsToValidate);
      if (!validationResult.result) {
        return alert(validationResult.errors.join("\n"));
      }
      const updatedItem = {
        comment: fields.comment ? fields.comment.value : null,
        email: fields.email ? fields.email.value : null,
        phone: fields.phone ? fields.phone.value : null,
        Fname: fields.Fname ? fields.Fname.value : null,
        Lname: fields.Lname ? fields.Lname.value : null,
        item: {
          ...product,
          props: [...product.params],
          categoryId: props.categoryId,
          productId: product.productId,
        },
      };
      return props.updateCartFields({ payload: [updatedItem] });
    };

    return (
      <CartItemProduct
        key={`${productIndex}_${product.id}_${now}`}
        {...product}
        expandedComment={expandedComment}
        expandedAll={expandedAll}
        updateHandler={updateHandler}
        removeHandler={removeHandler}
        profile={props.profile}
        loading={props.updateState.loading}
      />
    );
  });

  return (
    <div className={css["item"]}>
      <div className={css["item-main"]}>
        <ul className={util.join("ut-theme-color", css["item-icons"])}>
          <li
            className={`${css["item-icon"]} ${css["item-red"]}`}
            onClick={() => removeHandler("entire")}
          >
            <ClearIcon />
          </li>
          <li>
            <ShareWidget
              items={props.items}
              categoryId={props.categoryId}
              productId={props.productId}
            />
          </li>
        </ul>

        <img src={props.image} className={css["item-image"]} alt="" />
        <div className={css["item-data"]}>
          <h3 className={css["item-title"]}>{props.name}</h3>
          <ul className={css["item-summary"]}>{itemsSummary}</ul>
          <div className={css["item-toggle-contaner"]}>
            {/* Цена {util.currencyFormat(props.total)} */}
            <span className={util.join(css["item-toggle"], "ut-theme-color")}>
              <input type="checkbox" defaultChecked={expandedAll} />
              <label
                className={util.join(
                  expandedAll ? css["item-toggle-activeLabel"] : ""
                )}
                onClick={() => toggleExpandedAll(!expandedAll)}
              >
                {util.getExpandText("other", expandedAll)}
              </label>
            </span>
            {/* commentary */}
            <span className={util.join(css["item-toggle"], "ut-theme-color")}>
              <input type="checkbox" defaultChecked={expandedComment} />
              <label
                className={util.join(
                  expandedComment ? css["item-toggle-activeLabel"] : ""
                )}
                onClick={() => toggleExpandedComment(!expandedComment)}
              >
                {util.getExpandText()}
              </label>
            </span>
          </div>
        </div>
      </div>
      {/* <div
        className={
          expandedAll || expandedComment
            ? css["item-details--expanded"]
            : css["item-details"]
        }
      > */}
        {products}
      {/* </div> */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  favourites: state.user.favourites,
  updateState: state.user.updateState,
});

const mapDispatchToProps = (dispatch) => ({
  addFavourite: (itemData) => dispatch(actions.addFavourite(itemData)),
  removeFavourite: (itemData) => dispatch(actions.removeFavourite(itemData)),
  removeFromCart: (items) => dispatch(actions.removeFromCart(items)),
  updateCartFields: (data) => dispatch(actions.updateCartFields(data)),
  changeQuantity: (positionId, newQuantity, callback) =>
    dispatch(actions.changeQuantity(positionId, newQuantity, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
