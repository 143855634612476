import util from "../../../util/util";
import img from "../../../../assets/image/dummy_small.jpg";
import { createObject } from "../../../../model/User";
import api_config from "../../API.config";

const config = {
    methodName: "View",
    isTest: false,
    isDummy: false,
    method: "POST",
    dummyData: {},
    prepareRequest: r => r,
    prepareResponse: r => {
        const userObject = { ...r };
        util.log("A", userObject);
        if (userObject.photo && userObject.photo.indexOf("http") == -1) {
            userObject.photo = api_config.web_root + userObject.photo;
        }
        return userObject;
    },

    handleError: e => e
};

export default config;
