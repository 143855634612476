import React from "react";
import css from "../../Cart/Cart.module.scss";
import purchasesCss from "../styles/Orders.module.scss";
import PropTypes from "prop-types";
import util from "../../util/util";

const OrderItem = props => {
  const value = () => {
    let value = props.value;
    let code = props.code;
    if (code === "dateIn") {
      let test = props.value;
      value = test
        .split("-")
        .reverse()
        .join("-");
    } else if (code === "dateOut") {
      let test = props.value;
      value = test
        .split("-")
        .reverse()
        .join("-");
    }
    return value;
  };
  const title = () => {
    util.log(props.value == undefined);
    if (props.value == undefined) {
      return <p className={purchasesCss["childTitle"]}>{props.title}</p>;
    } else {
      return (
        <p className={purchasesCss["childTitle"]}>
          {props.title}
          <span>:</span>
        </p>
      );
    }
  };
  return props.value !== "" && props.value !== "0" ? (
    <div className={util.join(purchasesCss["childWrapper"])}>
      {title()}
      <p className={purchasesCss["childTitle"]}>
        <span className={purchasesCss["childTitle--light-weight"]}>{value()}</span>
      </p>
    </div>
  ) : null;
};

OrderItem.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string
};

export default OrderItem;
