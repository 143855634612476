import React, { Component } from "react";
import { Form, Input, Button } from "../Interface/Interface";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import apiDev from "../API/API.dev";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import css from "./UserForm.module.scss";
import util from "../util/util";
import UserModel, { validationRules } from "../../model/User";

class AuthForm extends Component {
    state = {
        email: "",
        password: "",
        recovery: false,
        mailRecover: "",
        msg: "",
        allHiden: false
    };

    handleFieldChange = e => {
        e.preventDefault();
        const name = e.target.getAttribute("name");
        const value = e.target.value;
        this.setState(state => {
            const newState = { ...state };
            newState[name] = value;
            return newState;
        });
    };
    handleRecoveryFieldChange = e => {
        e.preventDefault();
        const value = e.target.value;
        this.setState(state => {
            const newState = { ...state, mailRecover: value };
            return newState;
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.auth.error) {
            const error = this.props.auth.error;
            this.props.reset();
            alert(error);
        }
    }
    //login

    preValidateForm(fieldsToValidate) {
        const validationResult = UserModel.validateFields(fieldsToValidate);
        if (!validationResult.result) {
            alert(validationResult.errors.join("\n"));
        }
        return validationResult.result;
    }

    handleAuth = () => {
        const fieldsToValidate = [
            [this.state.email, validationRules.EMAIL],
            [this.state.password, validationRules.PASSWORD]
        ];
        if (!this.preValidateForm(fieldsToValidate)) {
            return;
        }
        this.props.login({ ...this.state });
    };

    replaceHeader = () => {
        let header = "Авторизация";
        if (this.state.recovery) {
            header = "Восстановление пароля";
        }
        return header;
    };
    //for hide/show other button
    toggleRecovery = () => {
        this.setState({
            recovery: !this.state.recovery
        });
    };
    //send recover data

    sendRecover = e => {
        const params = {
            email: this.state.mailRecover
        };
        const fieldsToValidate = [
            [this.state.mailRecover, validationRules.EMAIL]
        ];
        if (!this.preValidateForm(fieldsToValidate)) {
            return;
        }

        apiDev("user/RecoverPasswordRequest", params).then(response => {
            if (response.data && Array.isArray(response.data)) {
                const msg = response.data;
                // console.log(msg);
                this.setState(state => {
                    const newState = { ...state, msg: msg, allHiden: true };
                    return newState;
                });
            } else {
                const msg = response.data.msg;
                this.setState(state => {
                    const newState = { ...state, msg: msg, allHiden: true };
                    return newState;
                });
            }
        });
    };
    render() {
        return (
            <div className="ut-form__container">
                {this.props.isLogged ? (
                    <h3 className={css["title"]}>Вы авторизованы</h3>
                ) : (
                    <Wrapper>
                        <h3 className={css["title"]}>{this.replaceHeader()}</h3>
                        <p className={css["message"]}>{this.state.msg}</p>
                        <Form
                            className={util.join(
                                this.state.allHiden ? css["input-hide"] : ""
                            )}
                        >
                            <Input
                                className={util.join(
                                    this.state.recovery ? css["input-hide"] : ""
                                )}
                                onChange={this.handleFieldChange}
                                name="email"
                                placeholder="Email"
                                label="Введите Ваш Email"
                                type="email"
                            />
                            <Input
                                className={util.join(
                                    this.state.recovery
                                        ? css["input-hide"]
                                        : css["inputLast"]
                                )}
                                name="password"
                                onChange={this.handleFieldChange}
                                placeholder="Пароль"
                                label="Введите Ваш пароль"
                                type="password"
                            />
                            <Input
                                className={util.join(
                                    this.state.recovery
                                        ? css["inputLast"]
                                        : css["input-hide"]
                                )}
                                onChange={this.handleRecoveryFieldChange}
                                name="email"
                                placeholder="Email"
                                label="Введите Ваш Email"
                            />
                            <Button
                                className={util.join(
                                    this.state.recovery ? css["input-hide"] : ""
                                )}
                                onClick={e => {
                                    e.preventDefault();
                                    return this.handleAuth();
                                }}
                                loading={this.props.auth.loading}
                            >
                                Авторизация
                            </Button>
                            <p
                                className={util.join(
                                    this.state.recovery
                                        ? css["input-hide"]
                                        : css["lightButton"]
                                )}
                                onClick={e => {
                                    e.preventDefault();
                                    return this.toggleRecovery();
                                }}
                            >
                                Забыли пароль?
                            </p>
                            <Button
                                className={util.join(
                                    this.state.recovery ? "" : css["input-hide"]
                                )}
                                onClick={e => {
                                    e.preventDefault();
                                    return this.sendRecover();
                                }}
                            >
                                Восстановить
                            </Button>
                        </Form>
                    </Wrapper>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isLogged: state.user.isLogged,
    auth: state.user.authState
});
const mapDispatchToProps = dispatch => ({
    login: user => dispatch(actions.login(user)),
    reset: () => dispatch(actions.authReset())
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);
