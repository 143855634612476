import axios from "axios";
import config from './API.config';
import util from "../util/util";


const api = (str,params = {}) => {
    
    const _axios = axios.create({
        baseURL: config.api_url,
        //withCredentials:true
    });
    
    /* 
     _axios.interceptors.response.use((response)=>{
        console.log(response);
        return response;
    })   */

    const pathParams = str
        .replace(/(^\/|\s|\/&)/g, "")
        .split("/");
    

    if (pathParams.length !== 2) {
        throw new Error(`Wrong number of arguments found in ${str}`);
    }

    const interfaceName = pathParams[0],
        actionName = pathParams[1];
        
    let requestUrl = `${interfaceName}.php?action=${actionName}`;
    
    
    //requestUrl += queryString;
    return _axios.post(requestUrl, { params, source:'web' });
};

export default api;
