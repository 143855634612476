const config = {
  methodName: "Orders",
  isTest: false,
  isDummy: false,
  method: "POST",
  dummyData: [
    {
      productName: "Апартаменты (1 спальня, R1, +540)",
      productId: "13556",
      quantity: 2,
      price: 58800,
      total: 117600,
      props: [
        {
          code: "email",
          value: "",
          title: "email",
          required: false,
          edit: true
        },
        {
          code: "adults",
          value: "2",
          title: "Взрослых",
          required: true,
          edit: false
        },
        {
          code: "time",
          value: "10:00",
          title: "Время",
          required: false,
          edit: false
        },
        {
          code: "dateOut",
          value: "2020-02-04",
          title: "Дата выезда",
          required: true,
          edit: false
        },
        {
          code: "dateIn",
          value: "2020-01-29",
          title: "Дата заезда",
          required: true,
          edit: false
        },
        {
          code: "children",
          value: "0",
          title: "Детей",
          required: true,
          edit: false
        },
        {
          code: "Fname",
          value: "",
          title: "Имя",
          required: false,
          edit: true
        },
        {
          code: "Lname",
          value: "",
          title: "Фамилия",
          required: false,
          edit: true
        }
      ],
      partnerId: "628",
      partnerName: "Апартаменты Премиум 540 и 960",
      partnerCode: "apartamenty_premium_540_i_960",
      partnerCategoryCode: "acc",
      image:
        "https://test.uniqtrip.ru/upload/resize_cache/iblock/08c/180_180_1/08c784e649ba239c5dfd11bb84612eb2.jpg"
    },
    {
      productName: "Премиум Кинг",
      productId: "17574",
      quantity: 1,
      price: 69500,
      total: 69500,
      props: [
        {
          code: "email",
          value: "",
          title: "email",
          required: false,
          edit: true
        },
        {
          code: "adults",
          value: "2",
          title: "Взрослых",
          required: true,
          edit: false
        },
        {
          code: "time",
          value: "10:00",
          title: "Время",
          required: false,
          edit: false
        },
        {
          code: "dateOut",
          value: "2020-02-04",
          title: "Дата выезда",
          required: true,
          edit: false
        },
        {
          code: "dateIn",
          value: "2020-01-29",
          title: "Дата заезда",
          required: true,
          edit: false
        },
        {
          code: "children",
          value: "0",
          title: "Детей",
          required: true,
          edit: false
        },
        {
          code: "Fname",
          value: "",
          title: "Имя",
          required: false,
          edit: true
        },
        {
          code: "Lname",
          value: "",
          title: "Фамилия",
          required: false,
          edit: true
        }
      ],
      partnerId: "634",
      partnerName: "Долина 960",
      partnerCode: "dolina_960",
      partnerCategoryCode: "acc",
      image:
        "https://test.uniqtrip.ru/upload/resize_cache/iblock/523/180_180_1/523db2a825bcd9d494b9e4196da1dc83.jpg"
    },
    {
      productName: "Супериор Кинг (вид на внутренний двор)",
      productId: "10925",
      quantity: 1,
      price: 75000,
      total: 75000,
      props: [
        {
          code: "email",
          value: "",
          title: "email",
          required: false,
          edit: true
        },
        {
          code: "adults",
          value: "2",
          title: "Взрослых",
          required: true,
          edit: false
        },
        {
          code: "time",
          value: "10:00",
          title: "Время",
          required: false,
          edit: false
        },
        {
          code: "dateOut",
          value: "2020-02-04",
          title: "Дата выезда",
          required: true,
          edit: false
        },
        {
          code: "dateIn",
          value: "2020-01-29",
          title: "Дата заезда",
          required: true,
          edit: false
        },
        {
          code: "children",
          value: "0",
          title: "Детей",
          required: true,
          edit: false
        },
        {
          code: "Fname",
          value: "",
          title: "Имя",
          required: false,
          edit: true
        },
        {
          code: "Lname",
          value: "",
          title: "Фамилия",
          required: false,
          edit: true
        }
      ],
      partnerId: "633",
      partnerName: "Novotel Resort Krasnaya Polyana Sochi",
      partnerCode: "novotel_resort_krasnaya_polyana_sochi",
      partnerCategoryCode: "acc",
      image:
        "https://test.uniqtrip.ru/upload/resize_cache/iblock/503/180_180_1/50328867e9676cf85d35d476b5f76cd4.jpg"
    }
  ],

  prepareRequest: r => {
    return {
      orderId: r
    };
  },
  prepareResponse: r => r,
  handleError: e => e
};

export default config;
