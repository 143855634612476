import React from "react";
import withOverlay from "../../hoc/Overlay/Overlay";
import { Container, Form } from "../Interface/Interface";
import css from "./styles/LocationSelect.module.scss";
import util from "../util/util";

const LocationSelect = props => {
    return (
        <Container className={css["container"]}>
            <Form>
                <h3 className={css["title"]}>Выбор локации</h3>
                <h6
                    className={util.join(
                        "ut-theme-color",
                        css["location-char"]
                    )}
                >
                    С
                </h6>
                <ul>
                    <li
                        className={css["location-item"]}
                        onClick={() =>
                            props.pickHandler({
                                country: "Россия",
                                city: "Сочи"
                            })
                        }
                    >
                        Сочи, Россия
                    </li>
                    {/* <li
                        className={css["location-item"]}
                        onClick={() =>
                            props.pickHandler({
                                country: "Россия",
                                city: "Адлер"
                            })
                        }
                    >
                        Адлер, Россия
                    </li> */}
                </ul>
            </Form>
        </Container>
    );
};

export default withOverlay(LocationSelect);
