import React from 'react';
import css from './Label.module.scss';

const Label = (props) => {
    let _class= css['label'];
    if (props.className) {
        _class += ` ${props.className}`;
    }
    if (props.color) {
        _class += ' ' + css[`label--${props.color}`];
    }

    return ( 
        <label className={_class} >
            {props.children}
        </label> 
    );
}
 
export default Label;