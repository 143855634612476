import React, { useState, Fragment } from "react";
import util from "../../util/util";
import css from "../../Cart/Cart.module.scss";
import purchasesCss from "../styles/Orders.module.scss";
import OrderItem from "./OrderItem";
const UserOrderItem = props => {
  const assocParams = util.arrayToAssoc(props.item.props, "code");
  const [fields, updateFields] = useState(assocParams);
  util.log(fields);
  const details = (
    <Fragment>
      <OrderItem {...fields["Fname"]} />
      <OrderItem {...fields["Lname"]} />
      <OrderItem {...fields["email"]} />
      <OrderItem {...fields["dateIn"]} />
      <OrderItem {...fields["dateOut"]} />
      <OrderItem {...fields["adults"]} />
      <OrderItem {...fields["children"]} />
      <OrderItem {...fields["time"]} />
    </Fragment>
  );

  return (
    <div
      className={util.join(purchasesCss["item--basis"])}
      onClick={props.detailCall}
    >
      <div className={purchasesCss["item-data"]}>
        <h3>{props.item.partnerName}</h3>
        <div>Общая цена: {util.currencyFormat(props.item.total)}</div>
      </div>
      <div
        className={util.join(
          css["item-main"],
          purchasesCss["item-main-purchases"]
        )}
      >
        <img
          src={props.item.image}
          className={util.join(
            css["item-image"],
            purchasesCss["item-image-purchases"]
          )}
        />
        <div className={purchasesCss["wrapp-product"]}>
          <div className={purchasesCss["wrapp-detail"]}>
            <p>
              {props.item.productName} x {props.item.quantity}
            </p>
            <p>
              Цена за услугу:{" "}
              <span>{util.currencyFormat(props.item.price)}</span>
            </p>
            <p>
              Общая сумма: <span>{util.currencyFormat(props.item.total)}</span>
            </p>
          </div>

          <div className={purchasesCss["detailOrder"]}>{details}</div>
        </div>
      </div>
    </div>
  );
};
export default UserOrderItem;
