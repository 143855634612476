import Login from "./_Login";
import Register from "./_Register";
import Logout from "./_Logout";
import Update from "./_Update";
import View from "./_View";
import Orders from "./_Orders";
import OrderProducts from "./_OrderProducts";
import UploadUserPhoto from "./_UploadUserPhoto";
import AddFavourite from "./_AddFavourite";
import AddFavourites from "./_AddFavourites";
import GetFavourites from "./_GetFavourites";
import RemoveFavourite from "./_RemoveFavourite";
export default {
    Login,
    Register,
    Logout,
    Update,
    View,
    Orders,
    OrderProducts,
    UploadUserPhoto,
    AddFavourite,
    AddFavourites,
    GetFavourites,
    RemoveFavourite
};
