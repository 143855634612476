import React from "react";
import withOverlay from "../../hoc/Overlay/Overlay";
import AuthForm from "./_AuthForm";
import RegisterForm from "./_RegisterForm";
import Tabs from "../Interface/Tabs";
import css from "./UserForm.module.scss";

const UserForm = props => {
  return (
    <Tabs className={`${css["modal"]} ut-theme-color`}>
      <AuthForm title="Авторизация" />
      <RegisterForm title="Регистрация" />
    </Tabs>
  );
};

export default withOverlay(UserForm);
