import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import apiDev from "../components/API/API.dev";
import css from "./styles/LayoutInfo.module.scss";
import util from "../components/util/util";

const LayoutInfo = () => {
  const [clientInfo, setClientInfo] = useState(null);

  useEffect(() => {
    apiDev("settings/getClientsInfo").then((res) => {
      setClientInfo(res.data.clientsInfo);
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={css["container"]}>
      <div className={css["text"]}>
        <div className={css["text-title"]}>Информация для клиентов</div>
        <div className={css["text-from-server"]}>
          {util.getHTML(clientInfo)}
        </div>
        {/* <Link style={{ marginTop: ".5rem" }} to="info/refund">
          Возврат денежных средств
        </Link> */}
        {/* <ul>
          <li>
            <a href="https://uniqtrip.ru/mobile/security_policy.pdf">
              Политика конфиденциальности
            </a>
          </li>
        </ul> */}
      </div>
    </div>
  );
};

export default LayoutInfo;
