import util from "../../components/util/util";

const config = {
	methodName: "AddOrder",
	isTest: false,
	isDummy: false,
	method: "GET",
	dummyData: {},
	prepareRequest: (p) => p,
	prepareResponse: (r) => {
		const url = r.payURL;
		if (url && url.length > 0) {
			return { url };
		}
		return r;
	},
	handleError: (e) => e,
};

export default config;
