import React, { useState } from 'react';

import css from './Tabs.module.scss';
import util from '../util/util';
const Tabs = (props) => {
    const [activeIndex,switchTab] = useState(0);
    const headers = props.children.map((el,index)=> (
        <li 
            className={util.join(css['header'], activeIndex === index && css['header--active'])} 
            key={index}
            onClick={()=>switchTab(index)}
            >
                <span>
                    {el.props.title}
                </span>
            
        </li>
        )
    )
    
    const tabs = props.children.map((el,index)=>(
        <div 
            className={util.join(css['tab'], activeIndex === index && css['tab--active'] )}
        >
            {el}
        </div>
        )
    );
    const currentTab = props.children.filter((e,i)=> i===activeIndex );
    return ( 
        <div className={props.className}>
            <ul className={css['headers']}>
                {headers}
            </ul>
            <div className={css['content']}>
                {currentTab}
            </div>
        </div>
        

    );
}
 
export default Tabs;