import Litepicker from "litepicker";
import "./DatePickerModal.scss";

export default class MyPicker extends Litepicker {
    show() {
        super.show();

        this.picker.style.position = "fixed";
        //this.picker.parentNode.className = "lp";
        this.picker.className = "litepicker lp";
        this.picker.style.display = "block";
        this.backdrop.style.display = "block";
        this.backdrop.style.zIndex = this.options.zIndex - 1;
        this.backdrop.style.opacity = 0.6;
        const pickerBCR = this.picker.getBoundingClientRect();
        this.picker.style.top = `${pickerBCR.height}px`;
        this.picker.style.left = `calc(50% - ${pickerBCR.width / 2}px)`;

        const span = document.createElement("span");
        span.className = "close ";
        span.innerText = "✕";
        span.addEventListener("click", () => {
            this.hide();
        });
        this.picker.appendChild(span);
    }
}
